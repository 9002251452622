<div class="col-md-12 text-right" *ngIf="mostrarObrigatorio">
  <div class="custom-control custom-switch">
    <input type="checkbox" class="custom-control-input" id="{{pergunta.titulo}}" [(ngModel)]="pergunta.obrigatorio">
    <label class="custom-control-label" for="{{pergunta.titulo}}">Obrigatório?</label>
  </div>
</div>
<div class="col-md-12">

  <label class="sub-title" *ngIf="pergunta.iD_TIPO_CAMPO_FORMULARIO != tipo.Check"
    style="padding-top: 10px;">{{pergunta.titulo}}</label>
  <p *ngIf="pergunta.descricao != null  && pergunta.descricao != '' && pergunta.iD_TIPO_CAMPO_FORMULARIO != tipo.Check "
    class="text-muted">
    <code>
      <span [innerHTML]="pergunta.descricao"></span>
    </code>
  </p>

  <input type="text" class="form-control" *ngIf="pergunta.iD_TIPO_CAMPO_FORMULARIO == tipo.Cpf"
    [textMask]="{mask: maskCPF}">
  <input type="text" class="form-control" *ngIf="pergunta.iD_TIPO_CAMPO_FORMULARIO == tipo.Email">
  <input type="text" class="form-control" *ngIf="pergunta.iD_TIPO_CAMPO_FORMULARIO == tipo.Inteiro"
    [textMask]="{mask: numberMaskInt}">
  <input type="text" class="form-control" *ngIf="pergunta.iD_TIPO_CAMPO_FORMULARIO == tipo.Rg">
  <select class="form-control form-control-default" *ngIf="pergunta.iD_TIPO_CAMPO_FORMULARIO == tipo.Selecao">
    <option *ngFor="let i of pergunta.valorSplit">{{i}}
  </select>
  <input type="text" class="form-control" *ngIf="pergunta.iD_TIPO_CAMPO_FORMULARIO == tipo.Telefone"
    [textMask]="{mask: maskTelefoneCelular}">
  <input type="text" class="form-control" *ngIf="pergunta.iD_TIPO_CAMPO_FORMULARIO == tipo.TextoLivre">
  <input type="text" class="form-control" *ngIf="pergunta.iD_TIPO_CAMPO_FORMULARIO == tipo.Moeda"
    [textMask]="{mask: numberMaskReal}">

  <div *ngIf="pergunta.iD_TIPO_CAMPO_FORMULARIO == tipo.Check ">
    <label class="sub-title" style="padding-top: 10px;">{{pergunta.titulo}}</label>
    <p *ngIf="pergunta.descricao null '' && pergunta.descricao != '' && pergunta.iD_TIPO_CAMPO_FORMULARIO != tipo.Check "
      class="text-muted">
      <code>
        <span [innerHTML]="pergunta.descricao"></span>
      </code>
      <div *ngFor="let it of pergunta.detalhesValores; let i = index">
        <input type="checkbox" name="{{it.titulo}}" id="{{it.titulo}}">
        <label for="{{it.titulo}}" class="cr"> &nbsp;&nbsp;{{it.titulo}} </label>
        <div class=" text-right" style="margin:-15px; padding: -15px" *ngIf="it?.patH_FILE">
          <label class="text-danger ">{{it?.patH_FILE}} </label>
        </div>
      </div>
  </div>

  <fieldset class="form-group" *ngIf=" pergunta.iD_TIPO_CAMPO_FORMULARIO == tipo.Radio">
    <div class="row">
      <div class="col-md-12" >
        <div class="form-check" *ngFor="let it of pergunta.valorSplit; let i = index" >
          <div class="text-left" >
            <input class="form-check-input" type="radio" name="gridRadios" id="{{it}}" value="{{it}}">
            <label class="form-check-label" for="gridRadios1">{{it}} </label>
          </div>
          <div class=" text-right" style="margin:-15px; padding: -15px" *ngIf="pergunta?.pathSplit">
            <label class="text-danger ">{{pergunta?.pathSplit[i]}} </label>
          </div>

        </div>
      </div>
      <!--<div class="col-md-3">
        <div class="form-check" *ngFor="let it of pergunta.pathSplit; let i = index">
          {{pergunta.pathSplit[i]}}
        </div>
      </div>-->
    </div>
  </fieldset>

  <fieldset class="form-group" *ngIf=" pergunta.iD_TIPO_CAMPO_FORMULARIO == tipo.Grade">
    <div class="row">
      <div class="col-md-12">
        <table>
          <tr class="text-center">
            <th class="col-md-8"></th>
            <th class="col-md-4" *ngFor="let it of pergunta.filhos[0].valorSplit;">{{it}}</th>
          </tr>
          <tr *ngFor="let it of pergunta.filhos; let i = index" class="text-center">
            <td class="col-md-8 text-left">{{it.titulo}}</td>
            <td class="col-md-4" *ngFor="let ite of it.valorSplit;">
              <input class="form-check-input" type="radio" name="gridRadios" id="{{ite}}-{{i}}" value="{{ite}}-{{i}}">
              <label class="form-check-label" for="gridRadios{{i}}"></label>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </fieldset>

</div>
