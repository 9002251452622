<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class="row">
  <div class="col-12">
    <app-card cardTitle="Cadastro Avisos" [options]="false">
      <div class="row">
        <div class="col-md-6">
          <label class="sub-title">Título</label>
          <input type="text" class="form-control" [(ngModel)]="titulo" name="titulo" placeholder="">
        </div>
        <div class="col-md-3">
          <label class="sub-title">Data Evento </label>
          <input type="date" class="form-control" [(ngModel)]="dataEvento" name="dataEvento" placeholder="">
        </div>
        <div class="col-md-3 text-left">
          <div class="form-group">
            <div class="switch switch-success d-inline m-r-10">
              <input type="checkbox" id="switch-p-1" [(ngModel)]="status" name="status">
              <label for="switch-p-1" class="cr"></label>
            </div>
            <label>Status</label>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-10">
          <label class="sub-title">Descrição </label>
          <app-tinymce [(ngModel)]="descricao" name="descricao" #tinymce='tinymce'></app-tinymce>
          <!--<input type="text" class="form-control" [(ngModel)]="descricao" name="descricao" placeholder="">-->
        </div>
        <!--<div class="NgxEditor__Wrapper">
          <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
          <ngx-editor [editor]="editor" [(ngModel)]="html" [disabled]="false" [placeholder]="'sua mensagem aqui'"></ngx-editor>
        </div>-->
      </div>
      <div class="row">
        <div class="col-sm-12 col-xl-10 text-right" *ngIf="avisoSelecionado == null">
          <br>
          <button type="button" class="btn btn-success " (click)="salvar()">SALVAR &nbsp;<i
              class="feather icon-check-circle"></i>
          </button>
        </div>
        <div class="col-sm-12 col-xl-10 text-right" *ngIf="avisoSelecionado != null">
            <br>
            <button type="button" class="btn btn-warning " (click)="limpar()"><i
                class="fa fa-arrow-left"> </i> &nbsp; LIMPAR PARA ADICIONAR NOVO
            </button>&nbsp;&nbsp;&nbsp;
            <button type="button" class="btn btn-info " (click)="alterar()"> ATUALIZAR &nbsp;<i
                class="feather icon-check-circle"></i>
            </button>
          </div>

      </div>


    </app-card>
  </div>
  <div class="col-12">
    <app-card cardTitle="Avisos Cadastrados" blockClass="table-border-style" [options]="false">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Descricão</th>
              <th>Data Evento</th>
              <th>Status</th>
              <th>Acão</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of arrayAvisos">
              <td>{{item.titulo }}</td>
              <td>{{item.datA_EVENTO | date: "dd/MM/yyyy"}}</td>
              <td>
                <span *ngIf="item.status" class="badge badge-success">ATIVO</span>
                <span *ngIf="!item.status" class="badge badge-danger">INATIVO</span>
              </td>
              <td>
                <button type="button" class="btn btn-icon btn-info " (click)="carregarItem(item)">
                    <i class="fa fa-edit"></i>
                  </button>
                &nbsp;&nbsp;
                <button type="button" class="btn btn-icon btn-danger " (click)="desabilitar(item.iD_AVISO)">
                  <i class="fa fa-trash"></i>
                </button>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </app-card>
  </div>
</div>
