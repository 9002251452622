import { UtilService } from "../service/util.service";

export class Crianca {

  constructor(
    iD_CRIANCA: number,
    nome: string,
    idade: number,
    sexo: string,
    graU_TEA: number,
    iD_RESPONSAVEL_TEA: number,
    iD_TRIAGEM: number,
    dataNascimento: string,
    util?: UtilService,
    tipoEscola?: string,
    turno?: string,
    cartaoSus?: string,
  ) {
    this.iD_CRIANCA = iD_CRIANCA;
    this.nome = nome;
    this.idade = idade;
    this.sexo = sexo;
    this.graU_TEA = graU_TEA;
    this.iD_RESPONSAVEL_TEA = iD_RESPONSAVEL_TEA;
    this.iD_TRIAGEM = iD_TRIAGEM;
    this.util = util;
    this.dataNascimento = dataNascimento;
    this.tipoEscola = tipoEscola;
    this.turno = turno;
    this.cartaoSus = cartaoSus;
  }

  iD_CRIANCA: number;
  nome: string;
  idade: number;
  dataNascimento: string;
  sexo: string;
  graU_TEA: number;
  iD_RESPONSAVEL_TEA: number;
  iD_TRIAGEM: number;
  util: UtilService;
  tipoEscola?: string;
  turno?: string;
  cartaoSus: string;

  validar(): boolean {

    if (this.nome == undefined || this.nome == "") {
      this.util.toasterShowError("Atenção", "Entre com o Nome do Indivíduo com TEA");
      return false;
    }

    if (this.dataNascimento == undefined || this.dataNascimento == null || this.dataNascimento == '') {
      this.util.toasterShowError("Atenção", "Entre com a Data Nascimento do Indivíduo com TEA");
      return false;
    }   

    if(!this.util.dateIsValid(this.dataNascimento)){
      this.util.toasterShowError("Atenção", "Revise a Data de Nascimento, ela esta em um formato inválido");
      return false;
    }

    if (this.idade == undefined || this.idade == 0) {
      this.util.toasterShowError("Atenção", "Entre com a idade do Indivíduo com TEA");
      return false;
    }  

    if (this.sexo == undefined || this.sexo == "") {
      this.util.toasterShowError("Atenção", "Entre com o Sexo do Indivíduo com TEA");
      return false;
    }

    return true;
  }

  montarJson(): any {
    return {
      "nome": this.nome,
      "idade": this.idade,
      "sexo": this.sexo,
      "graU_TEA": this.graU_TEA -1,
      "iD_RESPONSAVEL_TEA": this.iD_RESPONSAVEL_TEA,
      "ID_TRIAGEM": this.iD_TRIAGEM,
      "DATA_NASCIMENTO": this.util.formatDateSQL(this.dataNascimento),
      "ONDE_ESTUDOU": this.tipoEscola,
      "TURNO": this.turno,
      "CARTAO_SUS": this.cartaoSus
    }
  }
}

export class CriancaTriagem {
  iD_CRIANCA_TRIAGEM: number;
  iD_TRIAGEM: number;
  iD_CRIANCA: number;
  idEncCrud: string;
}