<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Aguarde ...</p>
  </ngx-spinner>
  
  <div class="row">
    <div class="col-12">
      <app-card cardTitle="Triagem Cadastradas" blockClass="table-border-style" [options]="false">
        <div class="row">
            <div class="col-md-6 col-sm-12">
              <label for="exampleInputEmail1">Filtro</label>
              <input type="text" class="form-control" [(ngModel)]="query"
                placeholder="Digite seu filtro, sera feito uma busca em todas as colunas" >
            </div>
            <!--<div class="col-md-2 col-sm-12">
              <br>
              <label for="exampleInputEmail1">&nbsp;</label>
              <button type="button"  class="btn btn-icon btn-warning"><i
                class="feather icon-refresh-cw"></i> Atualizar</button>
            </div>-->
          </div>
          <br>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Título Triagem</th>
                <th>Dt. Inicio</th>
                <th>Dt. Fim</th>
                <th></th>
                <th>Link / Edição</th>
               
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of arrayTriagem  | fullTextSearch:query">
                <td>{{item.titulo }}</td>
                <td>{{item.datA_INICIO | date: "dd/MM/yyyy"}}</td>
                <td>{{item.datA_FIM | date: "dd/MM/yyyy"}}</td>
                <td>
                  <span *ngIf="item.revisado" class="badge badge-success">REVISADO</span>
                  <span *ngIf="!item.revisado" class="badge badge-danger">NÃO REVISADO</span>
                </td>
                <td>
                  <button *ngIf="item.revisado" type="button" class="btn btn-icon btn-success " ngbTooltip="Copie seu link" (click)="mostrarLink(item)">
                    <i class="feather icon-link"></i>
                  </button>  
                  <button *ngIf="!item.revisado" type="button" class="btn btn-icon btn-warning " ngbTooltip="Triagem não revisada" (click)="mostrarLink(item)">
                    <i class="feather icon-link"></i>
                  </button>   &nbsp;&nbsp;&nbsp;
                  <button type="button" class="btn btn-icon btn-info " ngbTooltip="Editar" (click)="editar(item.id)">
                    <i class="feather icon-edit"></i>
                  </button>  &nbsp;&nbsp;&nbsp;
                  <button type="button" class="btn btn-icon btn-danger " (click)="desabilitar(item.id)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </app-card>
    </div>
  </div>
  