import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, API_QRCODE } from 'src/environments/environment';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pesquisa-triagem',
  templateUrl: './pesquisa-triagem.component.html',
  styleUrls: ['./pesquisa-triagem.component.scss']
})
export class PesquisaTriagemComponent implements OnInit {


  public arrayTriagem: [];
  query: string;

  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.buscar();
  }

  mostrarLink(item){
    console.log(item.iD_TRIAGEM);
    let message = ' ' +
    '<div class="modal-body"> ' +
    '		<h4 class="mb-2">URL</h4> ' +
    '		<div class="form-group mb-4"> ' +
    '			<input type="text" value="' + API_QRCODE + item.iD_TRIAGEM+ '" class="form-control pl-2" readonly=""> ' +
    '		</div>' +
    '<div>' ;

    Swal.fire({
      title: item.titulo,
      type: 'warning',
      html: message,
      //width: '95%'
    }).then((result) => {

    });

  } 

  buscar(){
    this.util.showLoading();
    let url = API + "Triagem/Triagem";  
    return this.ajax.get(url)
    .subscribe(response  => {
      this.arrayTriagem = response.data;

      this.util.closeLoading();      
    }, err => {
      debugger
      this.util.closeLoading();
      this.util.toasterShowError("Atenção", "Erro ao Buscar Triagem");
    });
  }

  editar(id: string){
    this.router.navigate(['/editar-triagem',id]);
  }


  desabilitar(id: string) {

    debugger
    let dados = {
      "id": id
    }
    let url = API + "Triagem/Triagem/Desabilitar/" + id;
    return this.ajax.post(url, dados)
      .subscribe(response => {

        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Triagem Desabilitado com Sucesso");
        this.buscar();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Triagem");
      });

  }

  
}
