<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Aguarde ...</p>
  </ngx-spinner>
  
  <div class="row">
    <div class="col-12">
      <app-card cardTitle="Triagem Cadastradas" blockClass="table-border-style" [options]="false">
        <div class="row">
            <div class="col-md-6 col-sm-12">
              <label for="exampleInputEmail1">Filtro</label>
              <input type="text" class="form-control" [(ngModel)]="query"
                placeholder="Digite seu filtro, sera feito uma busca em todas as colunas" >
            </div>
            <!--<div class="col-md-2 col-sm-12">
              <br>
              <label for="exampleInputEmail1">&nbsp;</label>
              <button type="button"  class="btn btn-icon btn-warning"><i
                class="feather icon-refresh-cw"></i> Atualizar</button>
            </div>-->
          </div>
          <br>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th>Título Triagem</th>
                <th>Acão</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of arrayTriagem  | fullTextSearch:query">
                  <td>{{item.iD_TRIAGEM}}</td>
                <td>{{item.titulo }}</td>
                <td>
                  <button type="button" class="btn btn-icon btn-warning " ngbTooltip="Detalhes" (click)="detalhe(item)">
                    <i class="feather icon-eye"></i>
                  </button> &nbsp;&nbsp;
                  <button type="button" class="btn btn-icon btn-success " ngbTooltip="Aprovados" (click)="aprovados(item)">
                    <i class="feather icon-check-circle"></i>
                  </button>  
                  &nbsp;&nbsp;
                  <button type="button" class="btn btn-icon btn-dark " ngbTooltip="EXPORTAR .XLS" (click)="exportarExcel(item)">
                    <i class="fa fa-file-excel"></i>
                  </button>      
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </app-card>
    </div>
  </div>
  