<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class="col-md-12">
  <!-- Default select start -->
  <app-card cardTitle="Resultado Triagem" [options]="false">
    <div class="row">

      <div class="col-md-12">
        <app-card [hidHeader]="true" cardClass="ticket-card">
          <div class="row">
            <div class="col-md-6 text-left">
              <h4 class="m-b-0 d-inline-block text-c-green text-right"> Título: {{tituloTriagem}} </h4>
            </div>
            <div class="col-md-6 text-right" *ngIf="arrayDivisaoGrupos.length == 0">
              <h2 class="m-b-0 d-inline-block text-success"> {{objResultado?.totaL_APROVADO}} </h2>
              <p class=""> Aprovados </p>
            </div>
          </div>

          <div class="row" *ngIf="arrayDivisaoGrupos.length == 0">
            <div class=" col-md-12 text-right">
              <button type="button" class="btn btn-info " style="margin-top: 6px;" (click)="gerarGrupos()">
                GERAR GRUPO CONTROLE E INTERVENÇÃO &nbsp;
                <i class="feather icon-check-circle"></i>
              </button>
            </div>
          </div>
          <div class="col-md-12 text-right" *ngIf="arrayDivisaoGrupos.length > 0">
            <div class="custom-control custom-radio custom-control-inline" (click)="filter(null)">
              <input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input"
                checked>
              <label class="custom-control-label" for="customRadioInline1">Todos</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline" (click)="filter('I')">
              <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input">
              <label class="custom-control-label" for="customRadioInline2">Intervenção</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline" (click)="filter('C')">
              <input type="radio" id="customRadioInline3" name="customRadioInline1" class="custom-control-input">
              <label class="custom-control-label" for="customRadioInline3">Controle</label>
            </div>
          </div>
          <br>
        </app-card>
      </div>

    </div>
    <div class="row" *ngIf="arrayDivisaoGrupos.length == 0">


      <input type="text" class="form-control" [(ngModel)]="query"
        placeholder="Digite seu filtro, sera feito uma busca em todas as colunas">
      <br><br>
      <div class="table-responsive">
        <table class="table table-hover">
          <div style="height:480px; ">
            <thead>
              <tr>
                <th>Responsável</th>
                <th>Criança</th>
                <th>Pontuação</th>
                <th></th>
                <th>Grupo Controle</th>
                <th>Grupo Intervenção</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of arrayResultado | fullTextSearch:query" class="text-muted">
                <td class="col-2"><strong>{{item.nomE_RESPONSAVEL }}</strong> </td>
                <td class="col-4"><strong>{{item.nomE_CRIANCA }}</strong></td>
                <td class="col-4">
                  <strong> {{item.pontos }}</strong>
                </td>
                <td></td>
                <td></td>
                <td class="col-1">
                  <!--<button type="button" class="btn btn-icon btn-info btn-sm " (click)="visualizarPerguntas(item)"
                      ngbTooltip="Editar Pergunta">
                      <i class="fa fa-eye"></i>
                    </button>-->
                  <!--<button type="button" class="btn btn-icon btn-info btn-sm " (click)="openModal(myLargeModalLabel, item)"
                    ngbTooltip="Ver Pergunta">
                    <i class="fa fa-eye"></i>
                  </button>-->
                  <!--<button type="button" class="btn btn-icon btn-danger btn-sm" (click)="desabilitar(item.id)">
                      <i class="fa fa-trash"></i>
                    </button>-->
                </td>

              </tr>
            </tbody>
          </div>
        </table>
      </div>

    </div>

    <div class="row" *ngIf="arrayDivisaoGrupos.length > 0">


          <div class="col-md-10" >
            <input type="text" class="form-control" [(ngModel)]="query"
              placeholder="Digite seu filtro, sera feito uma busca em todas as colunas">
          </div>
          <div class="col-md-2">
            {{arrayDivisaoGrupos.length}} Aprovados
          </div>
        <br><br>


      <div class="table-responsive">
        <table class="table table-hover">
          <div style="height:480px; ">
            <thead>
              <tr>
                <th>Responsável</th>
                <th>CPF</th>
                <th>Criança</th>
                <th>Sexo</th>
                <th>Grau TEA</th>
                <th>Grupo</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of arrayDivisaoGrupos | fullTextSearch:query" class="text-muted">
                <td class="col-1"><strong>{{item.reponsaveL_NOME }}</strong> </td>
                <td class="col-1"><strong>{{item.responsaveL_CPF }}</strong> </td>
                <td class="col-1"><strong>{{item.criancA_NOME }}</strong></td>
                <td class="col-1"><strong> {{item.criancA_SEXO }}</strong> </td>
                <td class="col-1"><strong> {{item.criancA_GRAU_TEA == 0 ? "Não Sei": item.criancA_GRAU_TEA }}</strong>
                </td>
                <td class="col-1"><strong>
                    <span *ngIf="item.grupo == 'I'" class="badge badge-warning">INTERVENÇÃO</span>
                    <span *ngIf="item.grupo == 'C'" class="badge badge-success">CONTROLE</span>
                  </strong> </td>
              </tr>
            </tbody>
          </div>
        </table>
      </div>

    </div>

  </app-card>
</div>
