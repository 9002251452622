import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cadastro-responsavel',
  templateUrl: './cadastro-responsavel.component.html',
  styleUrls: ['./cadastro-responsavel.component.scss']
})
export class CadastroResponsavelComponent implements OnInit {


  public arrayResponsavel: any[];
  public filtro: string;

  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.buscar();
  }

  buscar() {
    this.util.showLoading();
    let url = API + "ResponsavelTea/ResponsavelTea";
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayResponsavel = response.data;
        console.log(this.arrayResponsavel);
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Responsáveis");
      });
  }

  resetarDispositivo(item){

    Swal.fire({
      html: `Ao aceitar você estará dando permissao para <br><strong>${item.nome}</strong> acessar o aplicativo em um novo dispositivo`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Aceitar',
      showLoaderOnConfirm: true
    }).then((result) => {
      debugger
      if (result.value != undefined && result.value != '') {  
        this.resetarDados(item);
      }
    }); 
    
  }

  resetarDados(item){
    var dados = {
      "id": item.iD_RESPONSAVEL_TEA
    }
    this.util.showLoading();
    let url = API + "Login/login/ResetDispositivo";
    return this.ajax.post(url, dados)
      .subscribe(response => {          
        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "Agora o usuário podera realizar o login novamente em um novo dispositivo");
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Não foi possível atualizar os dados");
      });
  }

}
