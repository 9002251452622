import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AjaxService } from "src/app/service/ajax.service";
import { UtilService } from "src/app/service/util.service";
import { API, BASE_API } from "src/environments/environment";

@Component({
  selector: "app-modal-add-etapa",
  templateUrl: "./modal-add-etapa.component.html",
  styleUrls: ["./modal-add-etapa.component.scss"],
})
export class ModalAddEtapaComponent implements OnInit {
  //@Input() ID_MODULO: string;
  @Input() set ID_MODULO(MODULO: string) {
    this.buscarRecuperacao(MODULO);
  }
  @Output() acaoVoltar = new EventEmitter();
  @Input() item: any;

  public idModulo: string;
  public titulo: string;
  public ordem: number;
  public obrigatorio: boolean;
  public ehRecuperacao: boolean;
  public descricao: string;
  public uploadedFileAvatar: File;
  public arrayEtapaRecuperacao: any[];
  public etapaRecuperacaoSelecionado: any;

  constructor(private ajax: AjaxService, public util: UtilService) {}

  ngOnInit() {
    this.limpar();
    this.arrayEtapaRecuperacao = [];
  }

  buscarRecuperacao(modulo: string) {
    this.idModulo = modulo;
    console.log("modulo: " + modulo);
    if (modulo != null) {
      this.util.showLoading();
      let url =
        API +
        "EtapaPrograma/EtapaPrograma/ConsultarEtapaProgramaRecuperacao?ID_MODULO_PROGRAMA=" +
        modulo +
        "&EH_RECUPERACAO=true";
      this.ajax.get(url).subscribe(
        (response) => {
          this.arrayEtapaRecuperacao = this.util.convertIoptions(
            response.data,
            "iD_ETAPA_PROGRAMA",
            "titulo"
          );
          this.util.closeLoading();
        },
        (err) => {
          debugger;
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Buscar Programa");
        }
      );
    }
  }

  ngOnChanges() {
    this.preencheModuloEdit(this.item);
  }

  preencheModuloEdit(item) {
    console.log("dentro do modal galoo", item);
    if (item != null) {
      this.titulo = item.titulo;
      this.descricao = item.instrucao;
      this.ordem = item.ordem;
      this.obrigatorio = item.obrigatorio;
      this.ehRecuperacao = item.eH_RECUPERACAO;
      this.etapaRecuperacaoSelecionado = item.iD_ETAPA_PROGRAMA_RECUPERACAO;      
      
    } else {
      this.limpar();
    }

  }

  limpar() {
    this.titulo = "";
    this.ordem = 1;
    this.obrigatorio = true;
    this.ehRecuperacao = false;
    this.descricao = "";
    this.uploadedFileAvatar = null;
    this.item = null;
    //this.arrayEtapaRecuperacao = [];
    this.etapaRecuperacaoSelecionado = -1;
    //this.etapaRecuperacaoSelecionado = null;
  }

  buttonLimparSelectRecuperacao(){
    this.etapaRecuperacaoSelecionado = -1;
    this.etapaRecuperacaoSelecionado = null;
  }

  validar(): boolean {
    if (this.titulo == undefined || this.titulo == "") {
      this.util.toasterShowError("Atenção", "Entre com o titulo do Etapa");
      return false;
    }

    if (this.ordem == undefined) {
      this.util.toasterShowError("Atenção", "A ordem não pode ser nula");
      return false;
    }

    if (this.idModulo == undefined) {
      this.util.toasterShowError(
        "Atenção",
        "Não é possível salvar antes de escolher o Etapa"
      );
      return false;
    }

    return true;
  }

  montarJson() {
    //Arquivo: this.uploadedFileAvatar[0],
    var dados = {
      TITULO: this.titulo,
      INSTRUCAO: this.descricao,
      OBRIGATORIO: this.obrigatorio,
      EH_RECUPERACAO: this.ehRecuperacao,
      ORDEM: this.ordem,
      PROXIMO: false,
      ID_MODULO_PROGRAMA: this.idModulo,
    };

    debugger;
    if (this.uploadedFileAvatar != undefined || this.uploadedFileAvatar != null)
      dados["Arquivo"] = this.uploadedFileAvatar[0];

    if (this.item != null)
      dados["ID_ETAPA_PROGRAMA"] = this.item.iD_ETAPA_PROGRAMA;

    if(this.etapaRecuperacaoSelecionado != null){}
      dados["ID_ETAPA_PROGRAMA_RECUPERACAO"] = this.etapaRecuperacaoSelecionado

    return dados;
  }

  salvar() {
    if (this.validar()) {
      let data = this.montarJson();
      debugger;
      let dados = this.util.jsonToFormData(data);

      debugger;
      let url = API + "EtapaPrograma/EtapaPrograma/Salvar";
      this.ajax.postFile(url, dados).subscribe(
        (response) => {
          this.util.closeLoading();
          if (!response.hasErrors) {
            this.util.toasterShowSuccess("Atenção", "Etapa Salvo com Sucesso");
            this.limpar();
            this.buscarRecuperacao(this.idModulo);
          } else {
            this.util.toasterShowError("Atenção", response.message);
          }
        },
        (err) => {
          debugger;
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Etapa");
        }
      );
    }
  }

  voltar() {
    this.limpar();
    this.acaoVoltar.emit();
  }
}
