

export class DadosTemplateEntrada {
  iD_TRIAGEM: string;
  iD_TIPO_CAMPO_FORMULARIO: number;
  iD_TIPO_CLASSIFICACAO_PERGUNTA: number;
  iD_PERGUNTA: string;
  iD_PERGUNTA_PAI: string;
  titulo: string;
  descricao: string;
  valor: string;
  ordem: number;
  subOrdem: string;
  obrigatorio: boolean;
  valores: string[];
  valoresDetalhes: any[];
  detalhesValores: any[];
  agrupamento:string;
  paginaCabecalho:boolean;
  pagina: number;
  PONTO: string;
  HABILITA_CAMPO: string;
  TEXTO_CAMPO_CONDICIONAL: string;
  STATUS: boolean;
  ELIMINA_CLASSIFICA: string;
  TAG: string;
  TITULO_PAI: string;
  ID_PAI: string;
  arquivos?: File[];
  arquivosCapa?: File[];
  aRRAY_RECUPERACAO: string;
} 

