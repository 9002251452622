import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AjaxService } from "src/app/service/ajax.service";
import { UtilService } from "src/app/service/util.service";
import { API } from "src/environments/environment";

@Component({
  selector: "app-modal-cadastro-modulos",
  templateUrl: "./modal-cadastro-modulos.component.html",
  styleUrls: ["./modal-cadastro-modulos.component.scss"],
})
export class ModalCadastroModulosComponent implements OnInit {
  @Input() ID_PROGRAMA: string;
  @Output() acaoVoltar = new EventEmitter();
  @Input() item: any;

  public titulo: string;
  public ordem: number;
  public obrigatorio: boolean;
  public descricao: string;

  constructor(private ajax: AjaxService, public util: UtilService) {}

  ngOnInit() {
    this.limpar();
  }

  ngOnChanges () {
    this.preencheModuloEdit(this.item);
  }

  preencheModuloEdit(item) {
    //console.log("dentro do modal", item);
    if (item != null) {
      this.titulo = item.titulo;
      this.descricao = item.descricao;
      this.ordem = item.ordem;
      this.obrigatorio = item.obrigatorio;
    }else{
      this.limpar();
    }
  }

  limpar() {
    this.titulo = "";
    this.ordem = 1;
    this.obrigatorio = true;
    this.descricao = "";
    this.item = null;
  }

  validar(): boolean {
    if (this.titulo == undefined || this.titulo == "") {
      this.util.toasterShowError("Atenção", "Entre com o titulo do módulo");
      return false;
    }

    if (this.ordem == undefined) {
      this.util.toasterShowError("Atenção", "A ordem não pode ser nula");
      return false;
    }

    if (this.ID_PROGRAMA == undefined) {
      this.util.toasterShowError(
        "Atenção",
        "Não é possível salvar antes de escolher o Programa"
      );
      return false;
    }

    return true;
  }

  montarJson() {
    var dados = {
      TITULO: this.titulo,
      DESCRICAO: this.descricao,
      OBRIGATORIO: this.obrigatorio,
      ORDEM: this.ordem,
      PROXIMO: false,
      ID_PROGRAMA: this.ID_PROGRAMA,
    };

    if(this.item != null)
      dados['ID_MODULO_PROGRAMA'] = this.item.iD_MODULO_PROGRAMA;

    return  dados;
  }

  salvar() {
    debugger
    if (this.validar()) {
      let dados = this.montarJson();
      let url = API + "ModuloPrograma/ModuloPrograma/Salvar";
      this.ajax.post(url, dados).subscribe(
        (response) => {
          this.util.closeLoading();
          if (!response.hasErrors) {
            this.util.toasterShowSuccess("Atenção", "Módulo Salvo com Sucesso");
            this.limpar();
          } else {
            this.util.toasterShowError("Atenção", response.message);
          }
        },
        (err) => {
          debugger;
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Módulo");
        }
      );
    }
  }

  /*alterar(){
    if(this.validar()){
      let dados = this.montarJson();
      let url = API + "ModuloPrograma/ModuloPrograma/Salvar";
    }
  }*/

  voltar() {
    this.acaoVoltar.emit();
  }
}
