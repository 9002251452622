<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class="row">
  <div class="col-md-2">
    <app-card [options]="false" [hidHeader]="true">
      <div class="row">
        <label class="sub-title">Tipo Arquivo</label>
      </div>
      <div class="row">
        <button type="button" class="btn btn-icon btn-success " (click)="openExtencao()">
          <i class="fa fa-plus"></i> Add. Tipo
        </button>
      </div><br>
      <div class="row">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>TIPOS CAD.</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of arrayTipoMidias">
                <td>{{item.descricao }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </app-card>
  </div>
  <div class="col-md-10">
    <app-card [options]="false" [hidHeader]="true">

      <div class="row ">
        <div class="col-md-4">
          <label class="sub-title">Título</label>
          <input type="text" class="form-control" [(ngModel)]="nome" name="nome" placeholder="">
        </div>
        <div class="col-md-4">
          <label class="sub-title">Tipo Arquivo</label>
          <select [(ngModel)]="tipoMidiaSelecionada" name="tipoMidiaSelecionada"
            class="form-control form-control-default">
            <option *ngFor="let item of arrayTipoMidias" value={{item.id}}>
              {{item.descricao}}
          </select>
        </div>
        <div class="col-md-4">
          <label class="sub-title">Arquivo </label>
          <file-upload [(ngModel)]="uploadedFile" name="uploadedFile" [fileslimit]="1" simple></file-upload>
        </div>
      </div>
      <div class="row ">
        <div class="col-md-9">
          <label class="sub-title">Descricao</label>
          <!--<input type="text" class="form-control" [(ngModel)]="descricao" name="descricao" placeholder="">-->
          <textarea class="form-control" [(ngModel)]="descricao" name="descricao" rows="3"></textarea>
        </div>
        <div class="col-md-3 text-right" style="margin-top:70px;" *ngIf="midiaSelecionada == null">
          <button type="button" class="btn btn-icon btn-success " (click)="salvarMidia()">
            <i class="fa fa-plus"></i> Salvar Midia
          </button>
        </div>
        <div class="col-md-3 text-right" style="margin-top:70px;" *ngIf="midiaSelecionada != null">
          <button type="button" class="btn btn-icon btn-info " (click)="voltar()">
            <i class="fa fa-plus"></i> Voltar
          </button>&nbsp;&nbsp;
          <button type="button" class="btn btn-icon btn-success " (click)="salvarAlteracao()">
            <i class="fa fa-plus"></i> Alterar Midia
          </button>
        </div>

      </div>

    </app-card>
    <app-card  cardClass="card-datatable" [hidHeader]="true" [options]="false">

      <app-minha-tabela
      [endPoint]="urlMidia"
      [arrayCabecalho]="arrayCabecalho"
      (itemVisualizar)="receberItemVisualizar($event)"
      (itemEditar)="receberItemEditar($event)"
      (itemDeletar)="receberItemApagar($event)"
      >
    </app-minha-tabela>

    </app-card>
    <!--<app-card  cardClass="card-datatable" [hidHeader]="true" [options]="false">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
        <thead>
          <tr>
            <th class="col-md-1">TITULO</th>
            <th class="col-md-1">NOME</th>
            <th class="col-md-1">TAMANHO</th>
            <th class="col-md-1">EXTENSÃO</th>
            <th class="col-md-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of arrayMidias">
            <td class="col-md-1">{{item.titulo }}</td>
            <td class="col-md-1">{{item.nome }}</td>
            <td class="col-md-1">{{item.tamanho }}</td>
            <td class="col-md-1">{{item.contenT_TYPE }}</td>
            <td class="col-md-2">
              <button *ngIf="!item.revisado" type="button" class="btn btn-icon btn-warning " (click)="verArquivo(item)" ngbTooltip="Visualizar Arquivo" >
                <i class="feather icon-eye"></i>
              </button> &nbsp;&nbsp;
            <button type="button" class="btn btn-icon btn-info " ngbTooltip="Editar Arquivo" (click)="carregarItemEditar(item)">
              <i class="fa fa-edit"></i>
            </button>


            </td>
          </tr>
        </tbody>
      </table>
    </app-card> -->
  </div>
</div>

