import { TIPOS_ENTRADA } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { Mapa } from '../model/Mapa';
import { GeoLocation } from '../model/geoLocation';
import jwt_decode from 'jwt-decode';
import { Guid } from 'guid-typescript';
import { DadosTemplateEntrada } from '../model/DadosTemplateEntrada';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  options: any;
  tipo = TIPOS_ENTRADA;

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {

    this.options = {
      "closeButton": true,
      "newestOnTop": true,
      "progressBar": false,
      "positionClass": "toast-top-center",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }

  }

  showLoading() {
    this.spinner.show();
  }

  closeLoading() {
    this.spinner.hide();
    /*setTimeout(() => {
      this.spinner.hide();
    }, 3000);*/
  }

  toasterShowSuccess(titulo: string, mensagem: string) {
    this.toastr.success(mensagem, titulo, this.options);
  }

  toasterShowError(titulo: string, mensagem: string) {
    this.toastr.error(mensagem, titulo, this.options);
  }

  toasterShowInfo(titulo: string, mensagem: string) {
    this.toastr.info(mensagem, titulo, this.options);
  }

  toasterShowWarning(titulo: string, mensagem: string) {
    this.toastr.warning(mensagem, titulo, this.options);
  }


  SwalBasic(titulo: string, mensagem: string) {
    Swal.fire(titulo, 'Hello world!');
  }

  SwalSuccess(titulo: string, mensagem: string) {
    Swal.fire(titulo, mensagem, 'success');
  }

  SwalWarning(titulo: string, mensagem: string) {
    Swal.fire(titulo, mensagem, 'warning');
  }

  Swaldanger(titulo: string, mensagem: string) {
    Swal.fire(titulo, mensagem, 'error');
  }

  SwalInfo(titulo: string, mensagem: string) {
    Swal.fire(titulo, mensagem, 'info');
  }

  pad(num, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  convertIoptions(array, value, label) {
    return array.map(item => {
      return { "value": item[value], "label": item[label], "selected": false }
    });
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  notImplement() {
    this.toasterShowWarning("Atenção", "Em Implementação!");
  }

  isAndroid() {
    return navigator.userAgent.match(/Android/i);
  }

  isBlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i);
  }

  isiOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  }

  isOpera() {
    return navigator.userAgent.match(/Opera Mini/i);
  }

  isWindows() {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  }
  isMobile() {
    return (this.isAndroid() || this.isBlackBerry() || this.isiOS() || this.isOpera() || this.isWindows());
  };

  getEnderecoUrlMapa(url): Mapa {
    //https://www.google.com.br/maps/place/alagoas,260,centro,belo horizonte,minas gerais
    let mapa = new Mapa();
    debugger
    url = url.replace("https://www.google.com.br/maps/place/", "");
    let arrayEnd = url.split(',');
    mapa.rua = arrayEnd[0];
    mapa.numero = arrayEnd[1];
    mapa.bairro = arrayEnd[2];
    mapa.cidade = arrayEnd[3];
    mapa.estado = arrayEnd[4];
    return mapa;
  };

  getUrlParameter(url, sParam): string {
    var results = new RegExp('[\?&]' + sParam + '=([^&#]*)').exec(url);
    if (results == null) {
      return null;
    }
    return decodeURI(results[1]) || "0";
  };

  montarUrl(tipo: string, conteudo: string, mensagem = ""): string {
    let url = "";

    switch (tipo.toLowerCase()) {
      case "link": {
        url = conteudo;
        break;
      }
      case "whatsapp": {
        conteudo = conteudo.replace(/\D+/g, '');
        url = "https://api.whatsapp.com/send?phone=+55" + conteudo + "&text=" + mensagem;
        break;
      }
      case "telegram": {
        url = "https://t.me/" + conteudo;
        break;
      }
      case "facebook": {
        url = "https://www.facebook.com/" + conteudo;
        break;
      }
      case "twitter": {
        url = "https://twitter.com/" + conteudo;
        break;
      }
      case "youtube": {
        url = "https://www.youtube.com/c/" + conteudo;
        break;
      }
      case "linkedin": {
        url = "https://www.linkedin.com/in/" + conteudo;
        break;
      }
      case "instagram": {
        url = "https://www.instagram.com/" + conteudo
        break;
      }
      case "mapa": {
        url = "https://www.google.com.br/maps/place/" + conteudo
        break;
      }
      case "telefone": {
        url = "tel:" + conteudo.replace(/\D+/g, '');
        break;
      }
      case "celular": {
        url = "tel:" + conteudo.replace(/\D+/g, '');
        break;
      }
      case "site": {
        url = this.validateLink(conteudo);
        break;
      }
      default: {
        url = conteudo;
        break;
      }
    }

    return url;
  }

  removeMask(value): string {
    return value.replace(/\D/g, '');
  }

  createGuid(): Guid {
    return Guid.create();
  }

  dateIsValid(date) {
    var dataa = date.split("-");
    let diaa = dataa[2];
    let mess = dataa[1];
    let anoo = dataa[0];
    let maydata = `${diaa}.${mess}.${anoo}`;

    let match = /^(\d{2})([-\/.]?)(\d{2})\2(\d{4})$/.exec(maydata);
    if (match) {
      let dia = parseInt(match[1]);
      let mes = parseInt(match[3]);
      let ano = parseInt(match[4]);

      let d = new Date(ano, mes - 1, dia);
      let dataValida = d.getFullYear() == ano
        && d.getMonth() + 1 == mes
        && d.getDate() == dia;
      return dataValida // true
    } else {
      return false;
    }
  }

  formatDateString(dateString: string) {
    let date = new Date(dateString);

    var day = ('0' + date.getDate()).slice(-2);
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();
    var hour = date.getHours().toString();
    var minute = date.getMinutes().toString();
    var second = date.getSeconds().toString();

    if (hour.length == 1)
      hour = '0' + hour;

    if (minute.length == 1)
      minute = '0' + minute;

    if (second.length == 1)
      second = '0' + second;

    return day + '/' + month + '/' + year + ' ' + hour + ':' + minute + ':' + second;

  }

  formatDateSQL(dateString: string) {
    let date = new Date(dateString);

    var day = ('0' + date.getDate()).slice(-2);
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();
    var hour = date.getHours().toString();
    var minute = date.getMinutes().toString();
    var second = date.getSeconds().toString();

    if (hour.length == 1)
      hour = '0' + hour;

    if (minute.length == 1)
      minute = '0' + minute;

    if (second.length == 1)
      second = '0' + second;

    return  `${year}-${month}-${day}T${hour}:${minute}:${second}`;    //year + '-' + month + '-' + day;

  }

  messageError(err) {

    switch (err.status) {
      case 400: {
        this.toasterShowError("Atenção", err.error[0]);
        break;
      }
      case 400: {
        this.toasterShowError("Atenção", "Não Autorizado");
        break;
      }

      default: {
        this.toasterShowError("Atenção", "Erro Desconhecido");
        break;
      }
    }
  }




  validateLink(str) {
    var tarea = str;
    if (tarea.indexOf("http://") == 0 || tarea.indexOf("https://") == 0)
      return str;
    else
      return "https://" + str;
  }


  getLocation(): GeoLocation {
    let obj = new GeoLocation();
    debugger
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        obj.longitude = position.coords.longitude;
        obj.latitude = position.coords.latitude;
        return obj;
      });
    } else {
      return null; //console.log("No support for geolocation")
    }

  }

  MaiorValorItem(arrayItem: any[], campo: string): number {
    return arrayItem.reduce((op, item) => op = op > item[campo] ? op : item[campo], 0);
  }

  montarCampoFormularioTriagem(idTriagem: string, dados: DadosTemplateEntrada[], ordem: number) {
    let dadosPost = [];
    //let dados1 = dados.map((item) => ({ ...item, iD_TRIAGEM: idTriagem }));
    let options = [];
    dados.forEach(itemDados => {
      debugger
      let opt = null;
      if (itemDados.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Check && itemDados.valores.length > 0) {
        if (itemDados.valoresDetalhes != undefined) {
          opt = itemDados.valoresDetalhes.map((item, index) => {
            debugger
            return {
              ...itemDados,
              agrupamento: itemDados.titulo,
              titulo: item.titulo,
              iD_PERGUNTA: item.iD_PERGUNTA,
              ordem: ordem,
              subOrdem: itemDados.subOrdem + "." + (index + 1)
            };
          });
        } else if (itemDados.detalhesValores != undefined) {
          opt = itemDados.detalhesValores.map((item, index) => {
            debugger
            return {
              ...itemDados,
              agrupamento: itemDados.titulo,
              titulo: item.titulo,
              iD_PERGUNTA: item.iD_PERGUNTA,
              ordem: ordem,
              subOrdem: itemDados.subOrdem + "." + (index + 1)
            };
          });
        }
        options.push(...opt);
      }
    });
    let date = options != null ? [...dados, ...options] : dados;
    //return date;
    debugger
    date.forEach(item => {

      dadosPost.push(
        {
          "ID_TRIAGEM": idTriagem,
          "ID_PERGUNTA": item.iD_PERGUNTA,
          "ID_PERGUNTA_PAI": item.iD_PERGUNTA_PAI,
          "ordem": item.ordem,
          "obrigatorio": item.obrigatorio,
          "agrupamento": item.agrupamento,
          "tipo": item.iD_TIPO_CAMPO_FORMULARIO,
          "pagina": item.pagina,
          "SUB_ORDEM": item.subOrdem

        }
      );
    });
    return dadosPost;
  }

  removePerguntaCheck(array: any[]) {
    let newArray = [];
    array.forEach(item => {
      //if (item.ID_PERGUNTA != 0 || item.tipo == 12)
      if (!(item.tipo == this.tipo.Check && item.agrupamento == undefined))
        newArray.push(item);
    });
    return newArray;
  }

  calculaIdade(ano_aniversario: number, mes_aniversario: number, dia_aniversario: number) {
    var d = new Date,
      ano_atual = d.getFullYear(),
      mes_atual = d.getMonth() + 1,
      dia_atual = d.getDate(),

      ano_aniversario = +ano_aniversario,
      mes_aniversario = +mes_aniversario,
      dia_aniversario = +dia_aniversario,

      quantos_anos = ano_atual - ano_aniversario;

    if (mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario) {
      quantos_anos--;
    }

    return quantos_anos < 0 ? 0 : quantos_anos;
  }

  buildFormData(formData, data, parentKey = null) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {

      const value = data == null ? '' : data;
      if (typeof (data) != 'object')
        formData.append(parentKey, value);
      else {
        debugger
        let nomeArquivo = parentKey.replace("[ValorArquivo]", ".ValorArquivo");
        nomeArquivo = nomeArquivo.replace("[arquivo]", ".arquivo");
        if (value != "")
          formData.append(nomeArquivo, value, value.name);
      }

    }
  }

  jsonToFormData(data) {
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
  }

  createFormData(formData, key, data) {
    if (data === Object(data) || Array.isArray(data)) {
        for (var i in data) {
            this.createFormData(formData, key + '[' + i + ']', data[i]);
        }
    } else {
        formData.append(key, data);
    }
}

  objectToFormData(obj, rootName?, ignoreList?) {
    debugger
    var formData = new FormData();

    function appendFormData(data, root) {
      if (!ignore(root)) {
        root = root || '';
        if (data instanceof File) {
          formData.append(root, data);
        } else if (Array.isArray(data)) {
          for (var i = 0; i < data.length; i++) {
            appendFormData(data[i], root + '[' + i + ']');
          }
        } else if (typeof data === 'object' && data) {
          for (var key in data) {
            if (data.hasOwnProperty(key)) {
              if (root === '') {
                appendFormData(data[key], key);
              } else {
                appendFormData(data[key], root + '.' + key);
              }
            }
          }
        } else {
          if (data !== null && typeof data !== 'undefined') {
            formData.append(root, data);
          }
        }
      }
    }

    function ignore(root) {
      return Array.isArray(ignoreList)
        && ignoreList.some(function (x) { return x === root; });
    }

    appendFormData(obj, rootName);

    return formData;
  }
}
