
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>
<div id="app" class="app">

  <!-- LEFT SECTION -->

  <section id="main-left" class="main-left">
    <!-- header -->
    <div id="header-left" class="header-left">
      <span class="glyphicon glyphicon-menu-hamburger hamburger-btn"></span>
      <span class="glyphicon glyphicon-search search-btn"></span>
      <span class="glyphicon glyphicon-option-vertical option-btn"></span>
    </div>



    <div id="chat-list" class="chat-list">


      <div id="friends" class="friends" *ngFor="let item of itens | orderBy: 'status': 'asc'" (click)="lerConversa(item)">
        <!--<span class="friends-name">{{item | json}}</span>-->

        <div class="profile friends-photo">
          <img src="../../../assets/images/user/user-default.png" alt="">
        </div>
        <div class="friends-credent">
          <span class="friends-name">{{item.nome}}</span>
          <span class="friends-message">CPF: {{item.cpf}}</span>
        </div>
        <span class="badge notif-badge" *ngIf="item.status == 'A'">nova Mensagem</span>
      </div>



    </div>



  </section>



  <!-- RIGHT SECTION -->

  <section id="main-right" class="main-right">
    <!-- header -->
    <div id="header-right" class="header-right">
      <!-- profile pict -->
      <div id="header-img" class="profile header-img">
        <img src="images/ava2.jpg" alt="">
      </div>

      <!-- name -->
      <h4 class="name friend-name">  {{pessoaSelecionada?.nome}} - {{pessoaSelecionada?.cpf}}</h4>

      <!-- some btn -->
      <div class="some-btn">
        <span class="glyphicon glyphicon-facetime-video"></span>
        <span class="glyphicon glyphicon-earphone"></span>
        <span class="glyphicon glyphicon-option-vertical option-btn"></span>
      </div>
    </div>

    <!-- chat area -->
    <div id="chat-area" class="chat-area">
      <!-- chat content -->

      <span *ngFor="let item of chat; let i = index">
          
        <div id="friends-chat" class="friends-chat" *ngIf="item.user != 'adm'">
          <div class="profile friends-chat-photo">
            <img src="../../../assets/images/user/user-default.png" alt="">
          </div>
          <div class="friends-chat-content">
            <p class="friends-chat-name">{{item.user}}</p>
            <p class="friends-chat-balloon">{{item.texto}}</p>
            <!--<h5 class="chat-datetime">Sun, Aug 30 | 15:41</h5>-->
            <h5 class="chat-datetime">{{item.data | date : 'dd/MM/yyyy HH:mm:ss' }}</h5>
          </div>
        </div>
     
        <div id="your-chat" class="your-chat" *ngIf="item.user == 'adm'">
          <p class="your-chat-balloon">{{item.texto}}</p>
          <p class="chat-datetime"><span class="glyphicon glyphicon-ok"></span> {{item.data | date : 'dd/MM/yyyy HH:mm:ss' }}</p>
        </div>        
      </span> 

      <span  class="lastItem" id="lastItem"></span>



    </div>

    <!-- typing area -->
    <div id="typing-area" class="typing-area">
   

        <input [(ngModel)]="message" name="message" class="type-area" placeholder="&nbsp;&nbsp;&nbsp; Escreva sua Mensagem ...">
    
        <button type="button" class="btn btn-success  send-btn" (click)="sendMessage()">
          <i class="fa fa-paper-plane"></i>
          Enviar 
      </button>
     
      <!--<input id="type-area" class="type-area" placeholder="Type something...">
     
      <div class="attach-btn">
        <span class="glyphicon glyphicon-paperclip file-btn"></span>
        <span class="glyphicon glyphicon-camera"></span>
        <span class="glyphicon glyphicon-picture"></span>
      </div>
   
      <span class="glyphicon glyphicon-send send-btn"></span>-->
    </div>
  </section>
</div>