<app-ui-modal #myLargeModalLabel [hideFooter]="true" [dialogClass]="'modal-xl'" >
  <div class="app-modal-header">
    <h5 class="modal-title">Cadastro de Perguntas</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="closeModal(myLargeModalLabel)"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <app-cadastro-pergunta-formulario></app-cadastro-pergunta-formulario>
  </div>
</app-ui-modal>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>


<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Cadastro de Fromulário" [options]="false">


      <aw-wizard #wizard class="arc-wizard">
        <!--<aw-wizard-step stepTitle="Escolha do Programa">
            <div class="row" style="margin-top: 30px;">


              <div class="col-md-6 ">
                <label class="sub-title">Selecione um Programa</label>
                <ng-select [ngClass]="'tag-select'" [options]="arrayProgramas" [(ngModel)]="programaSelecionado">
                </ng-select>

              </div>
              <div class="col-md-6 ">
                <label class="sub-title">Selecione uma Turma</label>
                <ng-select [ngClass]="'tag-select'" [options]="arrayTurmas" [(ngModel)]="turmaSelecionada">
                </ng-select>

              </div>
              <br> <br> <br> <br>


              <div class="col-sm-12 centered-content">
                <div class="btn-group mt-10">
                  <button type="button" class="btn btn-primary btn-lg" awNextStep>Próximo</button>
                </div>
              </div>
            </div>
          </aw-wizard-step>-->
        <aw-wizard-step stepTitle="Cabeçalho Formulário">
          <div class="row">
            <app-card [hidHeader]="true" [options]="false" class="col-sm-12">
              <div class="row ">
                <div class="col-sm-6 col-xl-6">
                  <label class="sub-title">Título do Formulário</label>
                  <input type="text" class="form-control" [(ngModel)]="nome" name="nome" placeholder="">

                </div>
                <div class="col-md-6 ">
                  <label class="sub-title">Selecione um Programa</label>
                  <ng-select [ngClass]="'tag-select'" [options]="arrayProgramas" [(ngModel)]="programaSelecionado">
                  </ng-select>

                </div>


                <!--<div class="col-sm-12 col-xl-3 ">
                    <label class="sub-title">Data Inicio Triagem</label>
                    <input type="date" class="form-control" [(ngModel)]="dataInicial" name="dataInicial" placeholder="">
                  </div>
                  <div class="col-sm-12 col-xl-3 ">
                    <label class="sub-title">Data Fim Triagem</label>
                    <input type="date" class="form-control" [(ngModel)]="dataFinal" name="dataFinal" placeholder="">
                  </div>
                  <div class="col-sm-12 col-xl-3">
                    <label class="sub-title">Pontuação </label>
                    <input type="number" class="form-control" [(ngModel)]="inpuPontuacao" name="inpuPontuacao"
                      placeholder="">
                  </div>
                  <div class="col-sm-12 col-xl-3"></div>
                  <div class="col-sm-12 col-xl-6">
                    <label class="sub-title">Foto Banner </label>
                    <file-upload [(ngModel)]="uploadedFilesCapa" [fileslimit]="1" accept="image/*" simple></file-upload>
                  </div>-->
              </div>
            </app-card>
            <div class="col-sm-12 centered-content">
              <div class="btn-group mt-10">
                <button type="button" class="btn btn-secondary btn-lg" awPreviousStep>Voltar</button>
                <button type="button" class="btn btn-primary btn-lg" awNextStep>Próximo</button>
              </div>
            </div>
          </div>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Formulação das Perguntas">
          <div class="row">

            <div class="col-md-12">
              <div class="row" *ngFor="let x of arrayFormulario; let index = index;">
                <div class="col-md-3"></div>
                <div class="col-md-6 boxQuestao" [class.active]="x == selectedItem" (click)="onItemSelect(x)">
                  <div>
                    <!--<h5 class="card-title">Special title treatment</h5>-->
                    <div class="card-title" *ngIf="x.paginaCabecalho" class="row paginacaoCabecalho">
                      <div class="text-white text-center col-10">
                        Página {{x.pagina}}
                      </div>
                      <div class="col-2 text-white text-center">
                        <button type="button" (click)="removerpagina()" class="btn btn-icon btn-danger fecharpagina "
                          ngbTooltip="Remover Página"><i class="feather icon-trash-2 "></i> </button>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12 col-xl-8">
                        <label class="sub-title" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO != '9'">{{x.titulo}}</label>
                        <div class="custom-control custom-checkbox" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '9' ">

                          <label class="sub-title">
                            <span [innerHTML]="x.titulo"></span>
                          </label>
                          <div class="">

                            <div class="form-group " *ngFor="let item of x.valores; let i = index">
                              <div class="row ">

                                <div class="col-1">
                                </div>
                                <div class="col-8">

                                  <div class="checkbox">
                                    {{i}} - <input type="checkbox" name="{{item}}" id="{{item}}">
                                    <label for="{{item}}" class="cr">{{item}}</label>
                                  </div>
                                </div>
                                <div class="col-1">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <fieldset class="form-group" *ngIf=" x.iD_TIPO_CAMPO_FORMULARIO == '10'">
                          <div class="row">
                            <div class="col-sm-9">
                              <div class="form-check" *ngFor="let item of x.valores; let i = index">
                                <input class="form-check-input" type="radio" name="gridRadios" id="{{item}}"
                                  value="{{item}}">
                                <label class="form-check-label" for="gridRadios1">{{item}}</label>
                              </div>

                            </div>
                          </div>
                        </fieldset>

                        <fieldset class="form-group" *ngIf=" x.iD_TIPO_CAMPO_FORMULARIO == '12'">
                          <div class="row">
                            <div class="col-md-12">
                              <table>
                                <tr class="text-center">
                                  <th class="col-md-8"></th>
                                  <th class="col-md-4" *ngFor="let item of x.valoresDetalhes[0].valores;">{{item}}</th>
                                </tr>
                                <tr *ngFor="let item of x.valoresDetalhes; let i = index" class="text-center">
                                  <td class="col-md-8 text-left">

                                    <span [innerHTML]="item.titulo"></span>
                                  </td>
                                  <td class="col-md-4" *ngFor="let it of item.valores;">
                                    <input class="form-check-input" type="radio" name="gridRadios" id="{{it}}-{{i}}"
                                      value="{{it}}-{{i}}">
                                    <label class="form-check-label" for="gridRadios{{i}}"></label>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </fieldset>


                        <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '3'"
                          [textMask]="{mask: maskCPF}">

                        <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '5'">

                        <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '7'"
                          [textMask]="{mask: numberMaskInt}">

                        <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '4'">


                        <select class="form-control form-control-default" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '8'">
                          <option *ngFor="let i of x.valores">{{i}}
                        </select>

                        <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '2'"
                          [textMask]="{mask: maskTelefoneCelular}">
                        <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '1'">

                        <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '6'"
                          [textMask]="{mask: numberMaskReal}">


                      </div>
                      <div class="col-sm-2 col-xl-1">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input" id="{{x.titulo}}"
                            [(ngModel)]="x.obrigatorio">
                          <label class="custom-control-label" for="{{x.titulo}}">Obrigatório?</label>
                        </div>
                      </div>
                      <div class="col-sm-1 col-xl-1">
                        <label class="sub-title"> &nbsp;</label><br>
                        <button type="button" (click)="deleteItem(index)" class="btn btn-icon btn-danger"
                          ngbTooltip="Retirar da lista"><i class="feather icon-trash-2 "></i> </button>
                      </div>
                    </div>
                    <div class="row" style="padding: 30px;">
                      <label class="sub-title"> Escolha a Pergunta de Recuperação </label>
                      <ng-select [ngClass]="'tag-select'" class="class-select" [options]="arrayPerguntasRecuperacao"
                      [(ngModel)]="x.aRRAY_RECUPERACAO" [multiple]="true">
                      </ng-select>

                    </div>

                  </div>
                </div>
                <div class="col-md-3">
                  <div class="drag-list__move-items" *ngIf="x == selectedItem">
                    <button style="font-size:2em;" (click)="moveUp()"
                      [disabled]="!selectedItem || selectedItem && selectedItem.position == 1">
                      <i class="fa fa-arrow-up" style="color:#129697"></i>
                    </button>
                    <button style="font-size:2em;" (click)="moveDown()"
                      [disabled]="!selectedItem || selectedItem && arrayFormulario.length == selectedItem.position">
                      <i class="fa fa-arrow-down" style="color:#129697"></i>
                    </button>

                    <a type="button" class="btn btn-icon btn-outline-success buttonRadiusSemBorda"><i class="fa fa-bars"
                        ngbTooltip="Adicionar uma Página" (click)="adicionarpagina()"></i></a>
                  </div>
                </div>
              </div>

              <div class="drag-list__wrap center">
                <!--<div class="drag-list__item">
                    <div class="drag-list__item" [class.active]="x == selectedItem" (click)="onItemSelect(x)"
                      *ngFor="let x of arrayFormulario; let index = index;">

                      <div *ngIf="x.paginaCabecalho" class="row paginacaoCabecalho">
                        <div class="text-white text-center col-10">
                          Página {{x.pagina}}
                        </div>
                        <div class="col-2 text-white text-center">
                          <button type="button" (click)="removerpagina()" class="btn btn-icon btn-danger fecharpagina "
                            ngbTooltip="Remover Página"><i class="feather icon-trash-2 "></i> </button>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12 col-xl-8">
                          <label class="sub-title" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO != '9'">{{x.titulo}}</label>
                          <div class="custom-control custom-checkbox" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '9' ">

                            <label class="sub-title">{{x.titulo}}</label>
                            <div class="">

                              <div class="form-group " *ngFor="let item of x.valores; let i = index">
                                <div class="row ">

                                  <div class="col-1">
                                  </div>
                                  <div class="col-8">

                                    <div class="checkbox">
                                      {{i}} - <input type="checkbox" name="{{item}}" id="{{item}}">
                                      <label for="{{item}}" class="cr">{{item}}</label>
                                    </div>
                                  </div>
                                  <div class="col-1">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <fieldset class="form-group" *ngIf=" x.iD_TIPO_CAMPO_FORMULARIO == '10'">
                            <div class="row">
                              <div class="col-sm-9">
                                <div class="form-check" *ngFor="let item of x.valores; let i = index">
                                  <input class="form-check-input" type="radio" name="gridRadios" id="{{item}}"
                                    value="{{item}}">
                                  <label class="form-check-label" for="gridRadios1">{{item}}</label>
                                </div>

                              </div>
                            </div>
                          </fieldset>

                          <fieldset class="form-group" *ngIf=" x.iD_TIPO_CAMPO_FORMULARIO == '12'">
                            <div class="row">
                              <div class="col-md-12">
                                <table>
                                  <tr class="text-center">
                                    <th class="col-md-8"></th>
                                    <th class="col-md-4" *ngFor="let item of x.valoresDetalhes[0].valores;">{{item}}</th>
                                  </tr>
                                  <tr *ngFor="let item of x.valoresDetalhes; let i = index" class="text-center">
                                    <td class="col-md-8 text-left">{{item.titulo}}</td>
                                    <td class="col-md-4" *ngFor="let it of item.valores;">
                                      <input class="form-check-input" type="radio" name="gridRadios" id="{{it}}-{{i}}"
                                        value="{{it}}-{{i}}">
                                      <label class="form-check-label" for="gridRadios{{i}}"></label>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </fieldset>


                          <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '3'"
                            [textMask]="{mask: maskCPF}">

                          <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '5'">

                          <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '7'"
                            [textMask]="{mask: numberMaskInt}">

                          <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '4'">


                          <select class="form-control form-control-default" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '8'">

                            <option *ngFor="let i of x.valores">{{i}}
                          </select>

                          <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '2'"
                            [textMask]="{mask: maskTelefoneCelular}">
                          <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '1'">

                          <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '6'"
                            [textMask]="{mask: numberMaskReal}">


                        </div>
                        <div class="col-sm-2 col-xl-1">
                          <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="{{x.titulo}}"
                              [(ngModel)]="x.obrigatorio">
                            <label class="custom-control-label" for="{{x.titulo}}">Obrigatório?</label>
                          </div>
                        </div>
                        <div class="col-sm-1 col-xl-1">
                          <label class="sub-title"> &nbsp;</label><br>
                          <button type="button" (click)="deleteItem(index)" class="btn btn-icon btn-danger"
                            ngbTooltip="Retirar da lista"><i class="feather icon-trash-2 "></i> </button>
                        </div>
                      </div>
                    </div>

                  </div>-->
                <!--<div class="drag-list__move-items" [class.active]="selectedItem">
                    <button style="font-size:2em;" (click)="moveUp()"
                      [disabled]="!selectedItem || selectedItem && selectedItem.position == 1">
                      <i class="fa fa-arrow-up"></i>
                    </button>
                    <button style="font-size:2em;" (click)="moveDown()"
                      [disabled]="!selectedItem || selectedItem && arrayFormulario.length == selectedItem.position">
                      <i class="fa fa-arrow-down"></i>
                    </button>

                    <a type="button" class="btn btn-icon btn-outline-success buttonRadiusSemBorda"><i class="fa fa-bars"
                        ngbTooltip="Adicionar uma Página" (click)="adicionarpagina()"></i></a>
                  </div>-->
              </div>


              <div class="col-sm-12 col-xl-12 " (click)="onItemSelect('')" *ngIf="habilitarForm">
                <!-- Default select start -->
                <app-card cardTitle="Escolha as perguntas para montar Formulario" [options]="false">
                  <div class="row">
                    <div class="col-12 text-right">
                      <button type="button" class="btn btn-primary" data-toggle="modal"
                        (click)="openModal(myLargeModalLabel)">Cadastrar Nova Pergunta</button>
                    </div>
                    <br> <br>
                    <input type="text" class="form-control" [(ngModel)]="query"
                      placeholder="Digite seu filtro, sera feito uma busca em todas as colunas"><br><br><br>
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <div style="height:380px; ">
                          <thead>
                            <tr>
                              <th>Instrumento/Escala</th>
                              <th>Título</th>
                              <th>Descricão</th>
                              <th>TAG</th>
                              <th>Tipo Campo</th>
                              <th></th>
                              <th></th>
                              <th>Ver/Add</th>
                              <th hidden></th>

                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of arrayPerguntas | fullTextSearch:query">
                              <td class="col-1">{{item.grupO_DESCRICAO }}</td>
                              <td class="col-4">{{item.titulo }}</td>
                              <td class="col-4">
                                <span [innerHTML]="item.descricao"></span>
                              </td>
                              <td class="col-4">{{item.tag }}</td>
                              <td class="col-1">{{item.tipO_DESCRICAO}}</td>
                              <td class="col-1">
                                <i *ngIf="item.selecionado" class="feather icon-check-circle text-c-green d-block f-20 "
                                  ngbTooltip="Item Adicionado"></i>
                              </td>
                              <td>
                                <span *ngIf="item.revisado" class="badge badge-success">REVISADO</span>
                                <span *ngIf="!item.revisado" class="badge badge-danger">NÃO REVISADO</span>
                              </td>
                              <td class="col-1">
                                <button type="button" class="btn btn-icon btn-info btn-sm "
                                  (click)="openModalSwal(item)" ngbTooltip="Ver Pergunta">
                                  <i class="fa fa-eye"></i>
                                </button>
                                <button type="button" class="btn btn-icon btn-success btn-sm " (click)="addItem(item)"
                                  ngbTooltip="Adicionar Pergunta">
                                  <i class="fa fa-plus"></i>
                                </button>
                              </td>
                              <td hidden>
                                <div id="{{item.titulo}}">
                                  <app-visualizar-perguntas [pergunta]="item" [mostrarObrigatorio]="false">
                                  </app-visualizar-perguntas>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </div>
                      </table>
                    </div>

                  </div>

                  <div class="row" align="center">
                    <div class="col-sm-12 col-xl-12 m-b-30">

                    </div>
                  </div>
                </app-card>
              </div>


            </div>

            <div class="col-sm-12 centered-content">
              <div class="btn-group mt-10">
                <button type="button" class="btn btn-secondary btn-lg" awPreviousStep>Voltar</button>
                <button type="button" class="btn btn-primary btn-lg" awNextStep>Próximo</button>
              </div>
            </div>
          </div>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Salvar Formulário">
          <div class="row">
            <div class="col-sm-12">
              <div class="" (click)="onItemSelect('')">
                <app-card [hidHeader]="true" align="center">
                  <button type="button" class="btn btn-success btn-lg" (click)="criarTriagem()"> ENVIAR FORMULÁRIO
                    &nbsp;<i class="feather icon-check-circle"></i>
                  </button>
                </app-card>
              </div>
            </div>
            <div class="col-sm-12 centered-content">
              <div class="btn-group mt-10">
                <button type="button" class="btn btn-secondary btn-lg" awPreviousStep>Voltar</button>
                <!--<button type="button" class="btn btn-success btn-sm" awResetWizard>Finished</button>-->
              </div>
            </div>
          </div>
        </aw-wizard-step>
      </aw-wizard>








      <div class="row">



        <!--<div class="col-sm-12 col-xl-6 m-b-30">
              <label class="sub-title">Descricao Triagem</label>
              <textarea name="" id="" rows="3" class="form-control" [(ngModel)]="descricaoQrcode"
                name="descricaoQrcode"></textarea>
            </div>-->
      </div>



    </app-card>
  </div>

</div>
