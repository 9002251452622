import { TmplAstRecursiveVisitor } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DadosTemplateEntrada } from 'src/app/model/DadosTemplateEntrada';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { ResponseUser } from 'src/app/model/User.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, maskCPF, maskTelefoneCelular, numberMaskInt, numberMaskReal } from 'src/environments/environment';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cadastro-triagem-novo',
  templateUrl: './cadastro-triagem-novo.component.html',
  styleUrls: ['./cadastro-triagem-novo.component.scss']
})
export class CadastroTriagemNovoComponent implements OnInit {

  query: string;
  maskCPF = maskCPF;
  maskTelefoneCelular = maskTelefoneCelular;
  numberMaskInt = numberMaskInt;
  numberMaskReal = numberMaskReal;

  habilitarForm: boolean;
  user: ResponseUser;
  arrayTipos: any;
  campoLabel: string;
  descricaoLabel: string;
  inpuPontuacao: number;

  perguntaSelecionadaModal?: any;

  arraySelectOpcao: string[];
  tipoCampoSelecionado: string;
  ehObrigatorio: boolean;

  nome: string;
  descricao: string;
  selectedItem: any;

  arrayFormulario: DadosTemplateEntrada[];
  inputOpcoes: string;

  arrayProgramas: any;
  programaSelecionado: any;
  uploadedFilesCapa: File;
  instrucao: string;
  descricaoQrcode: string;
  dataInicial: string;
  dataFinal: string;

  arrayPerguntas: any[];
  perguntaSelecionada: any;
  arrayTurmas: any[];
  turmaSelecionada: any;



  public rateSquare = 2;

  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) {
    this.habilitaForm();
    this.inpuPontuacao = 0;
  }

  buscarPerguntas() {
    this.util.showLoading();
    let url = API + "Pergunta/Pergunta?IS_RESUMIR_AGRUPAMENTOS=true";
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayPerguntas = response.data;
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Perguntas");
      });
  }

  buscarProgramas() {
    this.util.showLoading();
    let url = API + "Programa/Programa";
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayProgramas = this.util.convertIoptions(response.data, "iD_PROGRAMA", "descricao");
        this.util.closeLoading();
        this.buscarTurmas();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Programa");
      });
  }


  buscarTurmas() {
    this.util.showLoading();
    let url = API + "Turma/Turma";
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayTurmas = this.util.convertIoptions(response.data, "iD_TURMA", "descricao");
        console.log(this.arrayTurmas);
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Turma");
      });
  }

  buscaQtdSeccsao() {
    let numpagina = 0;
    debugger
    this.arrayFormulario.forEach(item => {
      if (item.paginaCabecalho)
        numpagina++
    });
    return numpagina;
  }

  atualizarpagina() {
    let numpagina = this.buscaQtdSeccsao();
    let pagina = 0;
    debugger
    this.arrayFormulario.forEach(item => {
      if (item.paginaCabecalho) {
        pagina++;
        //item.pagina = `${pagina}/${numpagina}`;        
      }
      item.pagina = pagina;
    });
  }


  removerpagina() {
    if (this.buscaQtdSeccsao() > 1) {
      this.selectedItem.paginaCabecalho = false;
      this.atualizarpagina();
    } else {
      this.util.toasterShowInfo("Atenção", "Não é permitido remover esta Secção");
    }

  }

  adicionarpagina() {
    this.selectedItem.paginaCabecalho = true;
    this.atualizarpagina();

  }

  habilitaForm() {
    this.habilitarForm = true;
  }

  desabilitaForm() {
    this.habilitarForm = false;
  }

  ngOnInit() {
    this.user = this.auth.getUser();
    this.buscarTipos();
    this.buscarPerguntas();
    this.arrayFormulario = [];
    this.arraySelectOpcao = [];
    this.nome = "";
    this.descricao = "";
    this.ehObrigatorio = false;
  }

  validaAdd() {
    if (this.campoLabel == undefined || this.campoLabel == "") {
      this.util.toasterShowError("Atenção", "Entre com a Descricao do Campo");
      return false;
    }

    if (this.tipoCampoSelecionado == "8") { // select
      if (this.arraySelectOpcao == undefined || this.arraySelectOpcao.length == 0) {
        this.util.toasterShowError("Atenção", "Digite com ao menos uma opção");
        return false;
      }
    }

    return true;
  }

  ajustaValorSelecao(selecao) {
    let valores = selecao.split('|');
    return valores;
  }

  addOpcao() {
    debugger
    if (this.inputOpcoes != undefined && this.inputOpcoes != "") {
      this.arraySelectOpcao.push(this.inputOpcoes);
      this.inputOpcoes = "";
    } else {
      this.util.toasterShowWarning("Atenção", "Digite uma opção válida!");
    }
  }

  removeOpcao(index) {
    this.arraySelectOpcao.splice(index, 1);
  }

  transformaColuna(arrayItem: any[]): any[] {
    arrayItem.forEach(item => { item.valores = item.valores.split('|') });
    return arrayItem;
  }

  addItem(item) {
    debugger
    console.log(item);
    if (this.verificaItemjaFoiAdicionado(item)) {

      let dados = new DadosTemplateEntrada();
      dados.valores = [];
      dados.titulo = item.titulo;
      dados.descricao = item.descricao;
      dados.iD_PERGUNTA = item.iD_PERGUNTA;
      dados.iD_TIPO_CAMPO_FORMULARIO = item.iD_TIPO_CAMPO_FORMULARIO;
      dados.ordem = this.arrayFormulario.length + 1;
      dados.subOrdem = dados.ordem.toString();
      dados.obrigatorio = true;
      dados.iD_PERGUNTA_PAI = item.iD_PERGUNTA_PAI;
      //dados.pagina = "1/1";
      dados.pagina = 1;
      dados.paginaCabecalho = this.arrayFormulario.length == 0 ? true : false;
      //let tipo = this.arrayTipos.find(c => c.id == this.tipoCampoSelecionado);
      let tipo = item.iD_TIPO_CAMPO_FORMULARIO;
      if (tipo == 8 || tipo == 10 || tipo == 9) {// selecao de dados
        dados.valores = this.ajustaValorSelecao(item.valores);
        if (tipo == 9)
          dados.valoresDetalhes = item.detalhesValores;
      } else if (tipo == 12) {
        dados.valoresDetalhes = this.transformaColuna(item.filhos)
      }


      item.selecionado = true;
      this.arrayFormulario.push(dados);
      console.log(this.arrayFormulario);
    }   

  }

  limparItem() {
    this.campoLabel = "";
    this.arraySelectOpcao = [];
    this.ehObrigatorio = false;
    this.descricaoLabel = "";
    this.inpuPontuacao = 0;
    //this.desabilitaForm();
  }

  onItemSelect(item) {
    if (item.mode != 'empty') {
      this.selectedItem = item;
    }
  }

  moveUp() {
    let index = this.arrayFormulario.indexOf(this.selectedItem);
    this.arrayFormulario[index].ordem = this.arrayFormulario[index].ordem - 1;
    this.arrayFormulario[index - 1].ordem = this.arrayFormulario[index].ordem + 1;
    this.sortItemsByPostion();
  }

  moveDown() {
    let index = this.arrayFormulario.indexOf(this.selectedItem);
    this.arrayFormulario[index].ordem = this.arrayFormulario[index].ordem + 1;
    this.arrayFormulario[index + 1].ordem = this.arrayFormulario[index].ordem - 1;
    this.sortItemsByPostion();

  }

  sortItemsByPostion() {
    this.arrayFormulario = this.arrayFormulario.sort((opt1, opt2) => {
      if (opt1.ordem < opt2.ordem) {
        return -1;
      }
      if (opt1.ordem > opt2.ordem) {
        return 1;
      }
      return 0
    });
  }

  deleteItem(index) {
    let item = this.arrayFormulario[index];
    this.arrayFormulario.splice(index, 1);
    this.removeIconeCheck(item);
  }

  removeIconeCheck(item) {
    console.log(item.iD_PERGUNTA);
    let index = this.arrayPerguntas.findIndex(c => c['iD_PERGUNTA'] == item.iD_PERGUNTA);
    this.arrayPerguntas[index].selecionado = false;
  }

  verificaItemjaFoiAdicionado(item): boolean {
    var pergunta = this.arrayFormulario.find(c => c.iD_PERGUNTA == item.iD_PERGUNTA);
    if (pergunta != undefined) {
      this.util.toasterShowError("Atenção", "Item já foi adicionado a triagem")
      return false;
    }
    return true;
  }

  buscarTipos() {
    this.util.showLoading();
    var url = API + 'TipoCampoFormulario/TipoCampoFormulario';
    this.ajax.get(url)
      .subscribe(resp => {
        console.log(resp);
        this.arrayTipos = resp.data;
        this.tipoCampoSelecionado = "1"; // texto livre
        this.util.closeLoading();
        this.buscarProgramas();

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      });
  }

  validarTriagem() {

    if (this.programaSelecionado == undefined || this.programaSelecionado == null) {
      this.util.toasterShowError("Atenção", "Escolha um Programa");
      return false;
    }

    if (this.turmaSelecionada == undefined || this.turmaSelecionada == null) {
      this.util.toasterShowError("Atenção", "Escolha uma Turma");
      return false;
    }

    if (this.nome == undefined || this.nome == "") {
      this.util.toasterShowError("Atenção", "Entre com o Nome da Triagem");
      return false;
    }

    if (this.arrayFormulario == undefined || this.arrayFormulario.length == 0) {
      this.util.toasterShowError("Atenção", "Adicione pelo menos um campo no formulario");
      return false;
    }

    if (this.dataInicial == undefined || this.util.removeMask(this.dataInicial) == "" || this.util.removeMask(this.dataInicial).length < 8) {
      this.util.toasterShowError("Atenção", "O Campo Data Inicio não pode ser Vazio ou estar incompleto!");
      return false;
    }

    if (this.dataFinal == undefined || this.util.removeMask(this.dataFinal) == "" || this.util.removeMask(this.dataFinal).length < 8) {
      this.util.toasterShowError("Atenção", "O Campo Data Fim não pode ser Vazio ou estar incompleto!");
      return false;
    }

    if (this.uploadedFilesCapa == undefined || this.uploadedFilesCapa == null) {
      this.util.toasterShowError("Atenção", "Envie uma foto de capa por favor");
      return false;
    }


    return true;
  }


  removePerguntaCheck(array: any[]) {
    let newArray = [];
    array.forEach(item => {
      //if (item.ID_PERGUNTA != 0 || item.tipo == 12)
      if (!(item.tipo == 9 && item.agrupamento == undefined))
        newArray.push(item);
    });
    return newArray;
  }

  montarCampoFormularioTriagem(idTriagem: string, dados: DadosTemplateEntrada[]) {
    let dadosPost = [];
    //let dados1 = dados.map((item) => ({ ...item, iD_TRIAGEM: idTriagem }));
    let options = [];
    dados.forEach(itemDados => {
      
      if (itemDados.iD_TIPO_CAMPO_FORMULARIO == 9 && itemDados.valores.length > 0) {
        let opt = itemDados.valoresDetalhes.map((item, index) => {
          
          return {
            ...itemDados,
            agrupamento: itemDados.titulo,
            titulo: item.titulo,
            iD_PERGUNTA: item.iD_PERGUNTA,
            ordem: index + 1,
            subOrdem: itemDados.subOrdem + "." + (index + 1)
          };
        });
        options.push(...opt);
      }
    });
    let date = options != null ? [...dados, ...options] : dados;
    //return date;
    
    date.forEach(item => {

      dadosPost.push(
        {
          "ID_TRIAGEM": idTriagem,
          "ID_PERGUNTA": item.iD_PERGUNTA,
          "ID_PERGUNTA_PAI": item.iD_PERGUNTA_PAI,
          "ordem": item.ordem,
          "obrigatorio": item.obrigatorio,
          "agrupamento": item.agrupamento,
          "tipo": item.iD_TIPO_CAMPO_FORMULARIO,
          "pagina": item.pagina,
          "SUB_ORDEM": item.subOrdem

        }
      );
    });
    return dadosPost;
  }

  salvarTemplatePerguntas(idTriagem: string) {

    /*let dados = this.arrayFormulario.map((item) => ({ ...item, iD_TRIAGEM: idTriagem }));

    let options = [];
    dados.forEach(itemDados => {
      debugger
      if (itemDados.iD_TIPO_CAMPO_FORMULARIO == 9 && itemDados.valores.length > 0) {
        let opt = itemDados.valores.map((item, index) => {
          return { ...itemDados, agrupamento: itemDados.titulo, titulo: item, valores: null, ordem: index + 1 };
        });
        options.push(...opt);
      }
    });

    let date = options != null ? [...dados, ...options] : dados;*/
    debugger
    let dados = this.montarCampoFormularioTriagem(idTriagem, this.arrayFormulario);
    let data = this.removePerguntaCheck(dados);
    //let data = dados;
    console.log("date juntos", data);


    debugger
    let url = API + "CampoFormularioTriagem/CampoFormularioTriagem/T";
    this.util.showLoading();
    return this.ajax.post(url, data)
      .subscribe((resp: ResponseNet) => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "Cadastrado com sucesso!");
        this.router.navigate(['/home']);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.messageError(err);
      });

  }

  criarTriagem() {

    if (this.validarTriagem()) {

      this.util.showLoading();
      this.salvarTriagem()
        .subscribe((resp: ResponseNet) => {
          debugger
          this.util.closeLoading();
          this.salvarTemplatePerguntas(resp.data.iD_TRIAGEM);

        }, err => {
          debugger
          this.util.closeLoading();
          this.util.messageError(err);
        });

    }

  }

  posicao() {
    console.log(this.arrayFormulario);
  }

  salvarTriagem() {
    let dadosQrcode = {
      "ID_PROGRAMA": this.programaSelecionado,
      "ID_TURMA": this.turmaSelecionada,
      "TITULO": this.nome,
      "DATA_INICIO": this.util.formatDateSQL(this.dataInicial),
      "DATA_FIM": this.util.formatDateSQL(this.dataFinal),
      "STATUS": true,
      "PONTUACAO": this.inpuPontuacao,
      //"Arquivo": this.uploadedFilesCapa,
      "REVISADO": false,
      "INSTRUCAO": this.instrucao
    }
    debugger

    if (this.uploadedFilesCapa != undefined || this.uploadedFilesCapa != null)
      dadosQrcode['Arquivo'] = this.uploadedFilesCapa[0];

    let url = API + "Triagem/Triagem/T";
    let dados = this.util.jsonToFormData(dadosQrcode);

    return this.ajax.postFile(url, dados);
  }

  teste() {
    console.log(this.programaSelecionado);
  }

  openModal(item) {
    debugger
    let htmlPergunta = document.getElementById(item.titulo);
    Swal.fire({
      width: "50%",
      html: "<div align='left'>"+htmlPergunta.innerHTML+"</div>",
      showCloseButton: true,
    });
  }



}
