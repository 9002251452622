import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-detalhe-resultado-aprovado',
  templateUrl: './detalhe-resultado-aprovado.component.html',
  styleUrls: ['./detalhe-resultado-aprovado.component.scss']
})
export class DetalheResultadoAprovadoComponent implements OnInit {

  public tituloTriagem: string;
  public idTriagem: number;
  public htmlRespostas: string;
  public arrayResultado: any[];
  public arrayDivisaoGrupos: any[];
  public arrayDivisaoGruposOriginal: any[];
  public query: string;
  public objResultado: any;
  //public filtroRadio: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) { 
    this.arrayDivisaoGrupos = [];
    //this.filtroRadio = 
  }

  ngOnInit() {
    this.idTriagem = this.activatedRoute.snapshot.params['id'];
    this.tituloTriagem = this.activatedRoute.snapshot.params['titulo'];
    this.buscarDivisaoGrupos();
  }

  buscarAprovados(){
    this.util.showLoading();
    let url = API + "RespostaFormulario/RespostaFormulario/buscarResultadosAprovados/" + this.idTriagem;
    return this.ajax.get(url)
      .subscribe(response => {
        this.objResultado = response.data;
        this.arrayResultado = response.data.listResultadoPorPessoa;
        this.util.closeLoading();
        
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Não existe Pessoas Aprovadas no momento");
      });
  }

  filter(string){
    if(string != null)
      this.arrayDivisaoGrupos = this.arrayDivisaoGruposOriginal.filter(c => c.grupo == string);
    else
      this.arrayDivisaoGrupos = this.arrayDivisaoGruposOriginal;
  }

  buscarDivisaoGrupos(){
    this.util.showLoading();
    let url = API + "DivisaoGrupo/DivisaoGrupo/BuscarGrupos/" + this.idTriagem;
    return this.ajax.get(url)
      .subscribe(response => {
        debugger
        this.arrayDivisaoGruposOriginal = response.data;
        this.arrayDivisaoGrupos = response.data;
        this.util.closeLoading();  

        if(response.data.length == 0)
          this.buscarAprovados();

      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Resultados");
      });
  }

  montarObj(){
    let obj = [];
    this.arrayResultado.forEach(item => { 
        obj.push({ 
          "ID_TRIAGEM": item.iD_TRIAGEM, 
          "ID_RESPONSAVEL_TEA": item.iD_RESPONSAVEL,
          "ID_CRIANCA": item.iD_CRIANCA
        });
    });
    return obj;
  }

  gerarGrupos(){
    let dados = this.montarObj();
    if(dados.length > 0){
      console.log(dados);
      this.util.showLoading();
    let url = API + "DivisaoGrupo/DivisaoGrupo/DividirGrupos";
    return this.ajax.post(url, dados)
      .subscribe(response => {
        console.log("responstar ao gerar grupo",response);
        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Grupos Gerados com Sucesso!");
        this.buscarDivisaoGrupos();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Gerar Grupos");
      });
    }
    
  }

}
