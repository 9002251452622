<div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>título</th>
          <th>Ordem</th>


        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of arrayFormularios">
          <td class="col-10">{{item.titulo }}</td>
          <td class="col-1">
            <button type="button" class="btn btn-icon btn-warning "
            ngbTooltip="Selecionar" (click)="tableSelecionar(item)">
            <i class="feather icon-plus-circle"></i>
          </button>
          </td>


        </tr>
      </tbody>
    </table>
  </div>
