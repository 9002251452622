import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DadosTemplateEntrada } from 'src/app/model/DadosTemplateEntrada';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { ResponseUser } from 'src/app/model/User.model';
import { optionQuestao } from 'src/app/model/valorQuestao';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, maskCPF, maskTelefoneCelular, numberMaskInt, numberMaskReal } from 'src/environments/environment';

@Component({
  selector: 'app-cadastro-questoes',
  templateUrl: './cadastro-questoes.component.html',
  styleUrls: ['./cadastro-questoes.component.scss']
})
export class CadastroQuestoesComponent implements OnInit {

  maskCPF = maskCPF;
  maskTelefoneCelular = maskTelefoneCelular;
  numberMaskInt = numberMaskInt;
  numberMaskReal = numberMaskReal;

  habilitarForm: boolean;
  user: ResponseUser;
  arrayTipos: any;
  campoLabel: string;
  descricaoLabel: string;
  inputPontos: string;
  inputPerguntaCondicional: string;
  habilitaCondicional: boolean;

  arraySelectOpcao: optionQuestao[];
  tipoCampoSelecionado: string;
  ehObrigatorio: boolean;

  nome: string;
  descricao: string;
  selectedItem: any;

  arrayFormulario: DadosTemplateEntrada[];
  inputOpcoes: string;

  inputColunas: string;
  inputLinhas: string;
  arrayColunaMultiplaEscolha: optionQuestao[];
  arrayLinhasMultiplaEscolha: string[];

  arrayProgramas: [];
  arrayGrupoQuestoes: [];
  programaSelecionado: any;
  grupoQuestaoSelecionado: any;
  uploadedFilesCapa: File;
  descricaoQrcode: string;
  dataInicial: string;
  dataFinal: string;
  inicioEscala: number;
  fimEscala: number;
  arrayPalavrasChave: any[];
  eliminatoriaClassificatoria: string;



  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) {
    this.fimEscala = 10;
    this.inicioEscala = 1;
    this.arrayPalavrasChave = [];
    this.eliminatoriaClassificatoria = "N";
    this.inputColunas = "";
    this.inputLinhas = "";
    this.inputOpcoes = "";
    this.arrayColunaMultiplaEscolha = [];
    this.arrayLinhasMultiplaEscolha = [];
  }


  buscarGruposCadastrados() {
    this.util.showLoading();
    let url = API + "TipoClassificacaoPergunta/TipoClassificacaoPergunta";
    return this.ajax.get(url)
      .subscribe(response => {
        //this.arrayProgramas = this.util.convertIoptions(response.data, "iD_PROGRAMA", "descricao");
        this.arrayGrupoQuestoes = this.util.convertIoptions(response.data, "id", "descricao");
        console.log(this.arrayGrupoQuestoes);
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Instrumentos/Escala");
      });
  }

  buscaQtdSeccsao() {
    let numpagina = 0;
    debugger
    this.arrayFormulario.forEach(item => {
      if (item.paginaCabecalho)
        numpagina++
    });
    return numpagina;
  }



  ngOnInit() {
    this.user = this.auth.getUser();
    this.buscarTipos();
    this.arrayFormulario = [];
    this.arraySelectOpcao = [];
    this.nome = "";
    this.descricao = "";
    this.ehObrigatorio = false;
    this.inputColunas = "";
    this.inputLinhas = "";
    this.inputOpcoes = "";
    this.arrayColunaMultiplaEscolha = [];
    this.arrayLinhasMultiplaEscolha = [];
    this.inputPontos = "";
    this.arrayPalavrasChave = [];
    this.inputPerguntaCondicional = "";
    this.habilitaCondicional = false;

  }



  validaAdd() {

    if (this.grupoQuestaoSelecionado == undefined || this.grupoQuestaoSelecionado == "") {
      this.util.toasterShowError("Atenção", "Escolha um Instrumento/Escala !");
      return false;
    }

    if (this.campoLabel == undefined || this.campoLabel == "") {
      this.util.toasterShowError("Atenção", "Entre com a Pergunta por favor.");
      return false;
    }

    if (this.tipoCampoSelecionado == "8") { // select
      if (this.arraySelectOpcao == undefined || this.arraySelectOpcao.length == 0) {
        this.util.toasterShowError("Atenção", "Digite com ao menos uma opção");
        return false;
      }
    }

    return true;
  }

  ajustaValorSelecao() {
    debugger
    let valores = [];
    for (let item of this.arraySelectOpcao) {
      valores.push({ chave: item.value, ponto: "" });
    }
    return valores;
  }




  montarEscala() {
    //this.htmlEscala = 'sdfdsf<br><bar-rating [(rate)]="rateSquare" [max]="4" [theme]="\'square\'"></bar-rating>';
    this.arraySelectOpcao = [];
    if (this.inicioEscala < this.fimEscala) {
      this.inputOpcoes = "";
      for (let i = this.inicioEscala; i <= this.fimEscala; i++) {
        this.arraySelectOpcao.push({ chave: i.toString(), ponto: "", habilitaCampo: "N", textoCampoCondicional: "" });
      }
    } else {
      this.util.toasterShowWarning("Atenção", "O início não pode ser maior que o fim!");
    }
  }

  addColuna() {
    if (this.inputColunas != undefined && this.inputColunas != "") {
      this.arrayColunaMultiplaEscolha.push({ chave: this.inputColunas, ponto: this.inputPontos, habilitaCampo: "N", textoCampoCondicional: "" });
      this.inputColunas = "";
    } else {
      this.util.toasterShowWarning("Atenção", "Digite uma opção válida!");
    }
  }

  addLinha() {
    if (this.inputLinhas != undefined && this.inputLinhas != "") {
      this.arrayLinhasMultiplaEscolha.push(this.inputLinhas);
      this.inputLinhas = "";
    } else {
      this.util.toasterShowWarning("Atenção", "Digite uma opção válida!");
    }
  }


  addOpcao() {
    debugger
    if (this.inputOpcoes != undefined && this.inputOpcoes != "") {
      this.arraySelectOpcao.push({ chave: this.inputOpcoes, ponto: "", habilitaCampo: "N", textoCampoCondicional: "" });
      this.inputOpcoes = "";
    } else {
      this.util.toasterShowWarning("Atenção", "Digite uma opção válida!");
    }
  }

  removeOpcao(index) {
    this.arraySelectOpcao.splice(index, 1);
  }

  removeOpcaoLinhas(index) {
    this.arrayLinhasMultiplaEscolha.splice(index, 1);
  }

  removeOpcaoColuna(index) {
    this.arrayColunaMultiplaEscolha.splice(index, 1);
  }


  limparItem() {
    this.campoLabel = "";
    this.arraySelectOpcao = [];
    this.ehObrigatorio = false;
    this.descricaoLabel = "";
    this.arrayFormulario = [];
    this.inputColunas = "";
    this.inputLinhas = "";
    this.inputOpcoes = "";
    this.arrayColunaMultiplaEscolha = [];
    this.arrayLinhasMultiplaEscolha = [];
    this.inputPontos = "";
    this.eliminatoriaClassificatoria = "N";
    this.arrayPalavrasChave = [];
    this.inputPerguntaCondicional = "";
    this.habilitaCondicional = false;
    //this.desabilitaForm();
  }


  moveUp() {
    let index = this.arrayFormulario.indexOf(this.selectedItem);
    this.arrayFormulario[index].ordem = this.arrayFormulario[index].ordem - 1;
    this.arrayFormulario[index - 1].ordem = this.arrayFormulario[index].ordem + 1;
    this.sortItemsByPostion();
  }

  moveDown() {
    let index = this.arrayFormulario.indexOf(this.selectedItem);
    this.arrayFormulario[index].ordem = this.arrayFormulario[index].ordem + 1;
    this.arrayFormulario[index + 1].ordem = this.arrayFormulario[index].ordem - 1;
    this.sortItemsByPostion();

  }

  sortItemsByPostion() {
    this.arrayFormulario = this.arrayFormulario.sort((opt1, opt2) => {
      if (opt1.ordem < opt2.ordem) {
        return -1;
      }
      if (opt1.ordem > opt2.ordem) {
        return 1;
      }
      return 0
    });
  }

  deleteItem(index) {
    this.arrayFormulario.splice(index, 1);
  }

  buscarTipos() {
    this.util.showLoading();
    var url = API + 'TipoCampoFormulario/TipoCampoFormulario';
    this.ajax.get(url)
      .subscribe(resp => {
        console.log(resp);
        this.arrayTipos = resp.data;
        this.tipoCampoSelecionado = "1"; // texto livre
        this.util.closeLoading();
        this.buscarGruposCadastrados();

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      });
  }

  validarTriagem() {

    if (this.programaSelecionado == undefined || this.programaSelecionado == null) {
      this.util.toasterShowError("Atenção", "Escolha um Programa");
      return false;
    }

    if (this.nome == undefined || this.nome == "") {
      this.util.toasterShowError("Atenção", "Entre com o Nome da Triagem");
      return false;
    }

    if (this.arrayFormulario == undefined || this.arrayFormulario.length == 0) {
      this.util.toasterShowError("Atenção", "Adicione pelo menos um campo no formulario");
      return false;
    }

    return true;
  }

  getTag(): string {
    let tags = "";
    this.arrayPalavrasChave.forEach(item => { tags += item.value + "," });
    if (tags.length > 0)
      return tags.substring(0, tags.length - 1);

    return tags;
  }

  limpaVisualizacao() {
    this.arrayFormulario = [];
    this.arraySelectOpcao = [];
    this.arrayColunaMultiplaEscolha = [];
    this.arrayLinhasMultiplaEscolha = [];
  }

  addItem() {

    this.arrayFormulario = [];
    let dados = new DadosTemplateEntrada();
    if (this.validaAdd()) {


      dados.titulo = this.campoLabel;
      dados.descricao = this.descricaoLabel;
      dados.iD_TIPO_CAMPO_FORMULARIO = parseInt(this.tipoCampoSelecionado);
      dados.obrigatorio = this.ehObrigatorio;
      dados.iD_TIPO_CLASSIFICACAO_PERGUNTA = this.grupoQuestaoSelecionado;
      dados.STATUS = true;
      dados.PONTO = this.inputPontos;
      dados.ELIMINA_CLASSIFICA = this.eliminatoriaClassificatoria;
      dados.TAG = this.getTag();

      let tipo = this.arrayTipos.find(c => c.id == this.tipoCampoSelecionado);

      if (tipo.id == "8") {// selecao de dados
        dados.valores = [];
        dados.valores = this.ajustaValorSelecao();
      } else if (tipo.id == "9" || tipo.id == "10") {// check ou radio
        dados.valores = [];
        //dados.valores = this.justaValores(this.arraySelectOpcao);
        dados.valores = this.justaValores(this.arraySelectOpcao);
        dados.PONTO = this.pegaPonto(this.arraySelectOpcao);
        dados.HABILITA_CAMPO = this.pegaHabilitaCampo(this.arraySelectOpcao);
        //dados.TEXTO_CAMPO_CONDICIONAL = this.pegaTextoCampoCondicional(this.arraySelectOpcao);
        dados.TEXTO_CAMPO_CONDICIONAL = this.inputPerguntaCondicional;
      } else if (tipo.id == "12") {//Grade de Multipla Escolha
        dados.valores = [];
        dados.valores = this.justaValoresDetalhe(this.arrayLinhasMultiplaEscolha);
        dados.PONTO = this.pegaPonto(this.arrayColunaMultiplaEscolha);
        dados.valoresDetalhes = this.arrayColunaMultiplaEscolha;
      }


      this.arrayFormulario.push(dados);
      console.log(this.arrayFormulario);

      setTimeout(function () {
        let el = document.getElementById("idSalvarPergunta");
        el.scrollIntoView();
      }, 500);

    }
    //return dados;
  }

  verificaSeEcondiciona(arrayItens: any[]): boolean{    
    let existe = arrayItens.find(c =>c.habilitaCampo == "S");
    this.habilitaCondicional = existe == undefined ? false : true;
    return this.habilitaCondicional
  }

  justaValores(array: optionQuestao[]): string[] {
    let arrayGo = [];
    array.forEach(element => { arrayGo.push({ 
      chave: element.chave, 
      ponto: element.ponto, 
      habilitaCampo: element.habilitaCampo, 
      textoCampoCondicional : element.textoCampoCondicional
    }) });
    return arrayGo;
  }

  justaValoresDetalhe(array: any[]): string[] {
    let arrayGo = [];
    array.forEach(element => { arrayGo.push({ chave: element, ponto: "" }) });
    return arrayGo;
  }

  pegaPonto(array: optionQuestao[]): string {
    let pontos = "";
    array.forEach(element => { pontos += element.ponto + "|" });
    return pontos.substring(0, pontos.length - 1);
  }

  pegaHabilitaCampo(array: optionQuestao[]): string {
    let hab = "";
    array.forEach(element => { hab += element.habilitaCampo + "|" });
    return hab.substring(0, hab.length - 1);
  }

  pegaTextoCampoCondicional(array: optionQuestao[]): string {
    let hab = "";
    array.forEach(element => { hab += element.textoCampoCondicional + "|" });
    return hab.substring(0, hab.length - 1);
  }

  SalvarPergunta() {
    debugger
    /*if (this.arrayFormulario.length == 0)
      this.addItem();*/

    let options = [];
    let dados = this.arrayFormulario;
    dados.forEach(itemDados => {
      debugger
      if (itemDados.iD_TIPO_CAMPO_FORMULARIO == 9 && itemDados.valores.length > 0) { // check
        let opt = itemDados.valores.map((item, index) => {
          debugger
          return {
            ...itemDados,
            agrupamento: itemDados.titulo,
            titulo: item['chave'],
            valores: null, 
            PONTO:item['ponto'], 
            HABILITA_CAMPO:item['habilitaCampo'], 
            //TEXTO_CAMPO_CONDICIONAL: item['textoCampoCondicional'],
            TEXTO_CAMPO_CONDICIONAL: item['habilitaCampo'] == 'S' ? itemDados.TEXTO_CAMPO_CONDICIONAL : "",
            ordem: index + 1,
            REVISADO: false
          };
        });
        options.push(...opt);
      } else if (itemDados.iD_TIPO_CAMPO_FORMULARIO == 12 && itemDados.valores.length > 0) { // grade de multipla escolha
        debugger
        let guidPai = this.util.createGuid();
        itemDados.TITULO_PAI = itemDados.titulo;
        itemDados.ID_PAI = guidPai.toString();
        //options.push(itemDados);
        let opt = itemDados.valores.map((item, index) => {
          debugger
          return {
            ...itemDados,
            //agrupamento: itemDados.titulo, 
            titulo: item['chave'],
            ordem: index + 1,
            TITULO_PAI: "",
            ID_PAI: guidPai.toString(),
            valores: this.justaValores(this.arrayColunaMultiplaEscolha),
            valoresDetalhes: this.arrayLinhasMultiplaEscolha,
            REVISADO: false
          };
        });
        options.push(...opt);
        //itemDados.valores = null;
      }
    });

    debugger
    let date = options != null ? [...dados, ...options] : dados;



    let url = API + "Pergunta/Pergunta/T";
    this.util.showLoading();
    return this.ajax.post(url, date)
      .subscribe((resp: ResponseNet) => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "Cadastrado com sucesso!");
        //this.router.navigate(['/home']);
        this.limparItem();

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.messageError(err);
      });


  }


  posicao() {
    console.log(this.arrayFormulario);
  }


}
