<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>


<app-card cardTitle="Edição de Triagem" [options]="false" class="col-sm-12">
  <div class="row">
    <div class="col-md-6 text-left">
      <div class="form-group">
        <div class="switch switch-success d-inline m-r-10">
          <input type="checkbox" id="switch-p-1" [(ngModel)]="triagemCabecalho.revisado" [disabled]="habilitarRevisado">
          <label for="switch-p-1" class="cr"></label>
        </div>
        <label>{{ triagemCabecalho.revisado ? 'REVISADO': 'NÃO REVISADO' }}</label>
      </div>
    </div>
    <div class="col-md-6 text-right">
      <span *ngIf="triagemCabecalho.revisado" class="badge badge-success">REVISADO</span>
      <span *ngIf="!triagemCabecalho.revisado" class="badge badge-danger">NÃO REVISADO</span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 ">
      <label class="sub-title">Selecione um Programa</label>
      <select class="form-control" [(ngModel)]="programaSelecionado" [disabled]="triagemCabecalho.revisado">
        <option *ngFor="let item of arrayProgramas" [value]="item.value">{{item.label}}</option>
      </select>
    </div>
    <div class="col-md-6 ">
      <label class="sub-title">Selecione uma Turma</label>
      <select class="form-control" [(ngModel)]="turmaSelecionada" [disabled]="triagemCabecalho.revisado">
        <option *ngFor="let item of arrayTurmas" [value]="item.value">{{item.label}}</option>
      </select>
    </div>
    <br> <br> <br> <br>
  </div>
  <div class="row ">
    <div class="col-sm-12 col-xl-6 ">
      <label class="sub-title">Nome da Triagem</label>
      <input type="text" class="form-control" [(ngModel)]="triagemCabecalho.titulo" name="triagemCabecalho.titulo"
        placeholder="" [disabled]="triagemCabecalho.revisado">
    </div>
    <div class="col-sm-12 col-xl-3 ">
      <label class="sub-title">Data Inicio Triagem</label>
      <input type="date" class="form-control" [(ngModel)]="triagemCabecalho.datA_INICIO"
        name="triagemCabecalho.datA_INICIO" placeholder="" [disabled]="triagemCabecalho.revisado">
    </div>
    <div class="col-sm-12 col-xl-3 ">
      <label class="sub-title">Data Fim Triagem</label>
      <input type="date" class="form-control" [(ngModel)]="triagemCabecalho.datA_FIM" name="triagemCabecalho.datA_FIM"
        placeholder="">
    </div>
    <div class="col-sm-12 col-xl-3">
      <label class="sub-title">Pontuação </label>
      <input type="number" class="form-control" [(ngModel)]="triagemCabecalho.pontuacao"
        name="triagemCabecalho.pontuacao" placeholder="" [disabled]="triagemCabecalho.revisado">
    </div>
    <div class="col-sm-12 col-xl-3"></div>
    <div class="col-sm-12 col-xl-6">
      <label class="sub-title">Foto Banner </label>
      <file-upload [(ngModel)]="triagemCabecalho.banner" [fileslimit]="1" accept="image/*" simple></file-upload>
    </div>
  </div><br><br>
  <div class="row">
    <div class="col-md-12">
      <label class="sub-title">Entre com a Instrução que irá aparecer antes do preenchimento da triagem</label>
      <app-tinymce [(ngModel)]="triagemCabecalho.instrucao" name="triagemCabecalho.instrucao" #tinymce='tinymce'></app-tinymce>
    </div>
  </div>
  <div class="row ">
    <div class="col-md-12 text-right">
      <br>
      <button type="button" class="btn btn-icon btn-success " (click)="salvarCabecalho()"><i
          class="feather icon-check-circle"></i> Salvar Cabecalho</button>
    </div>

  </div>
</app-card>
<div class="row" *ngFor="let item of arrayPerguntas; let index = index;">
  <div class="col-md-3"></div>
  <div class="col-md-6 boxQuestao" [class.active]="!triagemCabecalho.revisado && item == selectedItem"
    (click)="onItemSelect(item)">
    <div class="card-title" *ngIf="item.eH_CABECALHO" class="row paginacaoCabecalho">
      <div class="text-white text-center col-10">
        Página {{item.pagina}}
      </div>
      <div class="col-2 text-white text-center">
        <button type="button" (click)="removerpagina()" class="btn btn-icon btn-danger fecharpagina "
          ngbTooltip="Remover Página" [disabled]="triagemCabecalho.revisado"><i class="feather icon-trash-2 "></i>
        </button>
      </div>
    </div>
    <app-visualizar-perguntas [pergunta]="item" [mostrarObrigatorio]="true"></app-visualizar-perguntas>
  </div>
  <div class="col-md-3">
    <div class="drag-list__move-items" *ngIf="!triagemCabecalho.revisado && item == selectedItem">
      <button style="font-size:2em;" (click)="moveUp()"
        [disabled]="!selectedItem || selectedItem && selectedItem.position == 1">
        <i class="fa fa-arrow-up" style="color:#129697"></i>
      </button>
      <button style="font-size:2em;" (click)="moveDown()"
        [disabled]="!selectedItem || selectedItem && arrayPerguntas.length == selectedItem.position">
        <i class="fa fa-arrow-down" style="color:#129697"></i>
      </button>

      <a type="button" class="btn btn-icon btn-outline-success buttonRadiusSemBorda"><i class="fa fa-bars"
          ngbTooltip="Adicionar uma Página" (click)="adicionarpagina()"></i></a>
      <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBorda"><i class="fa fa-times"
          ngbTooltip="Remover Pergunta" (click)="removerPergunta(index)"></i></a>
    </div>
  </div>
</div>

<app-card [hidHeader]="true" [options]="false" class="col-sm-12" *ngIf="!triagemCabecalho.revisado">
  <div class="row ">
    <div class="col-md-12 text-center">

      <button type="button" class="btn btn-icon btn-success btn-lg" (click)="salvarPerguntas()"
        [disabled]="triagemCabecalho.revisado"><i class="feather icon-check-circle"></i> Salvar Alteracão em Perguntas
        Triagem</button>
    </div>

  </div>
</app-card>


<app-card cardTitle="Escolha as perguntas para montar a triagem" [options]="false" *ngIf="!triagemCabecalho.revisado">
  <div class="row">


    <input type="text" class="form-control" [(ngModel)]="query"
      placeholder="Digite seu filtro, sera feito uma busca em todas as colunas"><br><br><br>
    <div class="table-responsive">
      <table class="table table-hover">
        <div style="height:380px; ">
          <thead>
            <tr>
              <th>Instrumento/Escala </th>
              <th>Título</th>
              <th>Descricão</th>
              <th>TAG</th>
              <th>Tipo Campo</th>
              <th></th>
              <th>Ver/Add</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of arrayTodasPerguntas | fullTextSearch:query">
              <td class="col-1">{{item.grupO_DESCRICAO }}</td>
              <td class="col-4">{{item.titulo }}</td>
              <td class="col-4">{{item.descricao }}</td>
              <td class="col-4">{{item.tag }}</td>
              <td class="col-1">{{item.tipO_DESCRICAO}}</td>
              <td class="col-1">
                <i *ngIf="item.selecionado" class="feather icon-check-circle text-c-green d-block f-20 "
                  ngbTooltip="Item Adicionado"></i>
              </td>
              <td class="col-1">
                <button type="button" class="btn btn-icon btn-info btn-sm " (click)="this.util.notImplement()"
                  ngbTooltip="Ver Pergunta">
                  <i class="fa fa-eye"></i>
                </button>
                <button type="button" class="btn btn-icon btn-success btn-sm " (click)="addItem(item)"
                  ngbTooltip="Adicionar Pergunta">
                  <i class="fa fa-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </div>
      </table>
    </div>
  </div>

</app-card>
