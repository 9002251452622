<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Aguarde ...</p>
  </ngx-spinner>
  
  <div class="row">
    <div class="col-12">
      <app-card cardTitle="Cadastro Meeting" [options]="false">
        <div class="row">
          <div class="col-sm-12 col-xl-4 ">
            <label class="sub-title">Nome Sala</label>
            <input type="text" class="form-control" [(ngModel)]="nome" name="nome" placeholder="" (keyup)="generateSlug()">
          </div>
          <div class="col-sm-12 col-xl-4 ">
            <label class="sub-title">Data Expiração</label>
            <input type="datetime-local" class="form-control" [(ngModel)]="dataEvento" name="dataEvento" placeholder="">
          </div>
          <div class="col-sm-12 col-xl-4 ">
            <label class="sub-title">Tamanho da Sala</label>
            <select [(ngModel)]="tamanhoSala" name="tamanhoSala" class="form-control">
                <option value="group">2-200</option>
                <option value="normal">2-4</option>
              </select>
          </div>
          <div class="col-sm-12 col-xl-4 ">
            <br>
            <button type="button" class="btn btn-success " (click)="salvar()"  style="margin-top: 6px;">Criar nova sala &nbsp;<i
                class="feather icon-check-circle"></i>
            </button>
            &nbsp;&nbsp;&nbsp;
            <button type="button" class="btn btn-warning " (click)="limpar()" *ngIf="meetingSelecionada != null" style="margin-top: 6px;">LIMPAR &nbsp;<i
                class="feather icon-check-circle"></i>
            </button>
          </div>
        </div>
  
  
  
      </app-card>
    </div>
    <div class="col-12">
      <app-card cardTitle="Meeting Cadastrado" blockClass="table-border-style" [options]="false">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Inicio</th>
                <th>Fim</th>      
                <th></th>            
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of arrayMeeting">
                <td>{{item.roomName }}</td>
                <td>{{item.startDate | date : 'dd/MM/yyyy'}}</td>
                <td>{{item.endDate | date : 'dd/MM/yyyy'}}</td>
                <td>
                  <button type="button" class="btn btn-icon btn-warning " (click)="visualizarLinks(item)">
                    <i class="feather icon-eye"></i>
                  </button>
                  &nbsp;&nbsp;
                  <button type="button" class="btn btn-icon btn-danger " (click)="desabilitar(item.meetingId)">
                    <i class="fa fa-trash"></i>
                  </button>
  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </app-card>
    </div>
  </div>
  