import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-detalhe-resultado-triagem',
  templateUrl: './detalhe-resultado-triagem.component.html',
  styleUrls: ['./detalhe-resultado-triagem.component.scss']
})
export class DetalheResultadoTriagemComponent implements OnInit {

  public tituloTriagem: string;
  public idTriagem: number;
  public htmlRespostas: string;
  public arrayResultado: any[];
  public query: string;
  public objResultado: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService

  ) { }

  ngOnInit() {
    this.idTriagem = this.activatedRoute.snapshot.params['id'];
    this.tituloTriagem = this.activatedRoute.snapshot.params['titulo'];
    this.buscar();
  }

  buscar(){
    this.util.showLoading();
    let url = API + "RespostaFormulario/RespostaFormulario/buscarResultados/" + this.idTriagem;
    return this.ajax.get(url)
      .subscribe(response => {
        this.objResultado = response.data;
        this.arrayResultado = response.data.listResultadoPorPessoa;
        this.util.closeLoading();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Resultados");
      });
  }

  /*buscar() {
    this.htmlRespostas = "";
    var htmlBody = "";
    this.util.showLoading();
    let url = API + "RespostaFormulario/RespostaFormulario?ID_TRIAGEM=" + this.idTriagem;
    return this.ajax.get(url)
      .subscribe((resp: any) => {

        let arrayDetalhes = resp.data;
        var idGrupo = resp.data[0].iD_RESPOSTA_METADADO_FORMULARIO;
        var tabela = '';
        var htmlPagina ='';

        for (var item of arrayDetalhes) {
          if (idGrupo != item['iD_RESPOSTA_METADADO_FORMULARIO']) {
            tabela = '';
            idGrupo = item['iD_RESPOSTA_METADADO_FORMULARIO'];
            console.log("fim agrupamento");
            htmlPagina+=this.getTableResposta(htmlBody,item.ip,this.util.formatDateString(item.datA_RESPOSTA));
            htmlBody = "";
            
          }

          var grupoPergunta = item['triagemAgrupamento'];
          htmlBody += `<tr>       
                                <td>${item['titulo']}</td>        
                                <td>${item['valor']}</td>                                                                      
                                <td>${grupoPergunta?grupoPergunta:''}</td>   
                            </tr> `;

        }

        htmlPagina+=this.getTableResposta(htmlBody,item.ip,this.util.formatDateString(item.datA_RESPOSTA));

        this.htmlRespostas =htmlPagina;

        //alert('finalizou');
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Detalhes");
      });
  }


  getTableResposta(tabela: string, ip: string,  respondidoEm: string) {
    var message = ` <fieldset><legend><h6>Respondido em ${respondidoEm}, ip:${ip}</h6></legend>	
    <table class="table">
    <thead">                                                                       
    <tr>       
      <th>Campo</th>        
      <th>Resposta</th>  
      <th>Resposta Grupo</th>                                                                      
    </tr>                                                                         
   </thead>                                                                      
    <tbody>
    ${tabela}      
  </tbody>                                                                      
  </table></fieldset><br />` ;

    return message;
  }*/


}
