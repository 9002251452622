<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>


<div class="row">
  <div class="col-sm-12">
    <div class="drag-list__wrap center">
      <div class="">
        <div class="col-sm-12 col-xl-12">
          <!-- Default select start -->
          <app-card cardTitle="Cadastro de Questão" [options]="false">
            <div class="row">
              <div class="col-sm-12 col-xl-6 m-b-30">
                <label class="sub-title">Escolha o Instrumento/Escala</label>
                <ng-select [ngClass]="'tag-select'" [options]="arrayGrupoQuestoes" [(ngModel)]="grupoQuestaoSelecionado"
                  [multiple]="true"> </ng-select>
              </div>
              <div class="col-sm-12 col-xl-6">
                <label class="sub-title">Entre com as palavras chave <code>(Tags)</code> para facilitar sua
                  busca.</label>
                <tag-input [ngClass]="'tag-select'" theme='bootstrap' [placeholder]="'+'"
                  [(ngModel)]="arrayPalavrasChave" [addOnBlur]="true" [clearOnBlur]="true">
                  <tag-input-dropdown></tag-input-dropdown>
                </tag-input>
                <br>
              </div>

            </div>
            <div class="row">
              <div class="col-sm-12 col-xl-6 m-b-30">
                <label class="sub-title">Estilo da questão</label>
                <select [(ngModel)]="tipoCampoSelecionado" name="tipoCampoSelecionado" (change)="limpaVisualizacao()"
                  class="form-control form-control-default">
                  <option *ngFor="let item of arrayTipos" value={{item.id}}>
                    {{item.descricao}}
                </select>
              </div>
              <div class="col-sm-12 col-xl-4 "
                *ngIf="tipoCampoSelecionado == '9' || tipoCampoSelecionado == '10' || tipoCampoSelecionado == '12'">
                <label class="sub-title">É uma questão Eliminatória ou Classificatória? </label>
                <select [(ngModel)]="eliminatoriaClassificatoria" name="eliminatoriaClassificatoria"
                  class="form-control form-control-default">
                  <option value="N">Não se Aplica</option>
                  <option value="E">Eliminatória</option>
                  <option value="C">Classificatória</option>
                </select>
              </div>
              <div class="col-sm-12 col-xl-9">
                <label class="sub-title">Digite sua Pergunta </label>

                <input type="text" class="form-control" [(ngModel)]="campoLabel" name="campoLabel" placeholder="">
              </div>
              <div class="col-2"
                *ngIf="tipoCampoSelecionado != '9' && tipoCampoSelecionado != '10' && tipoCampoSelecionado != '11' && tipoCampoSelecionado != '12' ">
                <br>
                <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'C'">
                  <input type="text" class="form-control form-control" [(ngModel)]="inputPontos" name="inputPontos"
                    placeholder="ponto">
                </div>
                <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'E'">
                  <select class="form-control form-control-default" [(ngModel)]="inputPontos">
                    <option value="E">Elimina</option>
                    <option value="C">Classifica</option>
                  </select>
                </div>
              </div>

              <div class="col-sm-12 col-xl-12 m-b-30">
                <label class="sub-title">Digite uma Descrição </label>
                <textarea rows="3" class="form-control" [(ngModel)]="descricaoLabel" name="descricaoLabel"></textarea>

              </div>
              <div class="col-sm-12 col-xl-8" *ngIf="tipoCampoSelecionado == '8'">
                <label class="sub-title">Digite a opção e aperte a tecla <code>ENTER</code></label>
                <tag-input [ngClass]="'tag-select'" theme='bootstrap' [placeholder]="'+'" [(ngModel)]="arraySelectOpcao"
                  [addOnBlur]="true" [clearOnBlur]="true">
                  <tag-input-dropdown></tag-input-dropdown>
                </tag-input>
                <br>
              </div>

              <div class="col-sm-12 col-xl-12" *ngIf="tipoCampoSelecionado == '9'">

                <div class="container" dragula="VAMPIRES" [(dragulaModel)]="arraySelectOpcao">
                  <div class="form-group " *ngFor="let item of arraySelectOpcao; let i = index">
                    <div class="row box">
                      <div class="col-2"></div>
                      <div class="col-1">
                        <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormal"><i
                            class="fa fa-arrows-alt" ngbTooltip="Mover"></i>
                        </a>

                      </div>
                      <div class="col-4">

                        <div class="checkbox">
                          {{i}} - <input type="checkbox" name="{{item.chave}}" id="{{item.chave}}">
                          <label for="{{item.chave}}" class="cr">{{item.chave}}</label>
                        </div>
                      </div>
                      <div class="col-2">

                        <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'C'">
                          <input type="text" class="form-control form-control" [(ngModel)]="item.ponto"
                            name="item.ponto" placeholder="ponto">
                        </div>
                        <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'E'">
                          <select class="form-control form-control-default" [(ngModel)]="item.ponto">
                            <option value="E">Elimina</option>
                            <option value="C">Classifica</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="checkbox"
                          ngbTooltip="Neste campo irá habilitar um campo texto quando usuario selecionar esta opção">
                          <select class="form-control form-control-default" [(ngModel)]="item.habilitaCampo"
                            (change)="verificaSeEcondiciona(arraySelectOpcao)">
                            <option value="N">NÃO CONDICIONAL </option>
                            <option value="S">CONDICIONAL</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-1">
                        <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                          (click)="removeOpcao(i)"><i class="fa fa-trash" ngbTooltip="Remover da Lista"></i>
                        </a>
                      </div>

                      <!--<div class="col-6" *ngIf="item.habilitaCampo == 'S'"></div>
                      <div class="col-6" *ngIf="item.habilitaCampo == 'S'">
                        <input type="text" class="form-control form-control" [(ngModel)]="item.textoCampoCondicional"
                          name="item.textoCampoCondicional"
                          placeholder="Entre com o texto de instrução para preenchimento">
                      </div>-->

                      <br><br>
                    </div>


                  </div>
                </div>

                <div class="row">
                  <div class="col-12" *ngIf="habilitaCondicional">
                    <label>Entre com o texto de instrução para preenchimento</label>
                    <input type="text" class="form-control form-control" [(ngModel)]="inputPerguntaCondicional"
                      name="inputPerguntaCondicional" placeholder="Entre com o texto de instrução para preenchimento">
                  </div>
                </div>
                <br>
                <label class="sub-title">Digite sua Opção e clique no Botão +</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button class="btn btn-primary" type="button" (click)="addOpcao()"><i
                        class="fa fa-plus"></i></button>
                  </div>
                  <input type="text" class="form-control" placeholder="Digite sua opção e clique o botão +"
                    aria-describedby="basic-addon1" [(ngModel)]="inputOpcoes" name="inputOpcoes">
                </div>
              </div>

              <div class="col-sm-12 col-xl-12" *ngIf="tipoCampoSelecionado == '10'">

                <div class="container" dragula="VAMPIRES" [(dragulaModel)]="arraySelectOpcao">


                  <div class="form-group " *ngFor="let item of arraySelectOpcao; let i = index">
                    <div class="row box">
                      <div class="col-2"></div>
                      <div class="col-1">
                        <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormal"><i
                            class="fa fa-arrows-alt" ngbTooltip="Mover"></i>
                        </a>

                      </div>
                      <div class="col-4">
                        <div class="form-check">
                          {{i}} - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input class="form-check-input" type="radio" name="gridRadios" id="{{item.chave}}"
                            value="{{item.chave}}">
                          <label class="form-check-label" for="gridRadios1">{{item.chave}}</label>
                        </div>
                      </div>
                      <div class="col-2">

                        <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'C'">
                          <input type="text" class="form-control form-control" [(ngModel)]="item.ponto"
                            name="item.ponto" placeholder="ponto">
                        </div>
                        <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'E'">
                          <select class="form-control form-control-default" [(ngModel)]="item.ponto">
                            <option value="E">Elimina</option>
                            <option value="C">Classifica</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="checkbox"
                          ngbTooltip="Neste campo irá habilitar um campo texto quando usuario selecionar esta opção">
                          <select class="form-control form-control-default" [(ngModel)]="item.habilitaCampo"
                            (change)="verificaSeEcondiciona(arraySelectOpcao)">
                            <option value="N">NÃO CONDICIONAL </option>
                            <option value="S">CONDICIONAL</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-1">
                        <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                          (click)="removeOpcao(i)"><i class="fa fa-trash" ngbTooltip="Remover da Lista"></i>
                        </a>
                      </div>
                      <!--<div class="col-6" *ngIf="item.habilitaCampo == 'S'"></div>
                      <div class="col-6" *ngIf="item.habilitaCampo == 'S'">
                        <input type="text" class="form-control form-control" [(ngModel)]="item.textoCampoCondicional"
                          name="item.textoCampoCondicional"
                          placeholder="Entre com o texto de instrução para preenchimento">
                      </div>-->

                      <br><br>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12" *ngIf="habilitaCondicional">
                    <label>Entre com o texto de instrução para preenchimento</label>
                    <input type="text" class="form-control form-control" [(ngModel)]="inputPerguntaCondicional"
                      name="inputPerguntaCondicional" placeholder="Entre com o texto de instrução para preenchimento">
                  </div>
                </div>
                <br>

                <label class="sub-title">Digite sua Opção e clique no Botão +</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button class="btn btn-primary" type="button" (click)="addOpcao()"><i
                        class="fa fa-plus"></i></button>
                  </div>
                  <input type="text" class="form-control" placeholder="Digite sua opção e clique o botão +"
                    aria-describedby="basic-addon1" [(ngModel)]="inputOpcoes" name="inputOpcoes">
                </div>
              </div>

              <div class="col-sm-12 col-xl-12" *ngIf="tipoCampoSelecionado == '11'">

                <div class="container" dragula="VAMPIRES" [(dragulaModel)]="arraySelectOpcao">



                  <div class="row">
                    <div class="custom-control custom-radio custom-control-inline"
                      *ngFor="let item of arraySelectOpcao; let i = index">
                      <input class="form-check-input" type="radio" name="gridRadios" id="{{item.chave}}"
                        value="{{item.chave}}">
                      <label class="form-check-label" for="gridRadios1">{{item.chave}}</label>
                    </div>
                  </div>
                  <br>


                </div>
                <div class="row">

                  <div class="col-sm-12 col-xl-2">
                    <label class="sub-title">Inicio</label>
                    <input type="number" id="inicioEscala" min=0 class="form-control" [(ngModel)]="inicioEscala"
                      name="inicioEscala" placeholder="">
                  </div>
                  <div class="col-sm-12 col-xl-2">
                    <label class="sub-title">Fim</label>
                    <input type="number" class="form-control" [(ngModel)]="fimEscala" name="fimEscala" placeholder="">
                  </div>
                  <div class="col-sm-12 col-xl-2">
                    <br>
                    <button class="btn btn-primary" type="button" (click)="montarEscala()" style="margin-top: 6px"><i
                        class="fa fa-plus"></i> Montar Escala</button>
                  </div>
                </div>
                <br><br>

              </div>


              <div class="col-sm-12 col-xl-12" *ngIf="tipoCampoSelecionado == '12'">

                <div class="container" dragula="VAMPIRES" [(dragulaModel)]="arraySelectOpcao">


                  <div class="form-group " *ngFor="let item of arraySelectOpcao; let i = index">
                    <div class="row box">
                      <div class="col-2"></div>
                      <div class="col-1">
                        <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormal"><i
                            class="fa fa-arrows-alt" ngbTooltip="Mover"></i>
                        </a>

                      </div>
                      <div class="col-5">
                        <div class="form-check">
                          {{i}} - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input class="form-check-input" type="radio" name="gridRadios" id="{{item.chave}}"
                            value="{{item.chave}}">
                          <label class="form-check-label" for="gridRadios1">{{item.chave}}</label>
                        </div>
                      </div>
                      <div class="col-2">

                        <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'C'">
                          <input type="text" class="form-control form-control" [(ngModel)]="item.ponto"
                            name="item.ponto" placeholder="ponto">
                        </div>
                        <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'E'">
                          <select class="form-control form-control-default" [(ngModel)]="item.ponto">
                            <option value="E">Elimina</option>
                            <option value="C">Classifica</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-1">
                        <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                          (click)="removeOpcao(i)"><i class="fa fa-trash" ngbTooltip="Remover da Lista"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6"></div>
                  <div class="col-md-6" align="center">
                    <div class="custom-control custom-radio custom-control-inline"
                      *ngFor="let item of arrayColunaMultiplaEscolha; let i = index">

                      <input class="form-check-input" type="radio" name="gridRadios" id="{{item.chave}}"
                        value="{{item.chave}}">

                      <label class="form-check-label" for="gridRadios1">{{item.chave}} </label>&nbsp;&nbsp;
                      <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                        (click)="removeOpcaoColuna(i)" style="font-size: 15px; margin: 0px; padding:0px;">
                        <i class="fa fa-trash" ngbTooltip="Remover coluna {{item.chave}}"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label class="sub-title">Adicionar Linha</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <button class="btn btn-primary" type="button" (click)="addLinha()"><i
                            class="fa fa-plus"></i></button>
                      </div>
                      <input type="text" class="form-control" placeholder="Digite sua opção e clique o botão +"
                        aria-describedby="basic-addon1" [(ngModel)]="inputLinhas" name="inputLinhas">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label class="sub-title">Adicionar Coluna</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <button class="btn btn-primary" type="button" (click)="addColuna()"><i
                            class="fa fa-plus"></i></button>
                      </div>
                      <input type="text" class="form-control" placeholder="Digite sua opção e clique o botão +"
                        aria-describedby="basic-addon1" [(ngModel)]="inputColunas" name="inputColunas">
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label class="sub-title">&nbsp;&nbsp;</label>
                    <div *ngIf="eliminatoriaClassificatoria == 'C'">
                      <input type="text" class="form-control form-control" [(ngModel)]="inputPontos" name="inputPontos"
                        placeholder="ponto">
                    </div>
                    <div *ngIf="eliminatoriaClassificatoria == 'E'">
                      <select class="form-control form-control-default">
                        <option value="E">Elimina</option>
                        <option value="C">Classifica</option>
                      </select>
                    </div>

                  </div>

                </div>


                <div class="col-md-12">
                  <br>
                  <div class="form-group " *ngFor="let item of arrayLinhasMultiplaEscolha; let i = index">
                    <div class="row box">
                      <div class="col-1">
                        <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                          (click)="removeOpcaoLinhas(i)"><i class="fa fa-trash" ngbTooltip="Remover da Lista"></i>
                        </a>
                      </div>
                      <div class="col-1">
                        <!--<a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormal"><i
                            class="fa fa-arrows-alt" ngbTooltip="Mover"></i>
                        </a>-->

                      </div>
                      <div class="col-4">
                        <div class="form-check">
                          {{i}} - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <label class="form-check-label" for="gridRadios1">{{item}}</label>
                        </div>
                      </div>
                      <div class="col-6" align="center">
                        <div class="custom-control custom-radio custom-control-inline"
                          *ngFor="let item of arrayColunaMultiplaEscolha; let i = index">
                          <input class="form-check-input" type="radio" name="gridRadios" id="{{item.chave}}"
                            value="{{item.chave}}">
                          <label class="form-check-label" for="gridRadios1">{{item.chave}}</label>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


              </div>

            </div>

            <div class="row" align="center">
              <div class="col-sm-12 col-xl-12 m-b-30">
                <br>
                <button type="button" class="btn btn-icon btn-info " (click)="addItem()">
                  <i class="fa fa-eye"></i> MONTAR PERGUNTA
                </button>
              </div>
            </div>
          </app-card>
        </div>
        <div class="row" *ngIf="arrayFormulario.length > 0">
          <div class="col-md-2"></div>
          <div class="col-sm-12 col-md-8">
            <app-card cardTitle="Visualizar Questão" [options]="false">
              <div *ngFor="let x of arrayFormulario; let index = index;">


                <div class="row">
                  <div class="col-md-12 ">
                    <h5 class="sub-title" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO != '9'">{{x.titulo}}</h5>
                    <label class="sub-title" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO != '9'">{{x.descricao}}</label>

                    <div class="custom-control custom-checkbox" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '9' ">
                      <!-- Check -->
                      <label class="sub-title">{{x.titulo}}</label>

                      <div class="">

                        <div class="form-group " *ngFor="let item of x.valores; let i = index">
                          <div class="row ">

                            <div class="col-1">

                            </div>
                            <div class="col-5">

                              <div class="checkbox">
                                {{i}} - <input type="checkbox" name="{{item.chave}}" id="{{item.chave}}">
                                <label for="{{item.chave}}" class="cr">{{item.chave}}</label>
                              </div>
                            </div>
                            <div class="col-2">
                              <div *ngIf="item.habilitaCampo == 'S'">
                                <a class="badge m-r-5 badge-success "> Condicional</a>
                              </div>

                            </div>
                            <div class="col-4">
                              <div *ngIf="item.habilitaCampo == 'S'">
                                {{item.textoCampoCondicional}}
                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <fieldset class="form-group" *ngIf=" x.iD_TIPO_CAMPO_FORMULARIO == '10'">
                      <div class=" col-md-12">
                        <!--<label for="inputPassword3" class="col-sm-3 col-form-label">Radios</label>-->

                        <div class="form-group " *ngFor="let item of x.valores; let i = index">
                          <div class="row">
                            <div class="col-2"></div>

                            <div class="col-4">
                              <div class="form-check">
                                {{i}} - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input class="form-check-input" type="radio" name="gridRadios" id="{{item.chave}}"
                                  value="{{item.chave}}">
                                <label class="form-check-label" for="gridRadios1">{{item.chave}}</label>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div *ngIf="item.habilitaCampo == 'S'">
                                <a class="badge m-r-5 badge-success "> Condicional</a>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div *ngIf="item.habilitaCampo == 'S'">
                                {{item.textoCampoCondicional}}
                              </div>
                            </div>


                          </div>
                        </div>

                        <!--</div>-->
                      </div>
                    </fieldset>

                    <fieldset class="form-group" *ngIf=" x.iD_TIPO_CAMPO_FORMULARIO == '12'">
                      <div class="row">

                        <!--<label for="inputPassword3" class="col-sm-3 col-form-label">Radios</label>-->
                        <div class="col-md-12">

                          <table align="center">
                            <!--<tr>
                                    <th colspan="2">Name</th>
                                    <th>Age</th>
                                  </tr>-->
                            <tr class="text-center">
                              <th class="col-md-8"></th>
                              <th class="col-md-3" *ngFor="let item of x.valoresDetalhes;">{{item.chave}}</th>
                            </tr>
                            <tr *ngFor="let item of x.valores; let i = index" class="text-center">
                              <td class="col-md-8 text-left">{{item.chave}}</td>
                              <td class="col-md-3" *ngFor="let it of x.valoresDetalhes;">
                                <input class="form-check-input" type="radio" name="gridRadios" id="{{it.chave}}-{{i}}"
                                  value="{{it.chave}}-{{i}}">
                                <label class="form-check-label" for="gridRadios{{i}}"></label>
                              </td>

                            </tr>

                          </table>


                        </div>
                      </div>
                    </fieldset>


                    <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '3'"
                      [textMask]="{mask: maskCPF}">
                    <!-- CPF -->
                    <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '5'">
                    <!-- E-mail -->
                    <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '7'"
                      [textMask]="{mask: numberMaskInt}">
                    <!-- Numérico (não aceita ponto ou vírgula) -->
                    <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '4'">
                    <!-- RG -->

                    <select class="form-control form-control-default" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '8'">
                      <!-- Seleção de Dados -->
                      <option *ngFor="let i of x.valores">{{i.chave}}
                    </select>

                    <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '2'"
                      [textMask]="{mask: maskTelefoneCelular}"> <!-- Telefone -->
                    <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '1'">
                    <!-- Texto Livre -->
                    <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '6'"
                      [textMask]="{mask: numberMaskReal}">
                    <!-- Valor Monetário (aceita ponto ou vírgula) -->

                  </div>
                </div>

              </div>
              <div class="row" align="center" id="idSalvarPergunta">
                <div class="col-sm-12 col-xl-12 m-b-30">
                  <br>
                  <button type="button" class="btn btn-icon btn-success " (click)="SalvarPergunta()">
                    <i class="fa fa-plus"></i> SALVAR PERGUNTA
                  </button>
                </div>
              </div>
            </app-card>
          </div>
        </div>

      </div>
    </div>
  </div>


</div>
