<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class="row">
  <div class="col-12">
    <app-card cardTitle="Cadastro Instrumento/Escala" [options]="false">
      <div class="row">
        <div class="col-sm-12 col-xl-6 ">
          <label class="sub-title">Nome do Instrumento/Escala</label>
          <input type="text" class="form-control" [(ngModel)]="nome" name="nome" placeholder="">
        </div>


      </div>
      <div class="row">
        <div class="col-sm-12 col-xl-12">
          <label class="sub-title">Instruções</label>
          <app-tinymce [(ngModel)]='descricao'></app-tinymce>
        </div>
      </div>
      <div class="row text-right">
        <div class="col-md-12">
          <br>
          <button *ngIf="escalaSelecionada == null" type="button" class="btn btn-success " (click)="salvar()">SALVAR
            &nbsp;<i class="feather icon-check-circle"></i>
          </button>

          <button *ngIf="escalaSelecionada != null" type="button" class="btn btn-warning " (click)="voltar()">
            <i class="fa fa-arrow-left"></i>
            &nbsp;VOLTAR
          </button>
          &nbsp;&nbsp;&nbsp;
          <button *ngIf="escalaSelecionada != null" type="button" class="btn btn-info " (click)="salvarEdicao()">SALVAR EDICÃO &nbsp;<i
              class="feather icon-check-circle"></i>
          </button>


        </div>
      </div>


    </app-card>
  </div>
  <div class="col-12">
    <app-card cardTitle="Instrumentos/Escalas Cadastrados" blockClass="table-border-style" [options]="false">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th style="width: 25%">Descricão</th>
              <th style="width: 60%">Instrução</th>
              <th class="text-center" >Acão</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of arrayGrupos" style="width: 100%">
              <td style="width: 25%">{{item.descricao }}</td>
              <td style="width: 60%"><span [outerHTML]="item.instrucao "></span></td>
              <td class="text-center">
                <button type="button" class="btn btn-icon btn-info " (click)="carregarEdicao(item)">
                  <i class="fa fa-edit"></i>
                </button>
                &nbsp;&nbsp;
                <button type="button" class="btn btn-icon btn-danger " (click)="desabilitar(item.id)"
                  ngbTooltip="Desabilitar Escala">
                  <i class="fa fa-trash"></i>
                </button>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </app-card>
  </div>
</div>
