import { Component, OnInit } from "@angular/core";
import {
  child,
  get,
  getDatabase,
  onValue,
  orderByChild,
  limitToFirst,
  ref,
  set,
  update,
} from "firebase/database";
import { AuthService } from "src/app/service/auth.service";
import * as moment from "moment";
import { UtilService } from "src/app/service/util.service";
import { query } from "@angular/animations";

@Component({
  selector: "app-suporte",
  templateUrl: "./suporte.component.html",
  styleUrls: ["./suporte.component.scss"],
})
export class SuporteComponent implements OnInit {
  public message: string;
  public usuario: any;
  public itens: any[];

  public chat: any;
  public pessoaSelecionada: any;

  constructor(public auth: AuthService, public util: UtilService) {
    this.itens = [];
    this.buscarTeste();
  }

  ngOnInit() {}

  sendMessage() {
    const db = getDatabase();
    let myMoment: moment.Moment = moment(new Date());

    const dbRef = ref(getDatabase());

    get(child(dbRef, `chat/${this.pessoaSelecionada.cpf}`))
      .then((snapshot) => {
        let sequencial = 0;
        if (snapshot.val() != null) {
          sequencial = snapshot.val().conversa.length;
        }
        debugger;
        update(ref(db, `chat/${this.pessoaSelecionada.cpf}`), {
          nome: snapshot.val().nome,
          cpf: snapshot.val().cpf,
          status: "F",
        });

        update(
          ref(db, `chat/${this.pessoaSelecionada.cpf}/conversa/${sequencial}`),
          {
            user: "adm",
            texto: this.message,
            timestamp: myMoment.unix(),
            data: myMoment.format(),
          }
        )
          .then((response) => {
            this.message = "";
          })
          .catch((error) => {});
      })
      .catch((error) => {
        console.error(error);
      });
  }

  lerConversa(item) {
    this.util.showLoading();
    this.pessoaSelecionada = item;
    const db = getDatabase();
    //.orderByChild('status')
    const reference = ref(db, `chat/${item.cpf}/conversa`);
    onValue(
      reference,
      (snapshot) => {
        const data = snapshot.val();
        this.chat = data;
        //console.log("chat", this.chat);
        this.util.closeLoading();

        setTimeout(function () {
          document.querySelector("#lastItem").scrollIntoView({
            behavior: "smooth",
          });
        }, 500);


      },
      (erro) => {
        console.log(erro);
      }
    );
  }

  sucesso(erro) {
    console.log(erro);
  }

  buscarTeste() {
    const db = getDatabase();

    const reference = ref(db, `chat/`);
    onValue(reference, (snapshot) => {
      const data = snapshot.val();
      this.auth.temSuporte = false;
      var temSuporte = false;
      var myItens = [];

      snapshot.forEach(function (childNodes) {
        //console.log(childNodes.val().nome);
        myItens.push({
          nome: childNodes.val().nome,
          cpf: childNodes.val().cpf,
          status: childNodes.val().status,
        });
     
        if (childNodes.val().status == "A") temSuporte = true;        

      });

      this.itens = myItens;
      this.auth.temSuporte = temSuporte;
      debugger
        setTimeout(function () {
          document.querySelector("#lastItem").scrollIntoView({
            behavior: "smooth",
          });
        }, 500);
      
    });
  }



  buscar() {
    const db = getDatabase();
    get(child(ref(db), `chat`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          //console.log(snapshot.val());
          this.itens = Object.values(snapshot.val());
          console.log(this.itens);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
