import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DadosTemplateEntrada } from 'src/app/model/DadosTemplateEntrada';
import { Pergunta } from 'src/app/model/Pergunta';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, TIPOS_ENTRADA } from 'src/environments/environment';

@Component({
  selector: 'app-editar-triagem',
  templateUrl: './editar-triagem.component.html',
  styleUrls: ['./editar-triagem.component.scss']
})
export class EditarTriagemComponent implements OnInit {

  query: string;
  idTriagem: string;
  arrayPerguntas: any[];
  arrayTodasPerguntas: any[];
  perguntaAdd: any;
  selectedItem: any;
  triagemCabecalho: any;

  tipo = TIPOS_ENTRADA;

  arrayProgramas: any[];
  arrayTurmas: any[];
  programaSelecionado: any;
  turmaSelecionada: any;
  habilitarRevisado: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) {
    this.idTriagem = this.activatedRoute.snapshot.params['id'];
    this.inicializar();
    this.buscarTriagem();
    this.buscarPerguntas();
    this.buscarTodasPerguntas();
    this.habilitarRevisado = true;

  }

  ngOnInit() {

  }

  inicializar() {
    this.triagemCabecalho = {
      banner: null,
      datA_FIM: "",
      datA_INICIO: "",
      iD_PROGRAMA: 0,
      iD_TRIAGEM: 0,
      pontuacao: 0,
      programa: "",
      revisado: false,
      titulo: "",
      instrucao: ""
    }
  }

  buscarTodasPerguntas() {
    let url = API + "Pergunta/Pergunta?IS_RESUMIR_AGRUPAMENTOS=true";
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayTodasPerguntas = response.data;
      }, err => {
        debugger
        this.util.toasterShowError("Atenção", "Erro ao Buscar Perguntas");
      });
  }

  salvarCabecalho() {
    this.atualizarTriagem();
  }

  atualizarTriagem() {
    if (this.validarTriagemCabecalho()) {
      let dadosQrcode = {
        "ID_TRIAGEM": this.triagemCabecalho.iD_TRIAGEM,
        "ID_PROGRAMA": this.programaSelecionado,
        "ID_TURMA": this.turmaSelecionada,
        "TITULO": this.triagemCabecalho.titulo,
        "DATA_INICIO": this.util.formatDateSQL(this.triagemCabecalho.datA_INICIO),
        "DATA_FIM": this.util.formatDateSQL(this.triagemCabecalho.datA_FIM),
        "STATUS": true,
        "PONTUACAO": this.triagemCabecalho.pontuacao,
        "REVISADO": this.triagemCabecalho.revisado,
        "INSTRUCAO": this.triagemCabecalho.instrucao
      }

      if (this.triagemCabecalho.banner != undefined || this.triagemCabecalho.banner != null)
        dadosQrcode['Arquivo'] = this.triagemCabecalho.banner[0];

      debugger
      let url = API + "Triagem/Triagem/T";
      let dados = this.util.jsonToFormData(dadosQrcode);

      this.util.showLoading();
      this.ajax.postFile(url, dados)
        .subscribe((resp: ResponseNet) => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowSuccess("Atenção", "Cabeçalho atualizado com sucesso!");
          this.habilitarRevisado = true;

        }, err => {
          debugger
          this.util.closeLoading();
          this.util.messageError(err);
        });
    }

  }

  validarTriagemCabecalho() {

    if (this.programaSelecionado == undefined || this.programaSelecionado == null) {
      this.util.toasterShowError("Atenção", "Escolha um Programa");
      return false;
    }

    if (this.turmaSelecionada == undefined || this.turmaSelecionada == null) {
      this.util.toasterShowError("Atenção", "Escolha uma Turma");
      return false;
    }

    if (this.triagemCabecalho.titulo == undefined || this.triagemCabecalho.titulo == "") {
      this.util.toasterShowError("Atenção", "Entre com o Nome da Triagem");
      return false;
    }

    if (this.triagemCabecalho.datA_INICIO == undefined || this.util.removeMask(this.triagemCabecalho.datA_INICIO) == "" || this.util.removeMask(this.triagemCabecalho.datA_INICIO).length < 8) {
      this.util.toasterShowError("Atenção", "O Campo Data Inicio não pode ser Vazio ou estar incompleto!");
      return false;
    }

    if (this.triagemCabecalho.datA_FIM == undefined || this.util.removeMask(this.triagemCabecalho.datA_FIM) == "" || this.util.removeMask(this.triagemCabecalho.datA_FIM).length < 8) {
      this.util.toasterShowError("Atenção", "O Campo Data Fim não pode ser Vazio ou estar incompleto!");
      return false;
    }

    if (this.triagemCabecalho.pontuacao == undefined || this.triagemCabecalho.pontuacao == 0) {
      this.util.toasterShowError("Atenção", "Entre com pontuacao da triagem");
      return false;
    }

    return true;
  }

  buscarProgramas() {

    let url = API + "Programa/Programa";
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayProgramas = this.util.convertIoptions(response.data, "iD_PROGRAMA", "descricao");
        this.programaSelecionado = this.triagemCabecalho.iD_PROGRAMA;
      }, err => {
        this.util.toasterShowError("Atenção", "Erro ao Buscar Programa");
      });
  }


  buscarTurmas() {
    let url = API + "Turma/Turma";
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayTurmas = this.util.convertIoptions(response.data, "iD_TURMA", "descricao");
        this.turmaSelecionada = this.triagemCabecalho.iD_TURMA;
        console.log(this.arrayTurmas);
      }, err => {
        this.util.toasterShowError("Atenção", "Erro ao Buscar Turma");
      });
  }

  moveUp() {
    let index = this.arrayPerguntas.indexOf(this.selectedItem);
    this.arrayPerguntas[index].ordem = this.arrayPerguntas[index].ordem - 1;
    this.arrayPerguntas[index - 1].ordem = this.arrayPerguntas[index].ordem + 1;
    this.sortItemsByPostion();
  }

  moveDown() {
    let index = this.arrayPerguntas.indexOf(this.selectedItem);
    this.arrayPerguntas[index].ordem = this.arrayPerguntas[index].ordem + 1;
    this.arrayPerguntas[index + 1].ordem = this.arrayPerguntas[index].ordem - 1;
    this.sortItemsByPostion();

  }

  sortItemsByPostion() {
    this.arrayPerguntas = this.arrayPerguntas.sort((opt1, opt2) => {
      if (opt1.ordem < opt2.ordem) {
        return -1;
      }
      if (opt1.ordem > opt2.ordem) {
        return 1;
      }
      return 0
    });
  }

  deleteItem(index) {
    let item = this.arrayPerguntas[index];
    this.arrayPerguntas.splice(index, 1);
    this.removeIconeCheck(item);
    this.atualizarpagina();
  }

  removeIconeCheck(item) {
    console.log(item.iD_PERGUNTA);
    let index = this.arrayPerguntas.findIndex(c => c['iD_PERGUNTA'] == item.iD_PERGUNTA);
    this.arrayPerguntas[index].selecionado = false;
  }

  adicionarpagina() {
    this.selectedItem.eH_CABECALHO = true;
    this.atualizarpagina();

  }

  atualizarpagina() {
    let pagina = 0;
    debugger
    this.arrayPerguntas.forEach(item => {
      if (item.eH_CABECALHO) {
        pagina++;
        //item.pagina = `${pagina}/${numpagina}`;        
      }
      item.pagina = pagina;
    });
  }

  buscaQtdSeccsao() {
    let numpagina = 0;
    debugger
    this.arrayPerguntas.forEach(item => {
      if (item.eH_CABECALHO)
        numpagina++
    });
    return numpagina;
  }

  onItemSelect(item) {
    if (item.mode != 'empty') {
      this.selectedItem = item;
    }
  }

  removerpagina() {
    debugger
    if (this.buscaQtdSeccsao() > 1) {
      this.selectedItem.eH_CABECALHO = false;
      this.atualizarpagina();
    } else {
      this.util.toasterShowInfo("Atenção", "Não é permitido remover esta Secção");
    }

  }

  buscarPerguntas() {

    this.util.showLoading();
    let url = API + "CampoFormularioTriagem/CampoFormularioTriagem/EditarTriagem/" + this.idTriagem;
    return this.ajax.get(url)
      .subscribe((resp: any) => {

        this.arrayPerguntas = resp.data;
        console.log('array perguntas', this.arrayPerguntas)


        //alert('finalizou');
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Detalhes");
      });
  }

  buscarTriagem() {

    this.util.showLoading();
    let url = API + "Triagem/Triagem?ID_TRIAGEM=" + this.idTriagem;
    return this.ajax.get(url)
      .subscribe((resp: any) => {

        this.triagemCabecalho = resp.data[0];
        this.triagemCabecalho.datA_INICIO = this.util.formatDateSQL(this.triagemCabecalho.datA_INICIO);
        this.triagemCabecalho.datA_FIM = this.util.formatDateSQL(this.triagemCabecalho.datA_FIM);

        console.log('Triagem Cabecalho', this.triagemCabecalho)
        this.habilitarRevisado = this.triagemCabecalho.revisado;
        this.buscarProgramas();
        this.buscarTurmas();

        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Detalhes");
      });
  }

  removerPergunta(index) {
    debugger
    this.util.showLoading();
    let item = this.arrayPerguntas[index];
    if (item.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Check) {

      let detalhesValores = item.detalhesValores.map(it => { return { iD_PERGUNTA: it.iD_PERGUNTA, iD_TRIAGEM: this.triagemCabecalho.iD_TRIAGEM } });
      let url = API + "CampoFormularioTriagem/CampoFormularioTriagem/DeleteVarias";
      return this.ajax.post(url, detalhesValores)
        .subscribe((resp: any) => {
          this.arrayPerguntas.splice(index, 1);
          this.util.closeLoading();
          this.buscarPerguntas();
        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao desvincular pergunta");
        });


    } else if (item.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Grade) {

      let detalhesValores = item.filhos.map(it => { return { iD_PERGUNTA: it.iD_PERGUNTA,  iD_PERGUNTA_PAI: it.iD_PERGUNTA_PAI,  iD_TRIAGEM: this.triagemCabecalho.iD_TRIAGEM } });
      let url = API + "CampoFormularioTriagem/CampoFormularioTriagem/DeleteVarias";
      return this.ajax.post(url, detalhesValores)
        .subscribe((resp: any) => {
          this.arrayPerguntas.splice(index, 1);
          this.util.closeLoading();
          this.buscarPerguntas();
        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao desvincular pergunta");
        });

    } else {
      let url = API + "CampoFormularioTriagem/CampoFormularioTriagem?id=" + item.iD_CAMPO_FORMULARIO_TRIAGEM;
      return this.ajax.delete(url)
        .subscribe((resp: any) => {
          this.arrayPerguntas.splice(index, 1);
          this.util.closeLoading();
          this.buscarPerguntas();
        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao desvincular pergunta");
        });
    }

  }


  salvarPerguntas() {
    let dados = this.montarCampoFormTriagem();
    console.log(dados);

    this.util.showLoading();
    let url = API + "CampoFormularioTriagem/CampoFormularioTriagem/EditarPerguntas/T";
    return this.ajax.post(url, dados)
      .subscribe((resp: any) => {
        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao desvincular pergunta");
      });

  }

  montarCampoFormTriagem() {
    let arrayCampoTriagem = [];
    this.arrayPerguntas.forEach(item => {

      let dados = {
        "iD_CAMPO_FORMULARIO_TRIAGEM": item.iD_CAMPO_FORMULARIO_TRIAGEM,
        "iD_TIPO_CAMPO_FORMULARIO": item.iD_TIPO_CAMPO_FORMULARIO,
        "iD_TRIAGEM": parseInt(this.idTriagem),
        "iD_PERGUNTA": item.iD_PERGUNTA,
        "ordem": item.ordem,
        "obrigatorio": item.obrigatorio,
        "pagina": item.pagina,
      };

      if (item.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Grade) {
        let filhos = [];
        item.filhos.forEach(it => {
          filhos.push({ iD_PERGUNTA: it.iD_PERGUNTA });
        });
        dados['filhos'] = filhos;
      }

      if (item.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Check) {
        let filhos = [];
        item.detalhesValores.forEach(it => {
          filhos.push({ iD_PERGUNTA: it.iD_PERGUNTA });
        });
        dados['filhos'] = filhos;
      }


      arrayCampoTriagem.push(dados);
    });
    return arrayCampoTriagem;
  }


  salvarCampoTriagem() {
    //let dados = this.util.montarCampoFormularioTriagem(this.perguntaAdd.idTriagem , this.arrayFormulario);
    //let data = this.util.removePerguntaCheck(dados);

    /*let url = API + "CampoFormularioTriagem/CampoFormularioTriagem";
    this.util.showLoading();
    return this.ajax.post(url, data)
      .subscribe((resp: ResponseNet) => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "Cadastrado com sucesso!");
        this.router.navigate(['/home']);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.messageError(err);
      });*/
  }

  transformaColuna(arrayItem: any[]): any[] {
    arrayItem.forEach(item => { item.valores = item.valores.split('|') });
    return arrayItem;
  }

  convertTemplateDadosEntrada(item, pagina): any {
    let dados = new DadosTemplateEntrada();
    dados.valores = [];
    dados.titulo = item.titulo;
    dados.descricao = item.descricao;
    dados.iD_PERGUNTA = item.iD_PERGUNTA;
    dados.iD_TIPO_CAMPO_FORMULARIO = item.iD_TIPO_CAMPO_FORMULARIO;
    dados.ordem = this.arrayPerguntas.length + 1;
    dados.subOrdem = dados.ordem.toString();
    dados.obrigatorio = true;
    dados.iD_PERGUNTA_PAI = item.iD_PERGUNTA_PAI;
    //dados.pagina = "1/1";
    dados.pagina = pagina;
    dados.paginaCabecalho = this.arrayPerguntas.length == 0 ? true : false;
    //let tipo = this.arrayTipos.find(c => c.id == this.tipoCampoSelecionado);
    let tipo = item.iD_TIPO_CAMPO_FORMULARIO;
    if (tipo == 8 || tipo == 10 || tipo == 9) {// selecao de dados
      dados.valores = item.valores.split('|');
      if (tipo == 9)
        dados.valoresDetalhes = item.detalhesValores;
    } else if (tipo == 12) {
      dados.valoresDetalhes = this.transformaColuna(item.filhos)
    }


    item.selecionado = true;
    return dados;
  }

  addItemTela() {
    console.log('addd itemmmm', this.perguntaAdd);
    if (this.perguntaAdd != undefined || this.perguntaAdd != null) {
      debugger
      let entrada = [];
      //entrada.push(this.perguntaAdd);

      let maxPage = this.util.MaiorValorItem(this.arrayPerguntas, 'pagina');
      let maxOrdem = this.util.MaiorValorItem(this.arrayPerguntas, 'ordem');
      let itemConvertido = this.convertTemplateDadosEntrada(this.perguntaAdd, maxPage + 1);
      entrada.push(itemConvertido);
      let dados = this.util.montarCampoFormularioTriagem(this.triagemCabecalho.iD_TRIAGEM, entrada, maxOrdem + 1);
      let data = this.util.removePerguntaCheck(dados);

      let url = API + "CampoFormularioTriagem/CampoFormularioTriagem/T";
      this.util.showLoading();
      return this.ajax.post(url, data)
        .subscribe((resp: ResponseNet) => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowSuccess("Sucesso", "Cadastrado com sucesso!");
          this.buscarPerguntas();

        }, err => {
          debugger
          this.util.closeLoading();
          this.util.messageError(err);
        });
      /*let item = this.arrayPerguntas.find(c => c.iD_PERGUNTA == this.perguntaAdd.iD_PERGUNTA);
      if(item == undefined){
        this.arrayPerguntas.push(this.perguntaAdd);
      }       
      else
        this.util.toasterShowWarning("Atenção", "Este Item ja está Adicionado a Triagem!");  */
    }
    else
      this.util.toasterShowWarning("Atenção", "Escolha um Item para Adicionar");
  }

  addItem(item) {
    debugger
    console.log(item);
    this.perguntaAdd = item;
    this.util.showLoading();
    let url = API + "Pergunta/Pergunta?ID_PERGUNTA=" + item.iD_PERGUNTA + "&IS_RESUMIR_AGRUPAMENTOS=true";
    return this.ajax.get(url)
      .subscribe(response => {

        this.perguntaAdd = response.data[0];
        this.addItemTela();
        this.util.closeLoading();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Pergunta");
      });
    /* if (this.verificaItemjaFoiAdicionado(item)) {
 
       let dados = new DadosTemplateEntrada();
       dados.valores = [];
       dados.titulo = item.titulo;
       dados.descricao = item.descricao;
       dados.iD_PERGUNTA = item.iD_PERGUNTA;
       dados.iD_TIPO_CAMPO_FORMULARIO = item.iD_TIPO_CAMPO_FORMULARIO;
       dados.ordem = this.arrayFormulario.length + 1;
       dados.subOrdem = dados.ordem.toString();
       dados.obrigatorio = true;
       dados.iD_PERGUNTA_PAI = item.iD_PERGUNTA_PAI;
       //dados.pagina = "1/1";
       dados.pagina = 1;
       dados.paginaCabecalho = this.arrayFormulario.length == 0 ? true : false;
       //let tipo = this.arrayTipos.find(c => c.id == this.tipoCampoSelecionado);
       let tipo = item.iD_TIPO_CAMPO_FORMULARIO;
       if (tipo == 8 || tipo == 10 || tipo == 9) {// selecao de dados
         dados.valores = this.ajustaValorSelecao(item.valores);
         if (tipo == 9)
           dados.valoresDetalhes = item.detalhesValores;
       } else if (tipo == 12) {
         dados.valoresDetalhes = this.transformaColuna(item.filhos)
       }
 
 
       item.selecionado = true;
       this.arrayFormulario.push(dados);
       console.log(this.arrayFormulario);*/
  }

}
