<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Aguarde ...</p>
  </ngx-spinner>
  
  <div class="row">
    <div class="col-12">
      <app-card  [options]="false" [hidHeader]="true">
        <div class="row">
          <div class="col-sm-12 col-xl-4 ">
            <label class="sub-title">Filtro</label>
            <input type="text" class="form-control" [(ngModel)]="filtro">
          </div>
        </div>
  
  
  
      </app-card>
    </div>
    <div class="col-12">
      <app-card cardTitle="Responsável Cadastrado" blockClass="table-border-style" [options]="false">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Idade</th>
                <th>Sexo</th>
                <th>Dta. Nasc.</th>
                <th>Grau TEA</th>  
                <th>Escola</th> 
                <th>Turno</th>    
                <th>Responsável Nome</th>    
                <th>Responsável CPF</th>   
                <th>Cartao SUS</th>  
                <th>Laudo</th>       
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of arrayParticipante | fullTextSearch:filtro">
                <td>{{item.nome }}</td>
                <td>{{item.idade }}</td>
                <td>{{item.sexo }}</td>
                <td>{{item.datA_NASCIMENTO | date: "dd/MM/yyyy"}}</td>
                <td>{{item.graU_TEA }}</td>
                <td>{{item.ondE_ESTUDOU }}</td>
                <td>{{item.turno }}</td>
                <td>{{item.responsaveL_NOME }}</td>
                <td>{{item.responsaveL_CPF }}</td>
                <td>{{item.cartaO_SUS }}</td>
                <td>
                  <button *ngIf="item.patH_LAUDO" type="button" class="btn btn-icon btn-warning " (click)="verArquivo(item)">
                    <i class="fa fa-eye"></i>
                  </button>
                </td>
                <!--<td>
                  <button type="button" class="btn btn-icon btn-info " (click)="this.util.notImplement()">
                      <i class="fa fa-edit"></i>
                    </button>
                  &nbsp;&nbsp;
                  <button type="button" class="btn btn-icon btn-danger " (click)="desabilitar(item.id)">
                    <i class="fa fa-trash"></i>
                  </button>
  
                </td>-->
              </tr>
            </tbody>
          </table>
        </div>
      </app-card>
    </div>
  </div>
  