<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class="row">
  <div class="col-12" *ngIf="!ehCadastro">


    <app-card cardTitle="Diários" blockClass="table-border-style" [options]="false">
      <div class="row">

        <button type="button" class="btn btn-primary" data-toggle="modal" (click)="inicioCadastro()">
          <i class="feather icon-plus-circle"></i>
          Cadastrar Novo Diário</button>

      </div>
      <br>
      <div class="row">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Título</th>
                <th>Descrição</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of arrayDiarios">
              <td class="col-9" >{{item.pergunta }}</td>
              <td class="col-1" >{{item.descricao }}</td>
              <td class="col-1" >
                <span *ngIf="item.tipo == 'P'" class="badge badge-success">Fase Prática</span>
                <span *ngIf="item.tipo == 'M'" class="badge badge-warning">Fase Módulos</span>
              </td>
              <td class="col-1">
                <button type="button" class="btn btn-icon btn-dark " (click)="openModal(modalCadastroDiario, item)">
                  <i class="fa fa-edit"></i>
                </button>
                <!--&nbsp;&nbsp;<button type="button" class="btn btn-icon btn-danger " >
                  <i class="fa fa-trash"></i>
                </button>-->
              </td>
            </tr>
          </tbody>
          </table>
        </div>
      </div>

    </app-card>
  </div>


  <div class="col-12" *ngIf="ehCadastro">
    <app-card cardTitle="Diário" blockClass="table-border-style" [options]="false">
      <app-modal-cadastro-diario (acaoVoltar)="voltar()" [item]="diarioItem">
      </app-modal-cadastro-diario>
    </app-card>
  </div>

</div>







<app-ui-modal #modalCadastroDiario [hideFooter]="true" [dialogClass]="'modal-xl'">
  <div class="app-modal-header">
    <h5 class="modal-title">Cadastro de Diário</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="closeModal(modalCadastroDiario, 'modulo')"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <app-modal-cadastro-diario (acaoVoltar)="closeModal(modalCadastroDiario, 'diario')" [item]="diarioItem">
    </app-modal-cadastro-diario>     
  </div>
</app-ui-modal>