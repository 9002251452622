<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

  <div class="row"> 
    
    <!--<div class="col-xl-3 col-md-6">
      <app-card [hidHeader]="true" cardClass="prod-p-card bg-c-red">
        <div class="row align-items-center m-b-0">
          <div class="col">
            <h6 class="m-b-5 text-white">Total Profit</h6>
            <h3 class="m-b-0 text-white">$1,783</h3>
          </div>
          <div class="col-auto">
            <i class="fas fa-money-bill-alt text-white"></i>
          </div>
        </div>
      </app-card>
    </div>-->
    <div class="col-xl-4 col-md-6">
      <app-card [hidHeader]="true" cardClass="prod-p-card bg-c-blue">
        <div class="row align-items-center m-b-0">
          <div class="col">
            <h6 class="m-b-5 text-white">Total Perguntas Cadastradas</h6>
            <h3 class="m-b-0 text-white">{{qtdPergunta}}</h3>
          </div>
          <div class="col-auto">
            <i class="fas fa-database text-white"></i>
          </div>
        </div>
      </app-card>
    </div>
    <div class="col-xl-4 col-md-6">
      <app-card [hidHeader]="true" cardClass="prod-p-card bg-c-green">
        <div class="row align-items-center m-b-0">
          <div class="col">
            <h6 class="m-b-5 text-white">Total Respostas Triagem Ativas</h6>
            <h3 class="m-b-0 text-white">{{qtdRespostas}}</h3>
          </div>
          <div class="col-auto">
            <i class="feather icon-file-text text-white"></i>
          </div>
        </div>
      </app-card>
    </div>
    <div class="col-xl-4 col-md-6">
      <app-card [hidHeader]="true" cardClass="prod-p-card bg-c-yellow">
        <div class="row align-items-center m-b-0">
          <div class="col">
            <h6 class="m-b-5 text-white">Total Programas Ativos</h6>
            <h3 class="m-b-0 text-white">{{qtdProgramas}}</h3>
          </div>
          <div class="col-auto">
            <i class="fas fa-tags text-white"></i>
          </div>
        </div>
      </app-card>
    </div>

   
  </div>

  <div class="row">
    <div class="col-xl-12 col-md-12">
      <app-card cardTitle="Programas Ativos"  blockClass="scrollable-body" [options]="false">
        <perfect-scrollbar [style.max-height]="'410px'" [style.padding-top]="0">
          
          
          <div class="row m-b-25 align-items-center" *ngFor="let item of arrayProgramas;  let i = index">
            <div class="col-auto p-r-0">
              <i class="feather icon-file-text badge-light-success feed-icon"></i>
            </div>
            <div class="col">
              <a href="javascript:">
                <h6 class="m-b-5"> {{item.descricao}}    
                  <span class="text-muted float-right f-14"><label class="badge badge-light-info">{{29+i}}</label></span>
                  <span class="text-muted float-right f-14">
                    &nbsp; &nbsp; &nbsp; &nbsp;  
                    &nbsp; &nbsp; &nbsp; &nbsp;
                  </span>                              
                  <span class="text-muted float-right f-14">{{item.datA_INICIO | date: "dd/MM/yyyy"}} ate {{item.datA_FIM | date: "dd/MM/yyyy"}}</span>
                  
                  
                </h6>
              </a>
            </div>
          </div>
          


        </perfect-scrollbar>
      </app-card>
    </div>
  </div>

  <div class="row">
    <div class="col-xl6 col-md-6 col-sm-12">
      <app-card cardTitle="Avisos Importantes"  blockClass="scrollable-body" [options]="false">
        <perfect-scrollbar [style.max-height]="'410px'" [style.padding-top]="0">
          
          <div class="row m-b-25 m-t-5 align-items-center" *ngFor="let item of arrayAvisos" (click)="modalAviso(item)">
            <div class="col-auto p-r-0">
              <i class="feather icon-bell badge-primary feed-icon"></i>
            </div>
            <div class="col">
              <a href="javascript:">
                <h6 class="m-b-5">{{item.titulo}} <span class="text-muted float-right f-14">dia {{item.datA_EVENTO | date: "dd/MM/yyyy"}}</span>
                </h6>
              </a>             
            
            </div>
          </div>

          <!--<div class="row m-b-25 m-t-5 align-items-center">
            <div class="col-auto p-r-0">
              <i class="feather icon-bell badge-warning feed-icon"></i>
            </div>
            <div class="col">             
              <a href="javascript:">
                <h6 class="m-b-5">Tutores ,reunião de alinhamento <span class="text-muted float-right f-14">dia 20/01/2021 21:00</span>
                </h6>
              </a>
            
            </div>
          </div>-->
          
          

        </perfect-scrollbar>
      </app-card>
    </div>
    
    <div class="col-xl-6 col-md-6 col-sm-12">
      <app-card cardTitle="cabecalho teste"  blockClass="scrollable-body" [options]="false">
        <perfect-scrollbar [style.max-height]="'410px'" [style.padding-top]="0">
          <div class="row m-b-25 m-t-5 align-items-center">
            <div class="col-auto p-r-0">
              <i class="feather icon-bell badge-light-primary feed-icon"></i>
            </div>
            <div class="col">
              <a href="javascript:">
                <h6 class="m-b-5">Teste01 <span class="text-muted float-right f-14">Just Now</span>
                </h6>
              </a>
            </div>
          </div>
          <div class="row m-b-25 align-items-center">
            <div class="col-auto p-r-0">
              <i class="feather icon-shopping-cart badge-light-danger feed-icon"></i>
            </div>
            <div class="col">
              <a href="javascript:">
                <h6 class="m-b-5">teste02<span class="text-muted float-right f-14">30 min ago</span></h6>
              </a>
            </div>
          </div>
          <div class="row m-b-25 align-items-center">
            <div class="col-auto p-r-0">
              <i class="feather icon-file-text badge-light-success feed-icon"></i>
            </div>
            <div class="col">
              <a href="javascript:">
                <h6 class="m-b-5">teste03 <span class="text-muted float-right f-14">Just Now</span>
                </h6>
              </a>
            </div>
          </div>
          <div class="row m-b-25 align-items-center">
            <div class="col-auto p-r-0">
              <i class="feather icon-bell badge-light-primary feed-icon"></i>
            </div>
            <div class="col">
              <a href="javascript:">
                <h6 class="m-b-5">teste04 <span class="text-muted float-right f-14">1 hours ago</span>
                </h6>
              </a>
            </div>
          </div>

        </perfect-scrollbar>
      </app-card>
    </div>
  </div>


  