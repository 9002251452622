<div class="row">
    <div class="col-md-9">
        <label class="sub-title">Nome Diário</label>
        <input type="text" class="form-control" [(ngModel)]="pergunta" name="pergunta" placeholder="">
    </div>
    <div class="col-md-3">
        <label class="sub-title">Tipo</label>
        <select class="form-control" [(ngModel)]="tipo" name="tipo" (ngModelChange)="mudarTipo()">
            <option value="M">Fase Módulos</option>
            <option value="P">Fase Prática</option>
        </select>
    </div>    
</div>
<div class="row">
    <div class="col-md-12">
        <label class="sub-title">Descricão</label>
        <textarea rows="3" class="form-control" [(ngModel)]="descricao" name="descricao"></textarea>
    </div>
</div>
<br><br>
<div class="row">
    <div class="col-md-12">

        <div class="form-group " *ngFor="let item of opcoes; let i = index">
            <div class="row box">
              
                <div class="col-2"></div>
                <div class="col-4">
                    <input class="form-check-input" type="radio" name="gridRadios" id="{{item.perguntaOpcao}}"
                        value="{{item.perguntaOpcao}}">
                    <label class="form-check-label" for="gridRadios1">{{item.perguntaOpcao}} </label>&nbsp;&nbsp;
                </div>
                <div class="col-1"><input type="number" class="form-control" [(ngModel)]="item.peso" name="item.peso"></div>
                <div class="col-2">
                    <label class="sub-title">{{item?.confirmacaoInstrucao}}</label>
                       
                </div>
                <div class="col-1">
                    <span *ngFor="let opci of item.opcoesConfirmacaoInstrucao;">{{opci.display}}<br></span>
                </div>
                <div class="col-1">
                    <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                        (click)="removeOpcaoColuna(i)"><i class="fa fa-trash" ngbTooltip="Remover da Lista"></i>
                    </a>
                </div>
                <br><br>
            </div>
        </div>

        <!--<div class="custom-control custom-radio custom-control-inline"
            *ngFor="let item of opcoes; let i = index">

            <input class="form-check-input" type="radio" name="gridRadios" id="{{item.perguntaOpcao}}" value="{{item.perguntaOpcao}}">

            <label class="form-check-label" for="gridRadios1">{{item.perguntaOpcao}} </label>&nbsp;&nbsp;
            <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                (click)="removeOpcaoColuna(i)" style="font-size: 15px; margin: 0px; padding:0px;">
                <i class="fa fa-trash" ngbTooltip="Remover coluna {{item.chave}}"></i>
            </a>
        </div>-->
    </div>

    <div class="col-md-10">

        <label class="sub-title">Opção</label>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <button class="btn btn-primary" type="button" (click)="addLinha()"><i class="fa fa-plus"></i></button>
            </div>
            <input type="text" class="form-control" placeholder="Digite sua opção e clique o botão +"
                aria-describedby="basic-addon1" [(ngModel)]="perguntaOpcao" name="perguntaOpcao">
        </div>
    </div>
    <div class="col-md-2" >
        <label class="sub-title">Peso</label>
        <input type="number" class="form-control" [(ngModel)]="peso" name="peso"
            placeholder="">
    </div>
    <div class="col-md-6" *ngIf="tipo =='P'">
        <label class="sub-title">Opção 2</label>
        <input type="text" class="form-control" [(ngModel)]="confirmacaoInstrucao" name="confirmacaoInstrucao"
            placeholder="">
    </div>
    <div class="col-md-6" *ngIf="tipo =='P'">
        <label class="sub-title">Opçoes</label>
        <tag-input [ngClass]="'tag-select'" theme='bootstrap' [placeholder]="'+'"
            [(ngModel)]="opcoesConfirmacaoInstrucao" [addOnBlur]="true" [clearOnBlur]="true">
            <tag-input-dropdown></tag-input-dropdown>
        </tag-input>
        <br>
    </div>

</div>

<div class="row">
    <div class="col-md-6 text-left">
        <br>
        <button type="button" class="btn btn-danger" data-toggle="Salvar" (click)="voltar()">
            <i class="fa fa-chevron-circle-left"></i>
            Voltar
        </button>
    </div>
    <div class="col-md-6 text-right" *ngIf="item == null">
        <br>
        <button type="button" class="btn btn-primary" data-toggle="Salvar" (click)="salvar()">
            <i class="feather icon-check-circle"></i>
            Salvar
        </button>
    </div>
    <div class="col-md-6 text-right" *ngIf="item != null">
        <br>
        <!--<button type="button" class="btn btn-warning" data-toggle="Salvar" (click)="salvar()">
        <i class="feather icon-check-circle"></i>
        Limpar
      </button>-->
        <button type="button" class="btn btn-dark" data-toggle="Salvar" (click)="alterar()">
            <i class="feather icon-check-circle"></i>
            Alterar
        </button>
    </div>

</div>