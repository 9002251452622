<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class="row">
  <div class="col-12">
    <app-card cardTitle="Cadastro Turma" [options]="false">
      <div class="row">
        <div class="col-sm-12 col-xl-4 ">
          <label class="sub-title">Nome Turma</label>
          <input type="text" class="form-control" [(ngModel)]="nome" name="nome" placeholder="">
        </div>
        <div class="col-sm-12 col-xl-4 ">
          <br>
          <button type="button" class="btn btn-success " (click)="salvar()"  style="margin-top: 6px;">SALVAR &nbsp;<i
              class="feather icon-check-circle"></i>
          </button>
          &nbsp;&nbsp;&nbsp;
          <button type="button" class="btn btn-warning " (click)="limpar()" *ngIf="turmaSelecionada != null" style="margin-top: 6px;">LIMPAR &nbsp;<i
              class="feather icon-check-circle"></i>
          </button>
        </div>
      </div>



    </app-card>
  </div>
  <div class="col-12">
    <app-card cardTitle="Turmas Cadastrados" blockClass="table-border-style" [options]="false">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Descricão</th>
              <th>Acão</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of arrayTurma">
              <td>{{item.descricao }}</td>
              <td>
                <button type="button" class="btn btn-icon btn-info " (click)="carregarEdicao(item)">
                  <i class="fa fa-edit"></i>
                </button>
                &nbsp;&nbsp;
                <button type="button" class="btn btn-icon btn-danger " (click)="desabilitar(item.id)">
                  <i class="fa fa-trash"></i>
                </button>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </app-card>
  </div>
</div>
