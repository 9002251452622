import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CabecalhoTabela } from 'src/app/model/CabecalhoTabela';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, BASE_API, IMG_PADRAO } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { MinhaTabelaComponent } from '../minha-tabela/minha-tabela.component';

@Component({
  selector: 'app-cadastro-midia',
  templateUrl: './cadastro-midia.component.html',
  styleUrls: ['./cadastro-midia.component.scss']
})
export class CadastroMidiaComponent implements OnInit {
  @ViewChild(MinhaTabelaComponent, { static: false })
  public tabelaComponent: MinhaTabelaComponent;


  public arrayTipoMidias: any[];
  public tipoMidiaSelecionada: any;
  public arrayMidias: any[];
  public midiaSelecionada: any;
  public nome: string;
  public descricao: string;
  public uploadedFile: File;

  public arrayCabecalho: CabecalhoTabela[];
  public urlMidia: string;

  constructor(
    private ajax: AjaxService,
    public util: UtilService
  ) {
    this.arrayTipoMidias = [];
    this.urlMidia = 'Midia/Midia';
    this.limpar();
    this.arrayCabecalho = [
      { atributo: 'titulo', label: 'TITULO', tamanho: '2', acao: false, align: "left" },
      { atributo: 'tipO_MIDIA_DESCRICAO', label: 'TIPO', tamanho: '1', acao: false, align: "left" },
      { atributo: 'contenT_TYPE', label: 'EXTENSÃO', tamanho: '1', acao: false, align: "left" },
      { atributo: 'nome', label: 'ARQUIVO', tamanho: '1', acao: false, align: "left" },
      {
        atributo: '', label: '', tamanho: '2', acao: true, align: "center",
        listAcao: [
          { atributo: '', label: 'VISUALIZAR', tamanho: '1', acao: true, align: "center" },
          { atributo: '', label: 'EDITAR', tamanho: '1', acao: true, align: "center" },
          { atributo: '', label: 'DELETAR', tamanho: '1', acao: true, align: "center" }
        ]
      }


    ];
  }

  ngOnInit() {
    this.buscarTipoMidias();
  }

  receberItemEditar(item) {
    console.log('receberItemEditar', item);
    this.carregarItemEditar(item);
  }

  receberItemApagar(item) {
    console.log('receberItemApagar', item);

    Swal.fire({
      html: `Tem certeza que deseja apagar o arquivo <br>${item.titulo}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      showLoaderOnConfirm: true
    }).then((result) => {
      debugger
      if (result.value != undefined && result.value != '') {
        this.apagarMidia(item);
      }
    });


  }

  receberItemVisualizar(item) {
    console.log('receberItemVisualizar', item);
    this.verArquivo(item);
  }


  voltar() {
    this.limpar();
  }

  apagarMidia(item) {
    this.util.showLoading();
    let url = API + "Midia/Midia/Desabilitar/"+item.iD_MIDIA;
    return this.ajax.post(url, {})
      .subscribe(response => {
        this.arrayTipoMidias = response.data;
        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "Mídia desabilitada com sucesso");
        window.location.reload();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Tipo Midia");
      });
  }

  verArquivo(item) {
    let url = item.patH_FILE != null ? BASE_API + "/" + item.patH_FILE : IMG_PADRAO;
    window.open(url, '_blank').focus();
  }

  salvarAlteracao() {
    if (this.validarMidia()) {
      let dadosQrcode = {
        "ID_MIDIA": this.midiaSelecionada.iD_MIDIA,
        "ID_TIPO_MIDIA": this.tipoMidiaSelecionada,
        "DESCRICAO": this.descricao == undefined || this.descricao == null ? "" : this.descricao,
        "TITULO": this.nome
      }

      debugger
      //this.uploadedFile[0]
      if (this.uploadedFile != undefined || this.uploadedFile != null)
        dadosQrcode['Arquivo'] = this.uploadedFile[0]

      debugger
      let url = API + "Midia/Midia";
      let dados = this.util.jsonToFormData(dadosQrcode);
      this.util.showLoading();
      return this.ajax.postFile(url, dados).subscribe(response => {
        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Midia Salva com Sucesso!");
        this.limpar();
        this.tabelaComponent.buscar(false);
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Tipo Midia");
      });
    }
  }

  carregarItemEditar(item) {
    this.midiaSelecionada = item;
    this.nome = item.titulo;
    this.descricao = item.descricao;
    this.tipoMidiaSelecionada = item.iD_TIPO_MIDIA;
  }

  limpar() {
    this.tipoMidiaSelecionada = null;
    this.uploadedFile = null;
    this.nome = "";
    this.descricao = "";
    this.midiaSelecionada = null;
  }

  validarMidia() {
    if (this.nome == undefined || this.nome == "") {
      this.util.toasterShowError("Atenção", "Entre com o Titulo!");
      return false;
    }

    if (this.tipoMidiaSelecionada == undefined || this.tipoMidiaSelecionada == "") {
      this.util.toasterShowError("Atenção", "Entre com o tipo de Midia");
      return false;
    }

    if (this.midiaSelecionada == null && (this.uploadedFile == undefined || this.uploadedFile == null)) {
      this.util.toasterShowError("Atenção", "Entre com a Midia");
      return false;
    }



    return true;
  }

  salvarMidia() {
    if (this.validarMidia()) {
      let dadosQrcode = {
        "ID_TIPO_MIDIA": this.tipoMidiaSelecionada,
        "DESCRICAO": this.descricao,
        "TITULO": this.nome,
        "Arquivo": this.uploadedFile[0]
      }
      debugger
      let url = API + "Midia/Midia";
      let dados = this.util.jsonToFormData(dadosQrcode);
      this.util.showLoading();
      return this.ajax.postFile(url, dados).subscribe(response => {
        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Midia Salva com Sucesso!");
        this.limpar();
        this.tabelaComponent.buscar(false);
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Tipo Midia");
      });
    }
  }


  buscarTipoMidias() {
    this.util.showLoading();
    let url = API + "TipoMidia/TipoMidia";
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayTipoMidias = response.data;
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Tipo Midia");
      });
  }


  salvarExtencao(titulo) {
    this.util.showLoading();
    let url = API + "TipoMidia/TipoMidia";
    return this.ajax.post(url, { "DESCRICAO": titulo })
      .subscribe(response => {
        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");
        this.buscarTipoMidias();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Tipo Midia");
      });
  }

  openExtencao() {
    Swal.fire({
      text: 'Entre com a Extenção a ser Cadastrada',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      showLoaderOnConfirm: true
    }).then((result) => {
      console.log(result);
      if (result.value != undefined && result.value != '') {
        this.salvarExtencao(result.value);
      }
    });
  }

}
