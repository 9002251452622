<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Aguarde ...</p>
  </ngx-spinner>
  
  <div class="row">
    <div class="col-12">
      <app-card cardTitle="Envio de Push Notification" [options]="false">
        <div class="row">
          <div class="col-md-6">
            <label class="sub-title">Título</label>
            <input type="text" class="form-control" [(ngModel)]="titulo" name="titulo" placeholder="">
          </div>          
  
        </div>
        <div class="row">
          <div class="col-md-10">
            <label class="sub-title">Mensagem </label>           
            <input type="text" class="form-control" [(ngModel)]="mensagem" name="descricao" placeholder="">
          </div>
         
        </div>
        <div class="row">
          <div class="col-sm-12 col-xl-10 text-right" >
            <br>
            <button type="button" class="btn btn-success " (click)="enviar()">Enviar &nbsp;<i
                class="feather icon-check-circle"></i>
            </button>
          </div>          
        </div>
  
  
      </app-card>
    </div>
  </div>
  