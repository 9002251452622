<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>




<div class="row">
     
  <div class="col-sm-12">
    <div class="drag-list__wrap center">
      <div class="">
        <div class="drag-list__item" [class.active]="x == selectedItem" (click)="onItemSelect(x)"
          *ngFor="let x of arrayFormulario; let index = index;">

          <!--<h5 class="card-title ">Card title</h5>-->
          <div *ngIf="x.paginaCabecalho" class="row paginacaoCabecalho">
            <div class="text-white text-center col-10">
              Página {{x.pagina}}
            </div>
            <div class="col-2 text-white text-center">
              <button type="button" (click)="removerpagina()" class="btn btn-icon btn-danger fecharpagina "
                ngbTooltip="Remover Página"><i class="feather icon-trash-2 "></i> </button>
            </div>

          </div>


          <div class="row">

            <div class="col-sm-12 col-xl-10">
              <label class="sub-title" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO != '9'">{{x.titulo}}</label>

              <div class="custom-control custom-checkbox" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '9' ">
                <!-- Check -->
                <label class="sub-title">{{x.titulo}}</label>
                <div class="">

                  <div class="form-group " *ngFor="let item of x.valores; let i = index">
                    <div class="row box">
                      <div class="col-2"></div>
                      <div class="col-1">
                      </div>
                      <div class="col-5">

                        <div class="checkbox">
                          {{i}} - <input type="checkbox" name="{{item}}" id="{{item}}">
                          <label for="{{item}}" class="cr">{{item}}</label>
                        </div>
                      </div>
                      <div class="col-1">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <fieldset class="form-group" *ngIf=" x.iD_TIPO_CAMPO_FORMULARIO == '10'">
                <div class="row">
                  <div class="col-sm-9">
                    <div class="form-check" *ngFor="let item of x.valores; let i = index">
                      <input class="form-check-input" type="radio" name="gridRadios" id="{{item}}"
                        value="{{item}}">
                      <label class="form-check-label" for="gridRadios1">{{item}}</label>
                    </div>

                  </div>
                </div>
              </fieldset>


              <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '3'"
                [textMask]="{mask: maskCPF}">
              <!-- CPF -->
              <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '5'">
              <!-- E-mail -->
              <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '7'"
                [textMask]="{mask: numberMaskInt}">
              <!-- Numérico (não aceita ponto ou vírgula) -->
              <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '4'">
              <!-- RG -->

              <select class="form-control form-control-default" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '8'">
                <!-- Seleção de Dados -->
                <option *ngFor="let i of x.valores">{{i}}
              </select>

              <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '2'"
                [textMask]="{mask: maskTelefoneCelular}"> <!-- Telefone -->
              <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '1'">
              <!-- Texto Livre -->
              <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '6'"
                [textMask]="{mask: numberMaskReal}">
              <!-- Valor Monetário (aceita ponto ou vírgula) -->

            </div>

            <div class="col-sm-1 col-xl-1">
              <label class="sub-title"> &nbsp;</label><br>
              <button type="button" (click)="deleteItem(index)" class="btn btn-icon btn-danger"
                ngbTooltip="Retirar da lista"><i class="feather icon-trash-2 "></i> </button>
            </div>
          </div>

        </div>
        <div class="col-sm-12 col-xl-12" (click)="onItemSelect('')" *ngIf="habilitarForm">
          <!-- Default select start -->
          <app-card cardTitle="Adicionar campo ao Formulário" [options]="false">
            <div class="row">

              <div class="col-sm-12 col-xl-6 m-b-30">
                <label class="sub-title">Digite sua Pergunta</label>
                <input type="text" class="form-control" [(ngModel)]="campoLabel" name="campoLabel"
                  placeholder="">
              </div>
              <div class="col-sm-12 col-xl-3 m-b-30">
                <label class="sub-title">Estilo da Pergunta</label>
                <select [(ngModel)]="tipoCampoSelecionado" name="tipoCampoSelecionado"
                  class="form-control form-control-default">
                  <option *ngFor="let item of arrayTipos" value={{item.id}}>
                    {{item.descricao}}
                </select>
              </div>
              <div class="col-sm-12 col-xl-2 m-b-30">
                <label class="sub-title"> &nbsp; </label>
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="customswitch1"
                    [(ngModel)]="ehObrigatorio">
                  <label class="custom-control-label" for="customswitch1">Obrigatório?</label>
                </div>
              </div>
              <div class="col-sm-12 col-xl-12 m-b-30">
                <label class="sub-title">Digite uma Descrição</label>
                <textarea rows="3" class="form-control" [(ngModel)]="descricaoLabel"
                  name="descricaoLabel"></textarea>

              </div>
              <div class="col-sm-12 col-xl-8" *ngIf="tipoCampoSelecionado == '8'">
                <label class="sub-title">Digite a opção e aperte a tecla <code>ENTER</code></label>
                <tag-input [ngClass]="'tag-select'" theme='bootstrap' [placeholder]="'+'"
                  [(ngModel)]="arraySelectOpcao" [addOnBlur]="true" [clearOnBlur]="true">
                  <tag-input-dropdown></tag-input-dropdown>
                </tag-input>
                <br>
              </div>

              <div class="col-sm-12 col-xl-12" *ngIf="tipoCampoSelecionado == '9'">

                <div class="container" dragula="VAMPIRES" [(dragulaModel)]="arraySelectOpcao">
                  <div class="form-group " *ngFor="let item of arraySelectOpcao; let i = index">
                    <div class="row box">
                      <div class="col-2"></div>
                      <div class="col-1">
                        <a type="button"
                          class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormal"><i
                            class="fa fa-arrows-alt" ngbTooltip="Mover"></i>
                        </a>

                      </div>
                      <div class="col-5">

                        <div class="checkbox">
                          {{i}} - <input type="checkbox" name="{{item}}" id="{{item}}">
                          <label for="{{item}}" class="cr">{{item}}</label>
                        </div>
                      </div>
                      <div class="col-1">
                        <a type="button"
                          class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                          (click)="removeOpcao(i)"><i class="fa fa-trash" ngbTooltip="Remover da Lista"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <label class="sub-title">Digite sua Opção e clique no Botão +</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button class="btn btn-primary" type="button" (click)="addOpcao()"><i
                        class="fa fa-plus"></i></button>
                  </div>
                  <input type="text" class="form-control" placeholder="Digite sua opção e clique o botão +"
                    aria-describedby="basic-addon1" [(ngModel)]="inputOpcoes" name="inputOpcoes">
                </div>
              </div>

              <div class="col-sm-12 col-xl-12" *ngIf="tipoCampoSelecionado == '10'">

                <div class="container" dragula="VAMPIRES" [(dragulaModel)]="arraySelectOpcao">


                  <div class="form-group " *ngFor="let item of arraySelectOpcao; let i = index">
                    <div class="row box">
                      <div class="col-2"></div>
                      <div class="col-1">
                        <a type="button"
                          class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormal"><i
                            class="fa fa-arrows-alt" ngbTooltip="Mover"></i>
                        </a>

                      </div>
                      <div class="col-5">
                        <div class="form-check">
                          {{i}} - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input class="form-check-input" type="radio" name="gridRadios" id="{{item}}"
                            value="{{item}}">
                          <label class="form-check-label" for="gridRadios1">{{item}}</label>
                        </div>
                      </div>
                      <div class="col-1">
                        <a type="button"
                          class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                          (click)="removeOpcao(i)"><i class="fa fa-trash" ngbTooltip="Remover da Lista"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <label class="sub-title">Digite sua Opção e clique no Botão +</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <button class="btn btn-primary" type="button" (click)="addOpcao()"><i
                        class="fa fa-plus"></i></button>
                  </div>
                  <input type="text" class="form-control" placeholder="Digite sua opção e clique o botão +"
                    aria-describedby="basic-addon1" [(ngModel)]="inputOpcoes" name="inputOpcoes">
                </div>
              </div>

            </div>

            <div class="row" align="center">
              <div class="col-sm-12 col-xl-12 m-b-30">
                <!--<button type="button" class="btn btn-success" (click)="addItem()"> Adicionar um Tipo &nbsp;<i
                  class="fa fa-plus"></i>
              </button>-->
                <button type="button" class="btn btn-icon btn-outline-success buttonRadius"
                  (click)="addItem()" ngbTooltip="Adicionar Pergunta">
                  <i class="fa fa-plus"></i></button>
              </div>
            </div>
          </app-card>
        </div>
      </div>
      <div class="drag-list__move-items" [class.active]="selectedItem">
        <button style="font-size:2em;" (click)="moveUp()"
          [disabled]="!selectedItem || selectedItem && selectedItem.position == 1">
          <i class="fa fa-arrow-up"></i>
        </button>
        <button style="font-size:2em;" (click)="moveDown()"
          [disabled]="!selectedItem || selectedItem && arrayFormulario.length == selectedItem.position">
          <i class="fa fa-arrow-down"></i>
        </button>

        <a type="button" class="btn btn-icon btn-outline-success buttonRadiusSemBorda"><i class="fa fa-bars"
            ngbTooltip="Adicionar uma Página" (click)="adicionarpagina()"></i></a>
      </div>
    </div>
  </div>


</div>






















































<div class="row">

  <div class="col-xl-4 col-lg-6 col-md-6">
    <app-card cardTitle="Square Rating">
      <p>Use attribute<code>theme</code> as value <code>square</code> to see square rating</p>
      <div class="box-body">
        <bar-rating [(rate)]="rateSquare" [max]="10" [theme]="'square'"></bar-rating>
      </div>
    </app-card>
  </div>

</div>
<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Cadastro de Triagem" [options]="false">


      <aw-wizard #wizard class="arc-wizard">
        <aw-wizard-step stepTitle="Escolha do Programa">
          <div class="row">
            <app-card [hidHeader]="true" [options]="false" class="col-sm-12">
              <div class="col-sm-12" align="center">
                <div class="col-sm-12 col-xl-5 ">
                  <label class="sub-title">Selecione um Programa</label>
                  <ng-select [ngClass]="'tag-select'" [options]="arrayProgramas" [(ngModel)]="programaSelecionado">
                  </ng-select>

                </div>
                <br>
              </div>
            </app-card>
            <div class="col-sm-12 centered-content">
              <div class="btn-group mt-10">
                <button type="button" class="btn btn-primary btn-lg" awNextStep>Próximo</button>
              </div>
            </div>
          </div>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Cabeçalho Triagem">
          <div class="row">
            <app-card [hidHeader]="true" [options]="false" class="col-sm-12">
              <div class="row ">
                <div class="col-sm-12 col-xl-6 ">
                  <label class="sub-title">Nome da Triagem</label>
                  <input type="text" class="form-control" [(ngModel)]="nome" name="nome" placeholder="">
                </div>
                <div class="col-sm-12 col-xl-3 ">
                  <label class="sub-title">Data Inicio Triagem</label>
                  <input type="date" class="form-control" [(ngModel)]="dataInicial" name="dataInicial" placeholder="">
                </div>
                <div class="col-sm-12 col-xl-3 ">
                  <label class="sub-title">Data Fim Triagem</label>
                  <input type="date" class="form-control" [(ngModel)]="dataFinal" name="dataFinal" placeholder="">
                </div>
                <div class="col-sm-12 col-xl-6">
                  <label class="sub-title">Foto Banner </label>
                  <file-upload [(ngModel)]="uploadedFilesCapa" [fileslimit]="1" accept="image/*" simple></file-upload>
                </div>
              </div>
            </app-card>
            <div class="col-sm-12 centered-content">
              <div class="btn-group mt-10">
                <button type="button" class="btn btn-secondary btn-lg" awPreviousStep>Voltar</button>
                <button type="button" class="btn btn-primary btn-lg" awNextStep>Próximo</button>
              </div>
            </div>
          </div>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Formulação das Perguntas">
          <div class="row">
     
              <div class="col-sm-12">
                <div class="drag-list__wrap center">
                  <div class="">
                    <div class="drag-list__item" [class.active]="x == selectedItem" (click)="onItemSelect(x)"
                      *ngFor="let x of arrayFormulario; let index = index;">

                      <!--<h5 class="card-title ">Card title</h5>-->
                      <div *ngIf="x.paginaCabecalho" class="row paginacaoCabecalho">
                        <div class="text-white text-center col-10">
                          Página {{x.pagina}}
                        </div>
                        <div class="col-2 text-white text-center">
                          <button type="button" (click)="removerpagina()" class="btn btn-icon btn-danger fecharpagina "
                            ngbTooltip="Remover Página"><i class="feather icon-trash-2 "></i> </button>
                        </div>

                      </div>


                      <div class="row">

                        <div class="col-sm-12 col-xl-10">
                          <label class="sub-title" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO != '9'">{{x.titulo}}</label>

                          <div class="custom-control custom-checkbox" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '9' ">
                            <!-- Check -->
                            <label class="sub-title">{{x.titulo}}</label>
                            <div class="">

                              <div class="form-group " *ngFor="let item of x.valores; let i = index">
                                <div class="row box">
                                  <div class="col-2"></div>
                                  <div class="col-1">
                                  </div>
                                  <div class="col-5">

                                    <div class="checkbox">
                                      {{i}} - <input type="checkbox" name="{{item}}" id="{{item}}">
                                      <label for="{{item}}" class="cr">{{item}}</label>
                                    </div>
                                  </div>
                                  <div class="col-1">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <fieldset class="form-group" *ngIf=" x.iD_TIPO_CAMPO_FORMULARIO == '10'">
                            <div class="row">
                              <!--<label for="inputPassword3" class="col-sm-3 col-form-label">Radios</label>-->
                              <div class="col-sm-9">
                                <div class="form-check" *ngFor="let item of x.valores; let i = index">
                                  <input class="form-check-input" type="radio" name="gridRadios" id="{{item}}"
                                    value="{{item}}">
                                  <label class="form-check-label" for="gridRadios1">{{item}}</label>
                                </div>
                                <!--<div class="form-check">
                                <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="option2">
                                <label class="form-check-label" for="gridRadios2">Second radio</label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios3" value="option3" disabled>
                                <label class="form-check-label" for="gridRadios3">Third disabled radio</label>
                              </div>-->
                              </div>
                            </div>
                          </fieldset>
                          <!--<div class="custom-control custom-checkbox"
                          *ngIf=" x.iD_TIPO_CAMPO_FORMULARIO == '10'">          
                          <label class="sub-title">{{x.titulo}}</label>
                          <div class="">
          
                            <div class="form-group " *ngFor="let item of x.valores; let i = index">
                              <div class="row box">
                                <div class="col-2"></div>
                                <div class="col-1">
                                </div>
                                <div class="col-5">
          
                                  <div class="checkbox">
                                    {{i}} - <input type="checkbox" name="{{item}}" id="{{item}}">
                                    <label for="{{item}}" class="cr">{{item}}</label>
                                  </div>
                                </div>
                                <div class="col-1">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>-->

                          <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '3'"
                            [textMask]="{mask: maskCPF}">
                          <!-- CPF -->
                          <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '5'">
                          <!-- E-mail -->
                          <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '7'"
                            [textMask]="{mask: numberMaskInt}">
                          <!-- Numérico (não aceita ponto ou vírgula) -->
                          <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '4'">
                          <!-- RG -->

                          <select class="form-control form-control-default" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '8'">
                            <!-- Seleção de Dados -->
                            <option *ngFor="let i of x.valores">{{i}}
                          </select>

                          <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '2'"
                            [textMask]="{mask: maskTelefoneCelular}"> <!-- Telefone -->
                          <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '1'">
                          <!-- Texto Livre -->
                          <input type="text" class="form-control" *ngIf="x.iD_TIPO_CAMPO_FORMULARIO == '6'"
                            [textMask]="{mask: numberMaskReal}">
                          <!-- Valor Monetário (aceita ponto ou vírgula) -->

                        </div>
                        <!--<div class="col-sm-2 col-xl-2">
                    <label class="sub-title">Ordem</label>
                    <input type="number" class="form-control" [(ngModel)]="x.ordem" placeholder="">
                  </div>-->
                        <div class="col-sm-1 col-xl-1">
                          <label class="sub-title"> &nbsp;</label><br>
                          <button type="button" (click)="deleteItem(index)" class="btn btn-icon btn-danger"
                            ngbTooltip="Retirar da lista"><i class="feather icon-trash-2 "></i> </button>
                        </div>
                      </div>

                    </div>
                    <div class="col-sm-12 col-xl-12" (click)="onItemSelect('')" *ngIf="habilitarForm">
                      <!-- Default select start -->
                      <app-card cardTitle="Adicionar campo ao Formulário" [options]="false">
                        <div class="row">

                          <div class="col-sm-12 col-xl-6 m-b-30">
                            <label class="sub-title">Digite sua Pergunta</label>
                            <input type="text" class="form-control" [(ngModel)]="campoLabel" name="campoLabel"
                              placeholder="">
                          </div>
                          <div class="col-sm-12 col-xl-3 m-b-30">
                            <label class="sub-title">Estilo da Pergunta</label>
                            <select [(ngModel)]="tipoCampoSelecionado" name="tipoCampoSelecionado"
                              class="form-control form-control-default">
                              <option *ngFor="let item of arrayTipos" value={{item.id}}>
                                {{item.descricao}}
                            </select>
                          </div>
                          <div class="col-sm-12 col-xl-2 m-b-30">
                            <label class="sub-title"> &nbsp; </label>
                            <div class="custom-control custom-switch">
                              <input type="checkbox" class="custom-control-input" id="customswitch1"
                                [(ngModel)]="ehObrigatorio">
                              <label class="custom-control-label" for="customswitch1">Obrigatório?</label>
                            </div>
                          </div>
                          <div class="col-sm-12 col-xl-12 m-b-30">
                            <label class="sub-title">Digite uma Descrição</label>
                            <textarea rows="3" class="form-control" [(ngModel)]="descricaoLabel"
                              name="descricaoLabel"></textarea>

                          </div>
                          <div class="col-sm-12 col-xl-8" *ngIf="tipoCampoSelecionado == '8'">
                            <label class="sub-title">Digite a opção e aperte a tecla <code>ENTER</code></label>
                            <tag-input [ngClass]="'tag-select'" theme='bootstrap' [placeholder]="'+'"
                              [(ngModel)]="arraySelectOpcao" [addOnBlur]="true" [clearOnBlur]="true">
                              <tag-input-dropdown></tag-input-dropdown>
                            </tag-input>
                            <br>
                          </div>

                          <div class="col-sm-12 col-xl-12" *ngIf="tipoCampoSelecionado == '9'">

                            <div class="container" dragula="VAMPIRES" [(dragulaModel)]="arraySelectOpcao">
                              <div class="form-group " *ngFor="let item of arraySelectOpcao; let i = index">
                                <div class="row box">
                                  <div class="col-2"></div>
                                  <div class="col-1">
                                    <a type="button"
                                      class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormal"><i
                                        class="fa fa-arrows-alt" ngbTooltip="Mover"></i>
                                    </a>

                                  </div>
                                  <div class="col-5">

                                    <div class="checkbox">
                                      {{i}} - <input type="checkbox" name="{{item}}" id="{{item}}">
                                      <label for="{{item}}" class="cr">{{item}}</label>
                                    </div>
                                  </div>
                                  <div class="col-1">
                                    <a type="button"
                                      class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                                      (click)="removeOpcao(i)"><i class="fa fa-trash" ngbTooltip="Remover da Lista"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <label class="sub-title">Digite sua Opção e clique no Botão +</label>
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <button class="btn btn-primary" type="button" (click)="addOpcao()"><i
                                    class="fa fa-plus"></i></button>
                              </div>
                              <input type="text" class="form-control" placeholder="Digite sua opção e clique o botão +"
                                aria-describedby="basic-addon1" [(ngModel)]="inputOpcoes" name="inputOpcoes">
                            </div>
                          </div>

                          <div class="col-sm-12 col-xl-12" *ngIf="tipoCampoSelecionado == '10'">

                            <div class="container" dragula="VAMPIRES" [(dragulaModel)]="arraySelectOpcao">


                              <div class="form-group " *ngFor="let item of arraySelectOpcao; let i = index">
                                <div class="row box">
                                  <div class="col-2"></div>
                                  <div class="col-1">
                                    <a type="button"
                                      class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormal"><i
                                        class="fa fa-arrows-alt" ngbTooltip="Mover"></i>
                                    </a>

                                  </div>
                                  <div class="col-5">
                                    <div class="form-check">
                                      {{i}} - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <input class="form-check-input" type="radio" name="gridRadios" id="{{item}}"
                                        value="{{item}}">
                                      <label class="form-check-label" for="gridRadios1">{{item}}</label>
                                    </div>
                                  </div>
                                  <div class="col-1">
                                    <a type="button"
                                      class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                                      (click)="removeOpcao(i)"><i class="fa fa-trash" ngbTooltip="Remover da Lista"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <label class="sub-title">Digite sua Opção e clique no Botão +</label>
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <button class="btn btn-primary" type="button" (click)="addOpcao()"><i
                                    class="fa fa-plus"></i></button>
                              </div>
                              <input type="text" class="form-control" placeholder="Digite sua opção e clique o botão +"
                                aria-describedby="basic-addon1" [(ngModel)]="inputOpcoes" name="inputOpcoes">
                            </div>
                          </div>

                        </div>

                        <div class="row" align="center">
                          <div class="col-sm-12 col-xl-12 m-b-30">
                            <!--<button type="button" class="btn btn-success" (click)="addItem()"> Adicionar um Tipo &nbsp;<i
                              class="fa fa-plus"></i>
                          </button>-->
                            <button type="button" class="btn btn-icon btn-outline-success buttonRadius"
                              (click)="addItem()" ngbTooltip="Adicionar Pergunta">
                              <i class="fa fa-plus"></i></button>
                          </div>
                        </div>
                      </app-card>
                    </div>
                  </div>
                  <div class="drag-list__move-items" [class.active]="selectedItem">
                    <button style="font-size:2em;" (click)="moveUp()"
                      [disabled]="!selectedItem || selectedItem && selectedItem.position == 1">
                      <i class="fa fa-arrow-up"></i>
                    </button>
                    <button style="font-size:2em;" (click)="moveDown()"
                      [disabled]="!selectedItem || selectedItem && arrayFormulario.length == selectedItem.position">
                      <i class="fa fa-arrow-down"></i>
                    </button>

                    <!--<a type="button" class="btn btn-icon btn-outline-success buttonRadiusSemBorda"><i class="fa fa-plus"
                      ngbTooltip="Adicionar uma Pergunta" (click)="habilitaForm()"></i></a>-->
                    <a type="button" class="btn btn-icon btn-outline-success buttonRadiusSemBorda"><i class="fa fa-bars"
                        ngbTooltip="Adicionar uma Página" (click)="adicionarpagina()"></i></a>
                  </div>
                </div>
              </div>
      
            <div class="col-sm-12 centered-content">
              <div class="btn-group mt-10">
                <button type="button" class="btn btn-secondary btn-lg" awPreviousStep>Voltar</button>
                <button type="button" class="btn btn-primary btn-lg" awNextStep>Próximo</button>
              </div>
            </div>
          </div>
        </aw-wizard-step>
        <aw-wizard-step stepTitle="Salvar Triagem">
          <div class="row">
            <div class="col-sm-12">
              <div class="" (click)="onItemSelect('')">
                <app-card [hidHeader]="true" align="center">
                  <button type="button" class="btn btn-success btn-lg" (click)="criarTriagem()"> ENVIAR FORMULÁRIO
                    &nbsp;<i class="feather icon-check-circle"></i>
                  </button>
                </app-card>
              </div>
            </div>
            <div class="col-sm-12 centered-content">
              <div class="btn-group mt-10">
                <button type="button" class="btn btn-secondary btn-lg" awPreviousStep>Voltar</button>
                <!--<button type="button" class="btn btn-success btn-sm" awResetWizard>Finished</button>-->
              </div>
            </div>
          </div>
        </aw-wizard-step>
      </aw-wizard>








      <div class="row">



        <!--<div class="col-sm-12 col-xl-6 m-b-30">
          <label class="sub-title">Descricao Triagem</label>
          <textarea name="" id="" rows="3" class="form-control" [(ngModel)]="descricaoQrcode"
            name="descricaoQrcode"></textarea>
        </div>-->
      </div>



    </app-card>
  </div>

</div>

<div class=" row  ">
  <div class="col-sm-12 col-xl-12 ">



  </div>
</div>
