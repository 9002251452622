<div class="row">
  <div class="col-md-3">
    <!--<label class="sub-title">Obrigatorio</label>
    <input type="text" class="form-control" [(ngModel)]="titulo" name="titulo" placeholder="">-->
    <div class="form-group">
      <div class="switch switch-success d-inline m-r-10">
        <input type="checkbox" id="switch-p-2" [(ngModel)]="ehRecuperacao">
        <label for="switch-p-2" class="cr"></label>
      </div>
      <label>É UMA RECUPERAÇÃO?</label>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <label class="sub-title">Nome Trilha</label>
    <input type="text" class="form-control" [(ngModel)]="titulo" name="titulo" placeholder="">
  </div>
  <div class="col-md-3" *ngIf="!ehRecuperacao">
    <label class="sub-title">Ordem</label>
    <input type="number" class="form-control" [(ngModel)]="ordem" name="ordem" placeholder="">
  </div>
  <div class="col-md-3">
    <!--<label class="sub-title">Obrigatorio</label>
      <input type="text" class="form-control" [(ngModel)]="titulo" name="titulo" placeholder="">-->
    <div class="form-group" *ngIf="!ehRecuperacao">
      <div class="switch switch-success d-inline m-r-10">
        <input type="checkbox" id="switch-p-1" [(ngModel)]="obrigatorio">
        <label for="switch-p-1" class="cr"></label>
      </div>
      <label>OBRIGATÓRIO</label>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <label class="sub-title">Avatar App</label>
    <file-upload [(ngModel)]="uploadedFileAvatar" [fileslimit]="1" accept="image/*" simple></file-upload>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <label class="sub-title">Descricão</label>
    <textarea rows="3" class="form-control" [(ngModel)]="descricao" name="descricao"></textarea>
  </div>
</div>

<div class="row" >
  <div class="col-md-11">
    <label class="sub-title">Trilha de Recuperação</label>
    <!--<ng-select [ngClass]="'tag-select'" [options]="arrayEtapaRecuperacao" name="etapaRecuperacaoSelecionado" [(ngModel)]="etapaRecuperacaoSelecionado">
   
    </ng-select>-->

    <select [(ngModel)]="etapaRecuperacaoSelecionado" name="etapaRecuperacaoSelecionado" class="form-control form-control-default">
      <option *ngFor="let item of arrayEtapaRecuperacao" value={{item.value}}>
        {{item.label}}
    </select>
  </div>
  <div class="col-md-1" style="padding-top: 26px;">
    <button type="button" class="btn btn-icon btn-danger " (click)="buttonLimparSelectRecuperacao()">
      <i class="fa fa-trash"></i>
    </button>
  </div>
</div>

<div class="row">
  <div class="col-md-6 text-left">
    <br>
    <button type="button" class="btn btn-danger" data-toggle="Salvar" (click)="voltar()">
      <i class="fa fa-chevron-circle-left"></i>
      Voltar
    </button>
  </div>
  <div class="col-md-6 text-right" *ngIf="item == null">
    <br>
    <button type="button" class="btn btn-primary" data-toggle="Salvar" (click)="salvar()">
      <i class="feather icon-check-circle"></i>
      Salvar
    </button>
  </div>
  <div class="col-md-6 text-right" *ngIf="item != null">
    <br>
    <!--<button type="button" class="btn btn-warning" data-toggle="Salvar" (click)="salvar()">
        <i class="feather icon-check-circle"></i>
        Limpar
      </button>-->
    <button type="button" class="btn btn-dark" data-toggle="Salvar" (click)="salvar()">
      <i class="feather icon-check-circle"></i>
      Alterar
    </button>
  </div>

</div>