import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AjaxService } from "src/app/service/ajax.service";
import { AuthService } from "src/app/service/auth.service";
import { UtilService } from "src/app/service/util.service";
import { API } from "src/environments/environment";

@Component({
  selector: "app-cadastro-turma",
  templateUrl: "./cadastro-turma.component.html",
  styleUrls: ["./cadastro-turma.component.scss"],
})
export class CadastroTurmaComponent implements OnInit {
  public nome: string;
  public arrayTurma: any;
  public turmaSelecionada: any;

  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.buscar();
  }

  carregarEdicao(item) {
    console.log(item);
    this.limpar();
    this.turmaSelecionada = item;
    this.nome = item.descricao;
  }

  buscar() {
    this.util.showLoading();
    let url = API + "Turma/Turma";
    return this.ajax.get(url).subscribe(
      (response) => {
        this.arrayTurma = response.data;
        console.log(this.arrayTurma);
        this.util.closeLoading();
      },
      (err) => {
        debugger;
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Turma");
      }
    );
  }

  valida() {
    if (this.nome == undefined || this.nome == "") {
      this.util.toasterShowError("Atenção", "Entre com o Nome do Turma");
      return false;
    }

    return true;
  }

  limpar() {
    this.nome = "";
    this.turmaSelecionada = null;
  }

  salvar() {
    if (this.valida()) {
      debugger;
      let dados = {
        descricao: this.nome,
        STATUS: true,
      };

      if(this.turmaSelecionada != null)
        dados['ID_TURMA'] = this.turmaSelecionada.iD_TURMA;

      let url = API + "Turma/Turma";
      return this.ajax.post(url, dados).subscribe(
        (response) => {
          this.util.closeLoading();
          this.util.toasterShowSuccess("Atenção", "Turma Salvo com Sucesso");
          this.limpar();
          this.buscar();
        },
        (err) => {
          debugger;
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Turma");
        }
      );
    }
  }

  desabilitar(id: string) {
    debugger;
    let dados = {
      id: id,
    };
    let url = API + "Turma/Turma/Desabilitar/" + id;
    return this.ajax.post(url, dados).subscribe(
      (response) => {
        this.util.closeLoading();
        this.util.toasterShowSuccess(
          "Atenção",
          "Turma Desabilitado com Sucesso"
        );
        this.nome = "";
        this.buscar();
      },
      (err) => {
        debugger;
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Turma");
      }
    );
  }
}
