import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AjaxService } from 'src/app/service/ajax.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-modal-add-formulario',
  templateUrl: './modal-add-formulario.component.html',
  styleUrls: ['./modal-add-formulario.component.scss']
})
export class ModalAddFormularioComponent implements OnInit {
  @Output() itemSelecionado = new EventEmitter();

  public arrayFormularios: any[];

  constructor(
    private ajax: AjaxService,
    public util: UtilService
  ) { }

  ngOnInit() {
    this.buscar();
  }

  tableSelecionar(item: any){
    this.itemSelecionado.emit(item);
  }

  buscar(){

    this.util.showLoading();
    let url = API + "Triagem/Triagem?TIPO=F";
    return this.ajax.get(url)
      .subscribe(response => {  
        this.arrayFormularios = response.data;      
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Dados");
      });
  }

}
