import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-programa',
  templateUrl: './programa.component.html',
  styleUrls: ['./programa.component.scss']
})
export class ProgramaComponent implements OnInit {

  public nome: string;
  public dataInicial: string;
  public dataFinal: string;
  public arrayProgramas: any;
  public programaSelecionado: any;


  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.buscar();
  }

  carregarEdicao(item){
    console.log(item);
    this.limpar();
    this.programaSelecionado = item;
    this.nome = item.descricao;
    this.dataInicial = this.util.formatDateSQL(item.datA_INICIO);
    this.dataFinal = this.util.formatDateSQL(item.datA_FIM);
  }

  buscar() {
    this.util.showLoading();
    let url = API + "Programa/Programa?STATUS=true";
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayProgramas = response.data;
        console.log(this.arrayProgramas);
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Programa");
      });
  }

  valida() {
    if (this.nome == undefined || this.nome == "") {
      this.util.toasterShowError("Atenção", "Entre com o Nome do Programa");
      return false;
    }

    if (this.dataInicial == undefined || this.dataInicial == "") {
      this.util.toasterShowError("Atenção", "Entre com a Data Inicial");
      return false;
    }

    if (this.dataFinal == undefined || this.dataFinal == "") {
      this.util.toasterShowError("Atenção", "Entre com a Data Final");
      return false;
    }

    return true;

  }

  limpar() {
    this.nome = "";
    this.dataInicial = "";
    this.dataFinal = "";
    this.programaSelecionado = null;
  }

  salvar() {

    if (this.valida()) {

      debugger
      let dados = {
        "descricao": this.nome,
        "datA_INICIO": this.dataInicial,
        "datA_FIM": this.dataFinal,
        "STATUS": true
      }

      if(this.programaSelecionado != null)
        dados['ID_PROGRAMA'] = this.programaSelecionado.iD_PROGRAMA;

      let url = API + "Programa/Programa";
      return this.ajax.post(url, dados)
        .subscribe(response => {

          this.util.closeLoading();
          this.util.toasterShowSuccess("Atenção", "Programa Salvo com Sucesso");
          this.limpar();
          this.buscar();
        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Programa");
        });

    }

  }


  desabilitar(id: string) {

    debugger
    let dados = {
      "id": id
    }
    let url = API + "Programa/Programa/Desabilitar/" + id;
    return this.ajax.post(url, dados)
      .subscribe(response => {

        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Programa Desabilitado com Sucesso");
        this.nome = "";
        this.buscar();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Programa");
      });

  }

}
