import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-pesquisa-questoes',
  templateUrl: './pesquisa-questoes.component.html',
  styleUrls: ['./pesquisa-questoes.component.scss']
})
export class PesquisaQuestoesComponent implements OnInit {

  arrayPerguntas: [];
  perguntaSelecionada: any;
  query: string;

  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService) { }

  ngOnInit() {
    this.buscarPerguntas();
  }

  openModal(modal, item){
    this.perguntaSelecionada = item;
    modal.show();
  }

  visualizarPerguntas(item){
    debugger
    this.router.navigate(['/editar-questao',item.iD_PERGUNTA]);
    
    /*this.util.showLoading();
    let url = API + "Pergunta/Pergunta?IS_RESUMIR_AGRUPAMENTOS=true&iD_PERGUNTA="+ item.iD_PERGUNTA;
    return this.ajax.get(url)
      .subscribe(response => {
        this.perguntaSelecionada = response.data[0];
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Perguntas");
      });*/
    

        /*this.util.showLoading();
        let url = API + "CampoFormularioTriagem/CampoFormularioTriagem/EditarTriagem/" + this.idTriagem;
        return this.ajax.get(url)
          .subscribe((resp: any) => {
    
            this.arrayPerguntas = resp.data;
            console.log('array perguntas', this.arrayPerguntas)
    
    
            //alert('finalizou');
            this.util.closeLoading();
          }, err => {
            debugger
            this.util.closeLoading();
            this.util.toasterShowError("Atenção", "Erro ao Buscar Detalhes");
          });*/
      
  }

  buscarPerguntas() {
    this.util.showLoading();
    let url = API + "Pergunta/Pergunta?IS_RESUMIR_AGRUPAMENTOS=true";
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayPerguntas = response.data;
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Perguntas");
      });
  }


  desabilitar(id: string) {

    debugger
    let dados = {
      "id": id
    }
    let url = API + "Pergunta/Pergunta/Desabilitar/" + id;
    return this.ajax.post(url, dados)
      .subscribe(response => {

        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Pergunta Desabilitado com Sucesso");
        this.buscarPerguntas();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao desabilitar Pergunta");
      });

  }

}
