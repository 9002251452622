<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Aguarde ...</p>
  </ngx-spinner>
<div class="row">
    <div class="col-7">
        <app-card cardTitle="Etapas" blockClass="table-border-style" [options]="false">
            <div class="row">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Título</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of arrayEtapas"
                                [ngClass]="{highlighted: selectedRowIndexEtapa == item.id}" (click)="selectEtapa(item)">
                                <td class="col-1">
                                    <i  class="fas fa-circle f-10 m-r-5 text-warning" *ngIf="item.eH_RECUPERACAO"></i>
                                    <div class="feed-user-img">
                                        <img src="{{BASE_API + '/' + item.patH_AVATAR}}" class="img-radius "
                                            width="40px" height="40px">
                                    </div>
                                </td>
                                <td>
                                    <i  class="fas fa-circle f-10 m-r-5 text-warning" *ngIf="item.eH_RECUPERACAO"></i> {{item.titulo}} 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </app-card>
    </div>
    <div class="col-5">
        <app-card cardTitle="Diário" blockClass="table-border-style" [options]="false">
            <div class="row">
                <div class="col-8">
                    <app-alert *ngIf="arrayEtapasDiario?.length > 0" type="success">
                        <a href="javascript:" class="alert-link">
                            {{ arrayEtapasDiario[0]["nomE_DIARIO"] }}
                        </a>
                    </app-alert>
                </div>
                <div class="col-4">
                    <app-alert *ngIf="arrayEtapasDiario?.length > 0" type="warning">
                        <a href="javascript:" class="alert-link">
                            {{ arrayEtapasDiario[0]["tipO_DIARIO"] == "M" ? "Fase Módulos": "Fase Prática" }}
                        </a>
                    </app-alert>
                </div>
                <div class="col-12" *ngIf="arrayEtapasDiario?.length > 0">
                    <button type="button" class="btn btn-primary" data-toggle="modal" (click)="habilitaTableAlterar()">
                        <i class="feather icon-edit"></i>
                        alterar </button>
                </div>
                <div class="col-12" *ngIf="habilitaAlterarDiario">
                    <br>
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Escolha um Diario para esta Etapa</th>
                                <th>Tipo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of arrayDiarios" (click)="selectDiario(item)">
                                <td class="col-1">                                    
                                </td>
                                <td>
                                    {{item.pergunta}} {{item.tipo}}
                                </td>
                                <td><span>{{item.tipo == "M" ? "Fase Módulos": "Fase Prática"}}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
           

            <app-alert *ngIf=" etapaSelecionado != null && arrayEtapasDiario?.length == 0" type="danger"> <a href="javascript:"
                    class="alert-link">Não Há Diário Relacionado Ainda</a></app-alert>

            <div *ngIf="etapaSelecionado != null && arrayEtapasDiario?.length == 0">
                <table class="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Escolha um Diario para esta Etapa</th>
                            <th>Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of arrayDiarios" (click)="selectDiario(item)">
                            <td class="col-1">
                                <!--<div class="feed-user-img">
                                    <img src="{{BASE_API + '/' + item.patH_AVATAR}}" class="img-radius " width="40px"
                                        height="40px">
                                </div>-->
                            </td>
                            <td>
                                {{item.pergunta}} 
                            </td>
                            <td><span>{{item.tipo == "M" ? "Fase Módulos": "Fase Prática"}}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </app-card>
    </div>

</div>