import { Component, OnInit } from "@angular/core";
import { AjaxService } from "src/app/service/ajax.service";
import { UtilService } from "src/app/service/util.service";
import { API } from "src/environments/environment";

@Component({
  selector: "app-cadastro-diario",
  templateUrl: "./cadastro-diario.component.html",
  styleUrls: ["./cadastro-diario.component.scss"],
})
export class CadastroDiarioComponent implements OnInit {
  public diarioItem: any;
  public arrayDiarios: [];
  public ehCadastro: boolean;

  constructor(private ajax: AjaxService, public util: UtilService) {}

  ngOnInit() {
    this.limpar();
    this.buscarDiarios();
  }

  limpar() {
    this.ehCadastro = false;
    this.diarioItem = null;
    this.arrayDiarios = [];
  }

  inicioCadastro() {
    this.ehCadastro = true;
  }

  voltar() {
    this.buscarDiarios();
  }

  buscarDiarios() {
    this.limpar();
    this.util.showLoading();
    let url = API + "Diario/Diario/buscarDiario";
    return this.ajax.get(url).subscribe(
      (response) => {
        console.log(response);
        this.arrayDiarios = response.data;
        this.util.closeLoading();
      },
      (err) => {
        debugger;
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Diários");
      }
    );
  }

  editar(item){
    this.ehCadastro
    this.diarioItem = item;
  }

  openModal(modal, item=null) {
    if(item != null)
      this.diarioItem = item;

    modal.show();
    //console.log("mostrou modal"), item;
  }

  closeModal(modal, nome) {
    modal.hide();
    this.voltar();

  }


}
