<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class="col-md-12">
  <!-- Default select start -->
  <app-card cardTitle="Resultado Triagem" [options]="false">
    <div class="row">

      <div class="col-md-12">
        <app-card [hidHeader]="true" cardClass="ticket-card">
          <div class="row">
            <div class="col-md-6 text-left">
              <h4 class="m-b-0 d-inline-block text-c-green text-right"> Título: {{tituloTriagem}} </h4>
            </div>
            <div class="col-md-6 text-right">
              <p class="m-b-25 badge-light-info lbl-card"><i class="fa fa-info-circle m-r-5"></i>Valor corte:
                <strong>{{objResultado?.valoR_CORTE}}</strong> Pontos </p>
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-4 text-center">
              <h2 class="m-b-0 d-inline-block text-danger"> {{objResultado?.totaL_REPROVADO}} </h2>
              <p class=""> Reprovados </p>
            </div>
            <div class="col-md-4 text-center">
              <h2 class="m-b-0 d-inline-block text-success"> {{objResultado?.totaL_APROVADO}} </h2>
              <p class=""> Aprovados </p>
            </div>
            <div class=" col-md-4 text-center">            
                <h2 class="d-inline-block text-danger"> {{objResultado?.totaL_ELIMINADO}} </h2>
                <p class=" "> Eliminados </p>  
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-12 text-right">
              <p class="m-b-25 badge-info lbl-card"><i class="fa fa-info-circle m-r-5"></i>Valor Total de Respostas da Triagem:
                <strong>{{arrayResultado?.length}}</strong>  </p>
            </div>
          </div>
        </app-card>
      </div>

    </div>
    <div class="row">


      <input type="text" class="form-control" [(ngModel)]="query"
        placeholder="Digite seu filtro, sera feito uma busca em todas as colunas">
      <br><br>
      <div class="table-responsive">
        <table class="table table-hover">
          <div style="height:480px; ">
            <thead>
              <tr>
                <th>Responsável</th>
                <th>Criança</th>
                <th>Pontuação</th>
                <th></th>
                <th></th>
                <th></th>


              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of arrayResultado | fullTextSearch:query">
                <td class="col-2">{{item.nomE_RESPONSAVEL }}</td>
                <td class="col-4">{{item.nomE_CRIANCA }}</td>
                <td class="col-4">
                  <strong *ngIf="item.pontos >= objResultado?.valoR_CORTE" class="text-success">{{item.pontos }}</strong> 
                  <strong *ngIf="item.pontos < objResultado?.valoR_CORTE" class="text-danger">{{item.pontos }}</strong> 
                </td>
                <td class="col-1">
                  <span *ngIf="item.aprovado" class="badge badge-success">APROVADO</span>
                  <span *ngIf="!item.aprovado" class="badge badge-danger">REPROVADO</span>
                </td>
                <td class="col-1">
                  <span *ngIf="item.eliminado" class="badge badge-danger">ELIMINADO</span>
                  <span *ngIf="!item.eliminado" class="badge badge-success">CLASSIFICADO</span>
                </td>

                <td class="col-1">
                  <!--<button type="button" class="btn btn-icon btn-info btn-sm " (click)="visualizarPerguntas(item)"
                    ngbTooltip="Editar Pergunta">
                    <i class="fa fa-eye"></i>
                  </button>-->
                  <!--<button type="button" class="btn btn-icon btn-info btn-sm " (click)="openModal(myLargeModalLabel, item)"
                  ngbTooltip="Ver Pergunta">
                  <i class="fa fa-eye"></i>
                </button>-->
                  <!--<button type="button" class="btn btn-icon btn-danger btn-sm" (click)="desabilitar(item.id)">
                    <i class="fa fa-trash"></i>
                  </button>-->
                </td>

              </tr>
            </tbody>
          </div>
        </table>
      </div>

    </div>

  </app-card>
</div>



<!--<div class="row">
    <div class="col-12">
      <app-card  blockClass="table-border-style" [options]="false">
        <div [innerHTML]="htmlRespostas"></div>
        
      </app-card>
    </div>
  </div>-->
