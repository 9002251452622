<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<app-ui-modal #modalMensagens [hideFooter]="true" [dialogClass]="'modal-lg'">
  <div class="app-modal-header">
    <h5 class="modal-title">Cadastro de Mensagens</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="closeModal(modalMensagens, 'mensagem')"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-12">
            <label class="sub-title">Boas Vindas</label>
            <input type="text" class="form-control" [(ngModel)]="objMensagens.BoasVindas" name="objMensagens.BoasVinda"
              placeholder="">
          </div>
          <div class="col-md-12">
            <label class="sub-title">Sucesso</label>
            <input type="text" class="form-control" [(ngModel)]="objMensagens.Sucesso"
              name="ordemAtiobjMensagens.Sucessovidade" placeholder="">
          </div>
          <div class="col-md-12">
            <label class="sub-title">Falha</label>
            <input type="text" class="form-control" [(ngModel)]="objMensagens.Falha" name="objMensagens.Falha"
              placeholder="">
          </div>
          <div class="col-md-12">
            <label class="sub-title">Saída</label>
            <input type="text" class="form-control" [(ngModel)]="objMensagens.Saida" name="objMensagens.Saida"
              placeholder="">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 text-left">
          <br>
          <button type="button" class="btn btn-danger" (click)="closeModal(modalMensagens, 'mensagem')">
            <i class="fa fa-chevron-circle-left"></i>
            Voltar
          </button>
        </div>
        <div class="col-md-6 text-right">
          <br>
          <button type="button" class="btn btn-primary" (click)="closeModal(modalMensagens, 'mensagem')">
            <i class="feather icon-check-circle"></i>
            Adicionar
          </button>
        </div>
      </div>
    </div>
  </div>
</app-ui-modal>

<app-ui-modal #modalCadastroModulo [hideFooter]="true" [dialogClass]="'modal-lg'">
  <div class="app-modal-header">
    <h5 class="modal-title">Cadastro de Módulos</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="closeModal(modalCadastroModulo, 'modulo')"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <app-modal-cadastro-modulos [ID_PROGRAMA]="programaSelecionado"  [item]="editarItemSelecionado"
      (acaoVoltar)="closeModal(modalCadastroModulo, 'modulo')">
    </app-modal-cadastro-modulos>
  </div>
</app-ui-modal>

<app-ui-modal #modalAddEtapa [hideFooter]="true" [dialogClass]="'modal-lg'">
  <div class="app-modal-header">
    <h5 class="modal-title">Adicionar nova Trilha</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="closeModal(modalAddEtapa, 'etapa')"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <app-modal-add-etapa (acaoVoltar)="closeModal(modalAddEtapa, 'etapa')"
      [ID_MODULO]="moduloSelecionado?.iD_MODULO_PROGRAMA" [item]="editarItemSelecionado"></app-modal-add-etapa>
  </div>
</app-ui-modal>

<app-ui-modal #modalAddFormulario [hideFooter]="true" [dialogClass]="'modal-md'">
  <div class="app-modal-header">
    <h5 class="modal-title">Busca Formulário</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="closeModal(modalAddFormulario,'formulario')"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <app-modal-add-formulario (itemSelecionado)="receberFormularioSelecionada($event, modalAddFormulario)">
    </app-modal-add-formulario>
  </div>
</app-ui-modal>


<app-ui-modal #modalAddMidia [hideFooter]="true" [dialogClass]="'modal-lg'">
  <div class="app-modal-header">
    <h5 class="modal-title">Busca Mídias</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="closeModal(modalAddMidia, 'midia')"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <app-minha-tabela [endPoint]="urlMidia" [arrayCabecalho]="arrayCabecalho"
      (itemSelecionar)="receberMidiaSelecionada($event, modalAddMidia)">
    </app-minha-tabela>
  </div>
</app-ui-modal>



<div class="row">
  <div class="col-7">
    <app-card cardTitle="Programas Ativos" blockClass="table-border-style" [options]="false">
      <ng-select [ngClass]="'tag-select'" [options]="arrayProgramas" [(ngModel)]="programaSelecionado"
        (ngModelChange)="buscarModulos()">
      </ng-select>
    </app-card>
  </div>
  <div class="col-5">

  </div>
</div>
<div class="row">

  <div class="col-3" *ngIf="programaSelecionado">
    <!--<div class="col-3">-->
    <app-card cardTitle="Modulos" blockClass="table-border-style" [options]="false">
      <div class="row">
        <button type="button" class="btn btn-primary" data-toggle="modal" (click)="openModal(modalCadastroModulo)">
          <i class="feather icon-plus-circle"></i>
          Cadastrar Novo Modúlo</button>

      </div>
      <br>
      <div class="row">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>título</th>
                <th>Ordem</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of arrayModulos"
                [ngClass]="{highlighted: selectedRowIndex == item.id}">
                <td class="col-9" (click)="selectModulo(item)">{{item.titulo }}</td>
                <td class="col-1" (click)="selectModulo(item)">{{item.ordem }}</td>
                <td class="col-1" (click)="selectModulo(item)">
                  <span *ngIf="item.obrigatorio" class="badge badge-success">Obrigarotio</span>
                  <span *ngIf="!item.obrigatorio" class="badge badge-warning">não Obrigatorio</span>
                </td>
                <td class="col-1">
                  <button type="button" class="btn btn-icon btn-dark " (click)="openModal(modalCadastroModulo, item)">
                    <i class="fa fa-edit"></i>
                  </button>
                  &nbsp;&nbsp;<button type="button" class="btn btn-icon btn-danger " (click)="deletarModulo(item)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </app-card>
  </div>
  <div class="col-3" *ngIf="moduloSelecionado">
    <!--<div class="col-3">-->
    <app-card cardTitle="Trilha" blockClass="table-border-style" [options]="false">
      <div class="row">
        <button type="button" class="btn btn-primary" data-toggle="modal" (click)="openModal(modalAddEtapa)">
          <i class="feather icon-plus-circle"></i>
          Cadastrar Nova Trilha</button>

      </div>
      <br>
      <div class="row">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th></th>
                <th>título</th>
                <th>Ordem</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of arrayEtapa"
                [ngClass]="{highlighted: selectedRowIndexEtapa == item.id}">
                <td class="col-1" (click)="selectEtapa(item)">
                  <i  class="fas fa-circle f-10 m-r-5 text-warning" *ngIf="item.eH_RECUPERACAO"></i>
                  <div class="feed-user-img">
                    <img src="{{BASE_API + '/' + item.patH_AVATAR}}" class="img-radius " 
                      width="40px" height="40px">
                  </div>
                </td>
                <td class="col-10"   (click)="selectEtapa(item)"> <i  class="fas fa-circle f-10 m-r-5 text-warning" *ngIf="item.eH_RECUPERACAO"></i>{{item.titulo }}</td>
                <td class="col-1" (click)="selectEtapa(item)"><span *ngIf="!item.eH_RECUPERACAO">{{item.ordem }}</span></td>
                <td class="col-1" (click)="selectEtapa(item)">
                  <span *ngIf="!item.eH_RECUPERACAO">
                    <span *ngIf="item.obrigatorio" class="badge badge-success">Obrigarotio</span>
                    <span *ngIf="!item.obrigatorio" class="badge badge-warning">não Obrigatorio</span>
                  </span>
                  <span *ngIf="item.eH_RECUPERACAO">
                    <span  class="badge badge-warning">Recuperação</span>                    
                  </span>                  
                </td>
                <td class="col-1">
                  <button type="button" class="btn btn-icon btn-dark " (click)="openModal(modalAddEtapa, item)">
                    <i class="fa fa-edit"></i>
                  </button>
                  &nbsp;&nbsp;<button type="button" class="btn btn-icon btn-danger " (click)="deletarEtapa(item)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </app-card>
  </div>
  <div class="col-6" *ngIf="etapaSelecionado">
    <!--<div class="col-6">-->

    <app-card cardTitle="Atividades" blockClass="table-border-style" [options]="false">
      <button type="button" class="btn btn-primary" data-toggle="modal" (click)="setHabilitaFormCadAtividade()">
        <i class="feather icon-plus-circle"></i>
        Cadastrar Nova Atividade
      </button>

      <div *ngIf="habilitarFormAtividade">
        <div class="row">
          <div class="col-md-6">
            <label class="sub-title">Nome Atividade</label>
            <input type="text" class="form-control" [(ngModel)]="nomeAtividade" name="nomeAtividade" placeholder="">
          </div>
          <div class="col-md-4">
            <label class="sub-title">Ordem</label>
            <input type="number" class="form-control" [(ngModel)]="ordemAtividade" name="ordemAtividade" placeholder="">
          </div>

        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <div class="switch switch-success d-inline m-r-10">
                <input type="checkbox" id="obrigatorioAtividade" name="obrigatorioAtividade" [(ngModel)]="obrigatorioAtividade">
                <label for="obrigatorioAtividade" class="cr"></label>
              </div>
              <label>OBRIGATÓRIO</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <div class="switch switch-success d-inline m-r-10">
                <input type="checkbox" id="ehEscala"  [(ngModel)]="ehEscala">
                <label for="ehEscala" class="cr"></label>
              </div>
              <label>É ESCALA</label>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <div class="switch switch-success d-inline m-r-10">
                <input type="checkbox" id="necessitaResposta"  [(ngModel)]="necessitaResposta">
                <label for="necessitaResposta" class="cr"></label>
              </div>
              <label>PRECISA RESPOSTA?</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label class="sub-title">Descricão</label>
            <app-tinymce [(ngModel)]="descricaoAtividade" name="descricaoAtividade" #tinymce='tinymce'></app-tinymce>
            <!--<div class="NgxEditor__Wrapper">
              <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
              <ngx-editor [editor]="editor" [(ngModel)]="descricaoAtividade"  [disabled]="false" [placeholder]="'Entre com a descrição da atividade'"></ngx-editor>
            </div>-->
            <!--<app-tinymce [(ngModel)]="descricaoAtividade" name="descricaoAtividade" #tinymce='tinymce'></app-tinymce>-->
            <!--<textarea rows="3" class="form-control" [(ngModel)]="descricaoAtividade" name="descricao"></textarea>-->
          </div>

          <div class="col-md-12">
            <br><br>
            <label class="sub-title">Ajuda</label>
            <app-tinymce [(ngModel)]="ajuda" name="ajuda" #tinymce='tinymce'></app-tinymce>
            <!--<textarea rows="3" class="form-control" [(ngModel)]="descricaoAtividade" name="descricao"></textarea>-->
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-4 text-right">
            <button type="button" class="btn btn-dark" (click)="openModal(modalAddMidia)">
              <i class="feather icon-plus-circle"></i>
              Adicionar Midia
            </button>
          </div>
          <div class="col-md-4 text-center">
            <button type="button" class="btn btn-warning" (click)="openModal(modalMensagens)">
              <i class="feather icon-plus-circle"></i>
              Mensagens
            </button>
          </div>
          <div class="col-md-4 text-left">
            <button type="button" class="btn btn-info" (click)="openModal(modalAddFormulario)">
              <i class="feather icon-plus-circle"></i>
              Add. Formulário
            </button>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-12" *ngIf="arrayMidiaSelecionadas.length > 0">
            <app-card [hidHeader]="true">

              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>título</th>
                      <th>tipo</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of arrayMidiaSelecionadas">
                      <td class="col-10">{{item.titulo }}</td>
                      <td class="col-1">{{item.tipO_MIDIA_DESCRICAO }}</td>
                      <td class="col-1">{{item.contenT_TYPE }}</td>
                      <td class="col-1">
                        <button type="button" class="btn btn-icon btn-danger " ngbTooltip="Deletar"
                          (click)="removerMidia(item)">
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </app-card>
          </div>

        </div>
        <div class="row">
          <div class="col-12">
            <app-card [hidHeader]="true" cardClass="comp-card" *ngIf="formularioSelecionada">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="m-b-20">Formulário</h6>
                  <h3 class="text-c-yellow">{{formularioSelecionada?.titulo}}</h3>
                </div>
                <div class="col-auto">
                  <i class="far fa-file-word badge-light-warning"></i>
                </div>
                <div class="col-auto">
                  <i class="fa fa-trash badge-light-danger" (click)="removerFormulario()"></i>
                </div>
              </div>
            </app-card>
          </div>

        </div>
        <div class="row">
          <div class="col-12" >
            <app-alert *ngIf="objMensagens?.BoasVindas != ''" type="primary">Boas Vindas: {{objMensagens?.BoasVindas}}</app-alert>
            <app-alert *ngIf="objMensagens?.Sucesso != ''" type="success">Sucesso: {{objMensagens?.Sucesso}}</app-alert>
            <app-alert *ngIf="objMensagens?.Falha != ''" type="danger">Falha: {{objMensagens?.Falha}}</app-alert>
            <app-alert *ngIf="objMensagens?.Saida != ''" type="secondary">Saida: {{objMensagens?.Saida}}</app-alert>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 text-left">
            <br>
            <button type="button" class="btn btn-danger" (click)="backHabilitaFormCadAtividade()">
              <i class="fa fa-chevron-circle-left"></i>
              Voltar
            </button>
          </div>
          <div class="col-md-6 text-right">
            <br>
            <button type="button" class="btn btn-primary" (click)="salvarAtividade()">
              <i class="feather icon-check-circle"></i>
              Salvar
            </button>
          </div>
        </div>
      </div>

    </app-card>


    <app-card cardTitle="Atividades Salvas" blockClass="table-border-style" [options]="false">
      <div class="row">
        <div class="table-responsive">
          <perfect-scrollbar [style.max-height]="'584px'">
            <table class="table mb-0">
              <tbody>
                <tr *ngFor="let item of arrayAtividades" [ngClass]="{highlighted: selectedRowIndexAtividade == item.id}"
                >
                <td>
                  <button type="button" class="btn btn-icon btn-danger " (click)="deletarAtividade(item)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
                  <td class="col-1" (click)="selectAtividade(item)">{{item.ordem}}</td>
                  <td class="col-5" (click)="selectAtividade(item)" style="white-space: initial" >
                    <h6 class="mb-1">{{item.titulo}}</h6>
                    <!--<p class="m-0">{{item.descricao}}</p>-->
                  </td>
                  <td class="col-2" (click)="selectAtividade(item)">
                    <h6 class="text-muted"><i class="far fa-file-word text-c-green f-10 m-r-15"></i>Form:<br>
                      {{item?.formulario?.titulo}}</h6>
                  </td>
                  <td class="col-4" (click)="selectAtividade(item)">Midias:
                    <span *ngFor="let it of item?.midias">
                      <br><a class="badge badge-light-primary badge-pill f-12 mr-2">{{it?.titulo}}</a>
                      <a class="badge badge-light-primary badge-pill f-12 mr-2">{{it?.nome}}</a>
                    </span>
                  </td>

                </tr>

              </tbody>
            </table>
          </perfect-scrollbar>
        </div>
      </div>
    </app-card>
  </div>
</div>
