import { Component, Input, OnInit } from '@angular/core';
import { ObjPergunta } from 'src/app/model/ObjetosPerguntas';
import { maskCPF, maskTelefoneCelular, numberMaskInt, numberMaskReal, TIPOS_ENTRADA } from 'src/environments/environment';

@Component({
  selector: 'app-visualizar-perguntas',
  templateUrl: './visualizar-perguntas.component.html',
  styleUrls: ['./visualizar-perguntas.component.scss']
})
export class VisualizarPerguntasComponent implements OnInit {

  @Input() pergunta?: ObjPergunta;
  @Input() mostrarObrigatorio?: boolean;

  public tipo = TIPOS_ENTRADA;
  maskCPF = maskCPF;
  maskTelefoneCelular = maskTelefoneCelular;
  numberMaskInt = numberMaskInt;
  numberMaskReal = numberMaskReal;

  arraySelecao: string[];

  constructor() {
    this.arraySelecao = [];
    this.mostrarObrigatorio = this.mostrarObrigatorio != undefined ? this.mostrarObrigatorio : false;
  }

  ngOnInit() {
    console.log('ngOnInit visualizar-perguntas',this.pergunta);
    if(this.pergunta.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Selecao
      || this.pergunta.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Radio){


      this.pergunta.valorSplit = this.pergunta.valores.split('|');
      if(this.pergunta.patH_FILE != null)
        this.pergunta.pathSplit = this.pergunta.patH_FILE.split('|');
    }

    if(this.pergunta.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Grade){

      if(this.pergunta.filhos != null){
        this.pergunta.filhos.forEach(item => {
          item.valorSplit = item.valores.split('|');
        });
      }
    }

    if(this.pergunta.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Check){
      this.pergunta?.filhos?.forEach(item => {
        if(this.pergunta.patH_FILE != null)
        this.pergunta.pathSplit = this.pergunta.patH_FILE.split('|');
      });
    }
  }

}
