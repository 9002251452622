import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { jwtDecode,  ResponseUser, User } from 'src/app/model/User.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  senha: string;
  jwt_decode: jwtDecode;
  user : ResponseUser = new ResponseUser();


  constructor(
    private router: Router,
    private ajax: AjaxService,
    private util: UtilService,
    private auth: AuthService,
  ) {
    this.user.user = new User();
    this.user.token = "";
   }

  ngOnInit() {
    this.email = '';
    this.senha = '';
  }

  login(){
    //let url = "https://api.qrcode.lumago.com.br/api/";
    //https://localhost:44308/api/Login/Login?login=diogo%40lumago.com&senha=123456

    //console.log(this.email, this.senha);
    this.util.showLoading();
    var url = API + 'Login/Login?login='+this.email+'&senha='+this.senha;
    this.ajax.get(url)
      .subscribe((response: ResponseNet) => {

        this.util.closeLoading();
        debugger
        this.jwt_decode = this.util.getDecodedAccessToken(response.data);
        this.user.user.nome = this.jwt_decode.nameid;
        this.user.token = response.data;
        //user['user']['nome']="";// user.user.nome
        this.auth.setUser(this.user);
        this.util.toasterShowSuccess("Sucesso", "Bem Vindo " + this.user.user.nome);
        //this.util.toasterShowSuccess("Sucesso", "Bem Vindo ");
        this.router.navigate(['/home']);
        //console.log(apt);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Usuário ou Senha inválidos");
      });

  }

}
