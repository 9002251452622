<div class="row">
  <div class="col-md-6">
    <label class="sub-title">Nome Módulo</label>
    <input type="text" class="form-control" [(ngModel)]="titulo" name="titulo" placeholder="">
  </div>
  <div class="col-md-3">
    <label class="sub-title">Ordem</label>
    <input type="number" class="form-control" [(ngModel)]="ordem" name="ordem" placeholder="">
  </div>
  <div class="col-md-3">
    <!--<label class="sub-title">Obrigatorio</label>
    <input type="text" class="form-control" [(ngModel)]="titulo" name="titulo" placeholder="">-->
    <div class="form-group">
        <div class="switch switch-success d-inline m-r-10">
          <input type="checkbox" id="switch-p-1" [(ngModel)]="obrigatorio">
          <label for="switch-p-1" class="cr"></label>
        </div>
        <label>OBRIGATÓRIO</label>
      </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <label class="sub-title">Descricão</label>
    <textarea rows="3" class="form-control" [(ngModel)]="descricao" name="descricao"></textarea>
  </div>
</div>
<div class="row">
    <div class="col-md-6 text-left">
        <br>
        <button type="button" class="btn btn-danger" data-toggle="Salvar" (click)="voltar()">
          <i class="fa fa-chevron-circle-left"></i>
          Voltar
        </button>
      </div>
  <div class="col-md-6 text-right" *ngIf="item == null">
    <br>
    <button type="button" class="btn btn-primary" data-toggle="Salvar" (click)="salvar()">
      <i class="feather icon-check-circle"></i>
      Salvar
    </button>
  </div>
  <div class="col-md-6 text-right" *ngIf="item != null">
    <br>
    <!--<button type="button" class="btn btn-warning" data-toggle="Salvar" (click)="salvar()">
      <i class="feather icon-check-circle"></i>
      Limpar
    </button>-->
    <button type="button" class="btn btn-dark" data-toggle="Salvar" (click)="salvar()">
      <i class="feather icon-check-circle"></i>
      Alterar
    </button>
  </div>

</div>
