import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Menu',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [

      {
        id: 'home',
        title: 'Home',
        type: 'item',
        url: '/home',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },
      {
        id: 'aviso',
        title: 'Cadastro Avisos',
        type: 'item',
        url: '/avisos',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      }

    ]

  },
  {
    id: 'navigation',
    title: 'Programa',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'cadastro-programa',
        title: 'Cadastro Programa',
        type: 'item',
        url: '/cadastro-programa',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      }
    ]
  },
  {
    id: 'navigation',
    title: 'Turma',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'cadastro-turma',
        title: 'Cadastro Turma',
        type: 'item',
        url: '/cadastro-turma',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      }
    ]
  },
  {
    id: 'navigation',
    title: 'Triagem',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'cadastro-triagem',
        title: 'Cadastro Triagem',
        type: 'item',
        url: '/cadastro-triagem',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },

      {
        id: 'pesquisa-triagem',
        title: 'Pesquisa Triagem',
        type: 'item',
        url: '/pesquisa-triagem',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },
    ]
  },
  {
    id: 'navigation',
    title: 'Banco Questões',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'cadastro-participante',
        title: 'Cad. de Participante',
        type: 'item',
        url: '/cadastro-participante',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },
      {
        id: 'cadastro-responsavel',
        title: 'Cad. de Responsável',
        type: 'item',
        url: '/cadastro-responsavel',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },
      {
        id: 'cadastro-escala',
        title: 'Instrumentos/Escala',
        type: 'item',
        url: '/cadastro-escala',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },

      {
        id: 'cadastro-questao',
        title: 'Cadastro Questões',
        type: 'item',
        url: '/cadastro-questao',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },
      {
        id: 'pesquisa-questao',
        title: 'Pesquisa Questões',
        type: 'item',
        url: '/pesquisa-questao',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      }
    ]
  },
  {
    id: 'navigation',
    title: 'Relatorios',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'resultados',
        title: 'Resultados',
        type: 'item',
        url: '/resultados',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      }
    ]
  }
];


const NavigationItemsPrograma = [
  {
    id: 'navigation',
    title: 'Menu',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [

      {
        id: 'home-p',
        title: 'Home',
        type: 'item',
        url: '/home-p',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },      
      {
        id: 'push-notification',
        title: 'Push Notification',
        type: 'item',
        url: '/push-notification',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },      
      {
        id: 'meeting',
        title: 'Meeting',
        type: 'item',
        url: '/meeting',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      }

    ]

  },
  {
    id: 'navigation',
    title: 'Midias',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'cadastro-midia',
        title: 'Cadastro Midias',
        type: 'item',
        url: '/cadastro-midia',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      }
    ]
  },
  {
    id: 'navigation',
    title: 'Formulario',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'cadastro-formulario',
        title: 'Cadastro Formulario',
        type: 'item',
        url: '/cadastro-formulario',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      } ,
      {
        id: 'pesquisa-formulario',
        title: 'Pesquisa Formulario',
        type: 'item',
        url: '/pesquisa-formulario',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      }
      ,
      {
        id: 'pesquisa-pergunta',
        title: 'Pesquisa Pergunta',
        type: 'item',
        url: '/pesquisa-pergunta',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      }

    ]
  },
  {
    id: 'navigation',
    title: 'Programa',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'cadastro-modulos-programa',
        title: 'Cadastro Atividades',
        type: 'item',
        url: '/cadastro-modulos-programa',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      } ,


    ]
  },
  {
    id: 'navigation',
    title: 'Diário',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'cadastro-diario',
        title: 'Cadastro Diário',
        type: 'item',
        url: '/cadastro-diario',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      },
      {
        id: 'add-diario-etapa',
        title: 'Add Diário Etapa',
        type: 'item',
        url: '/add-diario-etapa',
        classes: 'nav-item',
        icon: 'feather icon-sidebar'
      }
    ]
  },

];

@Injectable()
export class NavigationItem {
  public get(ehTriagem?: boolean) {
    if(ehTriagem)
      return NavigationItems;
    else
      return NavigationItemsPrograma;
  }
}

