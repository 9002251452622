import { UtilService } from "../service/util.service";

export class Responsavel {

  constructor(
    iD_RESPONSAVEL_TEA: number,
    nome: string,
    cpf: string,
    telefone: string,
    email: string,
    cep: string,
    rua: string,
    numero: number,
    complemento: string,
    bairro: string,
    cidade: string,
    estado: string,
    iD_TRIAGEM: number,
    util?: UtilService,
    cartaoSus?: string,
    comoSoube?: string,
    rg?: string
    ){

      this.iD_RESPONSAVEL_TEA = iD_RESPONSAVEL_TEA;
      this.nome = nome;
      this.cpf = cpf;
      this.telefone = telefone;
      this.email = email;
      this.cep = cep;
      this.rua = rua;
      this.numero = numero;
      this.complemento = complemento;
      this.bairro = bairro;
      this.cidade = cidade;
      this.estado = estado;
      this.iD_TRIAGEM = iD_TRIAGEM;
      this.util = util;
      this.cartaoSus = cartaoSus;
      this.comoSoube = comoSoube;
      this.rg = rg;

  }

  iD_RESPONSAVEL_TEA: number;
  nome: string;
  cpf: string;
  telefone: string;
  email: string;
  cep: string;
  rua: string;
  numero: number;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  iD_TRIAGEM: number;
  util: UtilService;
  cartaoSus: string;
  comoSoube: string;
  rg: string;

  validar(): boolean{

    if(this.nome == undefined || this.nome == ""){
      this.util.toasterShowError("Atenção", "Entre com o Nome");
      return false;
    }

    if(this.cpf == undefined  || this.util.removeMask(this.cpf) == "" || this.util.removeMask(this.cpf).length < 11){
      this.util.toasterShowError("Atenção", "O Campo CPF não pode ser Vazio ou estar incompleto!!");
      return false;
    }

    if(this.email == undefined || this.email == ""){
      this.util.toasterShowError("Atenção", "Entre com o Email");
      return false;
    }

    if(this.telefone == undefined || this.util.removeMask(this.telefone) == "" || this.util.removeMask(this.telefone).length < 11){
      this.util.toasterShowError("Atenção", "O Campo Telefone não pode ser Vazio ou estar incompleto!!");
      return false;
    }

    if(this.cep == undefined || this.util.removeMask(this.cep) == "" || this.util.removeMask(this.cep).length < 8){
      this.util.toasterShowError("Atenção", "O Campo CEP não pode ser Vazio ou estar incompleto!!");
      return false;
    }

    if(this.rua == undefined || this.rua == ""){
      this.util.toasterShowError("Atenção", "Entre com o Rua");
      return false;
    }

    if(this.numero == undefined || this.numero == 0){
      this.util.toasterShowError("Atenção", "Entre com o Numero");
      return false;
    }

    if(this.bairro == undefined || this.bairro == ""){
      this.util.toasterShowError("Atenção", "Entre com o Bairro");
      return false;
    }

    if(this.cidade == undefined || this.cidade == ""){
      this.util.toasterShowError("Atenção", "Entre com a Cidade");
      return false;
    }
    
    if(this.estado == undefined || this.estado == ""){
      this.util.toasterShowError("Atenção", "Entre com o Estado");
      return false;
    }

    if(this.comoSoube == undefined || this.comoSoube == "")
    {
      this.util.toasterShowError("Atenção", "Como ficou sabendo da Pesquisa");
      return false;
    }

    return true;
  }

  convertJson():any{
    let dados = { 
      "nome": this.nome,
      "cpf": this.util.removeMask(this.cpf),
      "telefone": this.util.removeMask(this.telefone),
      "email": this.email,
      "cep": this.util.removeMask(this.cep),
      "rua": this.rua,
      "numero": this.numero.toString(),
      "complemento": this.complemento,
      "bairro": this.bairro,
      "cidade": this.cidade,
      "estado": this.estado,
      "ID_TRIAGEM":  this.iD_TRIAGEM,
      "CARTAO_SUS": this.cartaoSus,
      "COMO_SOUBE": this.comoSoube,
      "RG": this.rg,
    }

    debugger
    if(this.iD_RESPONSAVEL_TEA != undefined || this.iD_RESPONSAVEL_TEA > 0)
      dados['iD_RESPONSAVEL_TEA'] = this.iD_RESPONSAVEL_TEA;

      debugger
    return dados;

  }
  
}

export class ResponsavelTriagem {
  iD_RESPONSAVEL_TEA_TRIAGEM: number;
  iD_TRIAGEM: number;
  iD_RESPONSAVEL_TEA: number;
  idEncCrud: string;
}
