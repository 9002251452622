<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class="col-sm-12 col-xl-12 ">
  <!-- Default select start -->
  <app-card cardTitle="Perguntas Cadastradas" [options]="false">
    <div class="row">


      <input type="text" class="form-control" [(ngModel)]="query"
        placeholder="Digite seu filtro, sera feito uma busca em todas as colunas">
      <br><br>
      <div class="table-responsive">
        <table class="table table-hover">
          <div style="height:480px; ">
            <thead>
              <tr>
                <th>Instrumentos/Escala</th>
                <th>título</th>
                <th>Descricão</th>
                <th>TAG</th>
                <th>Tipo Campo</th>
                <th></th>
                <th>Ver</th>                
                <th hiden></th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of arrayPerguntas | fullTextSearch:query">
                <td class="col-2">{{item.grupO_DESCRICAO }}</td>
                <td class="col-4">{{item.titulo }}</td>
                <td class="col-4">{{item.descricao }}</td>
                <td class="col-4">{{item.tag }}</td>
                <td class="col-1">{{item.tipO_DESCRICAO}}</td>
                <td>
                  <span *ngIf="item.revisado" class="badge badge-success">REVISADO</span>
                  <span *ngIf="!item.revisado" class="badge badge-danger">NÃO REVISADO</span>
                </td>

                <td class="col-1">
                  <button type="button" class="btn btn-icon btn-info btn-sm " (click)="visualizarPerguntas(item)"
                    ngbTooltip="Editar Pergunta">
                    <i class="fa fa-eye"></i>
                  </button>
                  <!--<button type="button" class="btn btn-icon btn-info btn-sm " (click)="openModal(myLargeModalLabel, item)"
                  ngbTooltip="Ver Pergunta">
                  <i class="fa fa-eye"></i>
                </button>-->
                  <button type="button" class="btn btn-icon btn-danger btn-sm" (click)="desabilitar(item.id)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
                <td hidden>
                  <!--<app-visualizar-perguntas  [pergunta]="item"></app-visualizar-perguntas>-->
                  <!--<app-ui-modal #myLargeModalLabel [hideFooter]="true" [dialogClass]="'modal-lg'">
                    <div class="app-modal-header">
                      <h5 class="modal-title">Modal Title</h5>
                      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
                        (click)="myLargeModalLabel.hide()"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="app-modal-body">
                      <app-visualizar-perguntas  [pergunta]="item"></app-visualizar-perguntas>
                    </div>
                  </app-ui-modal>-->
                </td>
              </tr>
            </tbody>
          </div>
        </table>
      </div>

    </div>

  </app-card>
</div>

<!--
  <app-ui-modal #myLargeModalLabel [hideFooter]="true" [dialogClass]="'modal-lg'">
  <div class="app-modal-header">
    <h5 class="modal-title">Modal Title</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
      (click)="myLargeModalLabel.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
   <app-visualizar-perguntas *ngIf="perguntaSelecionada" [pergunta]="perguntaSelecionada"></app-visualizar-perguntas>
  </div>
</app-ui-modal>
-->
