import { Component, OnInit } from '@angular/core';
import { AjaxService } from 'src/app/service/ajax.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit {

  public titulo: string;
  public mensagem: string;

  constructor(
    private ajax: AjaxService,
    public util: UtilService,
  ) { }

  ngOnInit() {
  }


  valida() {
    if (this.titulo == undefined || this.titulo == "") {
      this.util.toasterShowError("Atenção", "Entre com o título");
      return false;
    }

    if (this.mensagem == undefined || this.mensagem == "") {
      this.util.toasterShowError("Atenção", "Entre com a mensagem");
      return false;
    }


    return true;

  }

  limpar() {
    this.titulo = "";
    this.mensagem = "";

  }


  enviar() {

    if (this.valida()) {
      debugger
      let dados = {
        "TITULO": this.titulo,
        "DESCRICAO": this.mensagem        
      }
      let url = API + "Aviso/Aviso/testeFirebaseMessageAsync";
      return this.ajax.post(url, dados)
        .subscribe(response => {

          this.util.closeLoading();
          this.util.toasterShowSuccess("Atenção", "Mensagem Enviada com Sucesso");
          this.limpar();      
        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Enviar Mensagem");
        });

    }

  }

}
