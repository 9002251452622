import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-pesquisa-pergunta',
  templateUrl: './pesquisa-pergunta.component.html',
  styleUrls: ['./pesquisa-pergunta.component.scss']
})
export class PesquisaPerguntaComponent implements OnInit {

  arrayPerguntas: [];
  perguntaSelecionada: any;
  query: string;

    constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService) { }

  ngOnInit() {
    this.buscarPerguntas();
  }

  openModal(modal, item){
    this.perguntaSelecionada = item;
    modal.show();
  }

  visualizarPerguntas(item){
    debugger
    this.router.navigate(['/editar-pergunta',item.iD_PERGUNTA]);       
  }

  buscarPerguntas() {
    this.util.showLoading();
    let url = API + "Pergunta/Pergunta?IS_RESUMIR_AGRUPAMENTOS=true&TIPO=F";
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayPerguntas = response.data;
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Perguntas");
      });
  }


  desabilitar(id: string) {

    debugger
    let dados = {
      "id": id
    }
    let url = API + "Pergunta/Pergunta/Desabilitar/" + id;
    return this.ajax.post(url, dados)
      .subscribe(response => {

        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Pergunta Desabilitado com Sucesso");
        this.buscarPerguntas();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao desabilitar Pergunta");
      });

  }

}
