import { Component, OnInit } from '@angular/core';
import { AjaxService } from 'src/app/service/ajax.service';
import { UtilService } from 'src/app/service/util.service';
import { API, BASE_API } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-adiciona-diario-etapa',
  templateUrl: './adiciona-diario-etapa.component.html',
  styleUrls: ['./adiciona-diario-etapa.component.scss']
})
export class AdicionaDiarioEtapaComponent implements OnInit {

  public BASE_API = BASE_API;
  public arrayDiarios: [];
  public arrayEtapas: [];
  public arrayEtapasDiario: any[];
  public habilitaAlterarDiario: boolean;

  public etapaSelecionado: any;
  public selectedRowIndexEtapa: number;

  constructor(private ajax: AjaxService, public util: UtilService) { }

  ngOnInit() {
    this.limpar();
    this.buscarEtapas();
    this.buscarDiarios();
  }

  limpar(){
    this.habilitaAlterarDiario = false;
    this.arrayEtapasDiario = [];
  }

  buscarDiarioRelacionado(item){   
    this.limpar();
    this.util.showLoading();
    let url = API + "Diario/Diario/buscarDiarioEtapas?ID_ETAPA_PROGRAMA="+item.id;
    return this.ajax.get(url).subscribe(
      (response) => {
        console.log(response);
        this.arrayEtapasDiario = response.data;
        this.util.closeLoading();
      },
      (err) => {
        debugger;
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Diários");
      }
    );
  }

  selectDiario(item){
    console.log(item);
    Swal.fire({
      html: `Tem certeza adicionar o Diário <br><strong>${item.pergunta}</strong><br> a esta Etapa?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Salvar',
      showLoaderOnConfirm: true
    }).then((result) => {
      debugger
      if (result.value != undefined && result.value != '') {
        this.adicionarDiarioRelacionamento(item);
      }
    });
  }

  adicionarDiarioRelacionamento(item){
    debugger
    let dados = {
      "ID_DIARIO": item.iD_DIARIO,
      "ID_ETAPA_PROGRAMA": this.etapaSelecionado.id
    }

    if(this.habilitaAlterarDiario)
        dados["ID_DIARIO_ETAPA_PROGRAMA"] = this.arrayEtapasDiario[0]["iD_DIARIO_ETAPA_PROGRAMA"];

    let url = API + "Diario/Diario/vincularDiarioEtapa";
    return this.ajax.post(url, dados)
    .subscribe(response  => {

      this.util.closeLoading();
      this.util.toasterShowSuccess("Atenção", "Salvo com Sucesso");      
      this.buscarDiarioRelacionado(this.etapaSelecionado);
      

    }, err => {
      debugger
      this.util.closeLoading();
      this.util.toasterShowError("Atenção", "Erro ao Salvar Escala");
    });
  }

  habilitaTableAlterar(){
    this.habilitaAlterarDiario = true;
  }

  selectEtapa(item){
    debugger
    this.selectedRowIndexEtapa = item.id;
    this.etapaSelecionado = item;
    this.buscarDiarioRelacionado(item);
  }

  buscarEtapas() {
    this.limpar();
    this.util.showLoading();
    let url = API + "ModuloPrograma/ModuloPrograma/ConsultarEtapaPrograma";
    return this.ajax.get(url).subscribe(
      (response) => {
        console.log(response);
        this.arrayEtapas = response.data;
        this.util.closeLoading();
      },
      (err) => {
        debugger;
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Diários");
      }
    );
  }

  buscarDiarios() {
    this.limpar();
    this.util.showLoading();
    let url = API + "Diario/Diario/buscarDiario";
    return this.ajax.get(url).subscribe(
      (response) => {
        console.log(response);
        this.arrayDiarios = response.data;
        this.util.closeLoading();
      },
      (err) => {
        debugger;
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Diários");
      }
    );
  }

}
