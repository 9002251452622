import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AjaxService } from "src/app/service/ajax.service";
import { UtilService } from "src/app/service/util.service";
import { API } from "src/environments/environment";

@Component({
  selector: "app-modal-cadastro-diario",
  templateUrl: "./modal-cadastro-diario.component.html",
  styleUrls: ["./modal-cadastro-diario.component.scss"],
})
export class ModalCadastroDiarioComponent implements OnInit {
  @Output() acaoVoltar = new EventEmitter();
  @Input() item: any;

  public pergunta: string;
  public tipo: string;
  public descricao: string;
  public peso: number;

  public perguntaOpcao: string;
  public confirmacaoInstrucao: string;
  public opcoes: any[];
  public opcoesConfirmacaoInstrucao: [];

  constructor(private ajax: AjaxService, public util: UtilService) {}

  ngOnInit() {
    this.limpar();
  }

  ngOnChanges() {
    this.preencheModuloEdit(this.item);
  }

  preencheModuloEdit(item) {
    console.log("dentro do modal");
    debugger;
    if (item != null) {
      this.pergunta = item.pergunta;
      this.descricao = item.descricao;
      this.confirmacaoInstrucao = item.confirmacaoInstrucao;
      this.tipo = item.tipo;
      this.opcoes = item.opcoes;
    } else {
      this.limpar();
    }
  }

  limpar() {
    this.pergunta = "";
    this.tipo = "M";
    this.descricao = "";
    this.peso = 0;

    this.perguntaOpcao = "";
    this.confirmacaoInstrucao = "";
    this.opcoes = [];
    this.opcoesConfirmacaoInstrucao = [];
    this.item = null;
    this.limparLinha();
  }

  mudarTipo() {
    this.limparLinha();
    this.opcoes = [];
  }

  validar(): boolean {
    if (this.pergunta == undefined || this.pergunta == "") {
      this.util.toasterShowError("Atenção", "Entre com o pergunta do diário");
      return false;
    }

    if (this.opcoes.length == 0) {
      this.util.toasterShowError("Atenção", "Entre com pelo menos uma opção");
      return false;
    }

    if (this.peso == null) {
      this.util.toasterShowError("Atenção", "Entre com um valor de peso válido");
      return false;
    }

    return true;
  }

  addLinha() {
    debugger
    let linha = {
      perguntaOpcao: this.perguntaOpcao,
      peso:  this.peso
    };

    if (this.tipo == "P") {
      linha["confirmacaoInstrucao"] = this.confirmacaoInstrucao;
      linha["opcoesConfirmacaoInstrucao"] = this.opcoesConfirmacaoInstrucao;
    }

    this.opcoes.push(linha);
    this.limparLinha();
  }

  limparLinha() {
    this.perguntaOpcao = "";
    this.peso = 0;
    this.confirmacaoInstrucao = "";
    this.opcoesConfirmacaoInstrucao = [];
  }

  removeOpcaoColuna(index) {
    this.opcoes.splice(index, 1);
  }

  montarJson() {
    var dados = {
      PERGUNTA: this.pergunta,
      DESCRICAO: this.descricao,
      TIPO: this.tipo
    };

    if(this.item != null)
      dados["ID_DIARIO"] = this.item.iD_DIARIO

    dados["OPCOES"] = this.opcoes;

    return dados;
  }

  salvar() {
    debugger;
    if (this.validar()) {
      let dados = this.montarJson();
      console.log(dados);
      let url = API + "Diario/Diario/salvar";
      this.ajax.post(url, dados).subscribe(
        (response) => {
          this.util.closeLoading();
          if (!response.hasErrors) {
            this.util.toasterShowSuccess("Atenção", "Módulo Salvo com Sucesso");
            this.limpar();
            this.voltar();
          } else {
            this.util.toasterShowError("Atenção", response.message);
          }
        },
        (err) => {
          debugger;
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Módulo");
        }
      );
    }
  }

  alterar() {
    if (this.validar()) {
      debugger
      let dados = this.montarJson();
      let url = API + "Diario/Diario/editar";
      this.ajax.post(url, dados).subscribe(
        (response) => {
          this.util.closeLoading();
          if (!response.hasErrors) {
            this.util.toasterShowSuccess("Atenção", "Módulo Salvo com Sucesso");
            this.limpar();
            this.voltar();
          } else {
            this.util.toasterShowError("Atenção", response.message);
          }
        },
        (err) => {
          debugger;
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Módulo");
        }
      );
    }
  }

  voltar() {
    this.acaoVoltar.emit();
  }
}
