import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-resultado',
  templateUrl: './resultado.component.html',
  styleUrls: ['./resultado.component.scss']
})
export class ResultadoComponent implements OnInit {

  public arrayTriagem: [];
  query: string;

  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.buscar();
    
  }

  buscar(){
    this.util.showLoading();
    let url = API + "Triagem/Triagem";  
    return this.ajax.get(url)
    .subscribe((resp: ResponseNet)  => {
      this.arrayTriagem = resp.data; 
      this.util.closeLoading();      
    }, err => {
      debugger
      this.util.closeLoading();
      this.util.toasterShowError("Atenção", "Erro ao Buscar Triagem");
    });
  }


  detalhe(item){
    this.router.navigate(['/detalhe-resultado', item.iD_TRIAGEM, item.titulo]);
  }

  aprovados(item){
    this.router.navigate(['/detalhe-resultado-aprovado', item.iD_TRIAGEM, item.titulo]);
  }

  exportarExcel(item){
    this.util.showLoading();
    var url = API + 'RespostaFormulario/RespostaFormulario/exportarResultadoTriagem/'+item.iD_TRIAGEM; 
    window.open(url); 
    this.util.closeLoading();
    /*return this.ajax.get(url)
    .subscribe(resp  => {   
      window.open(url); 
    }, erro => {
      debugger
      this.util.closeLoading();
      if(erro.status == 200){
        window.open(url);
      }else if(erro.status == 500){
        this.util.toasterShowWarning("Atenção", "Não existem dados");
      }
    });*/
  }

}
