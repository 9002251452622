import { PesquisaFormularioComponent } from './page/pesquisa-formulario/pesquisa-formulario.component';
import { CadastroPerguntaFormularioComponent } from './page/cadastro-pergunta-formulario/cadastro-pergunta-formulario.component';
import { CadastroFormularioComponent } from './page/cadastro-formulario/cadastro-formulario.component';
import { HomeProgramaComponent } from './page/home-programa/home-programa.component';
import { CadastroMidiaComponent } from './page/cadastro-midia/cadastro-midia.component';
import { EditarQuestaoComponent } from './page/editar-questao/editar-questao.component';
import { ConfigComponent } from './page/config/config.component';
import { EditComponent } from './page/edit/edit.component';
import { GComponent } from './page/g/g.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './page/login/login.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { LoggedInGuard } from './page/security/loggedin.quard';
import { HomeComponent } from './page/home/home.component';
import { CadastroUsuarioComponent } from './page/cadastro-usuario/cadastro-usuario.component';
import { CadastroTriagemComponent } from './page/cadastro-triagem/cadastro-triagem.component';
import { ProgramaComponent } from './page/programa/programa.component';
import { PesquisaTriagemComponent } from './page/pesquisa-triagem/pesquisa-triagem.component';
import { ResultadoComponent } from './page/resultado/resultado.component';
import { DetalheResultadoTriagemComponent } from './page/detalhe-resultado-triagem/detalhe-resultado-triagem.component';
import { CadastroTriagemNovoComponent } from './page/cadastro-triagem/cadastro-triagem-novo/cadastro-triagem-novo.component';
import { CadastroQuestoesComponent } from './page/cadastro-questoes/cadastro-questoes.component';
import { PesquisaQuestoesComponent } from './page/pesquisa-questoes/pesquisa-questoes.component';
import { CadastroGrupoQuestoesComponent } from './page/cadastro-grupo-questoes/cadastro-grupo-questoes.component';
import { CadastroTurmaComponent } from './page/cadastro-turma/cadastro-turma.component';
import { CadastroParticipanteComponent } from './page/cadastro-participante/cadastro-participante.component';
import { CadastroResponsavelComponent } from './page/cadastro-responsavel/cadastro-responsavel.component';
import { EditarTriagemComponent } from './page/editar-triagem/editar-triagem.component';
import { EditarFormularioComponent } from './page/editar-formulario/editar-formulario.component';
import { DetalheResultadoAprovadoComponent } from './page/detalhe-resultado-aprovado/detalhe-resultado-aprovado.component';
import { AvisoComponent } from './page/aviso/aviso.component';
import { EditarPerguntaComponent } from './page/editar-pergunta/editar-pergunta.component';
import { PesquisaPerguntaComponent } from './page/pesquisa-pergunta/pesquisa-pergunta.component';
import { CadastroModulosProgramaComponent } from './page/cadastro-modulos-programa/cadastro-modulos-programa.component';
import { CadastroDiarioComponent } from './page/cadastro-diario/cadastro-diario.component';
import { AdicionaDiarioEtapaComponent } from './page/adiciona-diario-etapa/adiciona-diario-etapa.component';
import { PushNotificationComponent } from './page/push-notification/push-notification.component';
import { SuporteComponent } from './page/suporte/suporte.component';
import { MeetingComponent } from './page/meeting/meeting.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'sample-page',
        loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      }
      
      , { path: 'cadastro-triagem-old', component: CadastroTriagemComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-programa', component: ProgramaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-turma', component: CadastroTurmaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-midia', component: CadastroMidiaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'resultados', component: ResultadoComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'avisos', component: AvisoComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'editar-triagem/:id', component: EditarTriagemComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'editar-formulario/:id', component: EditarFormularioComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'editar-questao/:id', component: EditarQuestaoComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'editar-pergunta/:id', component: EditarPerguntaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'detalhe-resultado/:id/:titulo', component: DetalheResultadoTriagemComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'detalhe-resultado-aprovado/:id/:titulo', component: DetalheResultadoAprovadoComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'home', component: HomeComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'home-p', component: HomeProgramaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'push-notification', component: PushNotificationComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'meeting', component: MeetingComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'suporte', component: SuporteComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-triagem', component: CadastroTriagemNovoComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-formulario', component: CadastroFormularioComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'pesquisa-triagem', component: PesquisaTriagemComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-questao', component: CadastroQuestoesComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-pergunta', component: CadastroPerguntaFormularioComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'pesquisa-questao', component: PesquisaQuestoesComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'pesquisa-pergunta', component: PesquisaPerguntaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'pesquisa-formulario', component: PesquisaFormularioComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-escala', component: CadastroGrupoQuestoesComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-participante', component: CadastroParticipanteComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-responsavel', component: CadastroResponsavelComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-modulos-programa', component: CadastroModulosProgramaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'cadastro-diario', component: CadastroDiarioComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }
      , { path: 'add-diario-etapa', component: AdicionaDiarioEtapaComponent, canLoad: [LoggedInGuard], canActivate: [LoggedInGuard] }

    ]
  }
  ,
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'cadastro-usuario', component: CadastroUsuarioComponent } ,
      { path: 'g/:p', component: GComponent }
    ]
  }/*,
  {
    path: '',
    component: AuthComponent,
    children: []
  }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
