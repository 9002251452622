import { formatDate } from '@angular/common';
import { AuthService } from 'src/app/service/auth.service';
import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/service/util.service';
import { AjaxService } from 'src/app/service/ajax.service';
import { Router } from '@angular/router';
import { ResponseUser } from 'src/app/model/User.model';
import { API, API_QRCODE, TIPOS } from 'src/environments/environment';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  user: ResponseUser;
  arrayProgramas: [];
  arrayAvisos: [];
  qtdPergunta: number;
  qtdRespostas: number;
  qtdProgramas: number;
  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService,
  ) { 
    this.qtdPergunta = 0;
    this.qtdRespostas = 0;
    this.qtdProgramas = 0;
  }

  ngOnInit() {
    this.user = this.auth.getUser();
    this.buscarProgramas();
    this.buscarAvisos();
    this.buscarPerguntasCadastradas();
    this.buscarRespostasCadastradas();
  }
  buscarProgramas() {
    this.util.showLoading();
    let url = API + "Programa/Programa";
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayProgramas = response.data;
        this.qtdProgramas = response.data.length;
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Programa");
      });
  }

  buscarAvisos() {
    this.util.showLoading();
    var hoje = new Date();
    var dd = String(hoje.getDate()).padStart(2, '0');
    var mm = String(hoje.getMonth() + 1).padStart(2, '0');
    var yyyy = hoje.getFullYear();

    let url = API + "Aviso/Aviso?STATUS=true&DATA_EVENTO=" + yyyy + "-" + mm + "-" + dd;
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayAvisos = response.data;
        console.log(this.arrayAvisos);
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Avisos");
      });
  }

  buscarPerguntasCadastradas() {
    this.util.showLoading();
    let url = API + "Pergunta/Pergunta?IS_RESUMIR_AGRUPAMENTOS=true";
    return this.ajax.get(url)
      .subscribe(response => {
        this.qtdPergunta = response.data.length;
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Avisos");
      });
  }

  buscarRespostasCadastradas() {
    this.util.showLoading();
    let url = API + "RespostaFormulario/RespostaFormulario/buscarQuantidadeRespostas";
    return this.ajax.get(url)
      .subscribe(response => {
        this.qtdRespostas = response.data;
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Respostas");
      });
  }


  modalAviso(item) {
    console.log(item);
    let date = new Date(item.datA_EVENTO)
    debugger
    let myhtml = `
    <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">${item.titulo}</h5>      
    </div>
    <div class="modal-body">
    ${item.descricao}
    </div>
    <div class="modal-footer">
      <p>Data Evento: ${date.toLocaleDateString()}</p>
    </div>
  </div>
    `;
    Swal.fire({
      width: "50%",
      html: myhtml,
      showCloseButton: true,
    });
  }


}
