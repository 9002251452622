<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Aguarde ...</p>
  </ngx-spinner>
  
  <div class="row">
    <div class="col-12">
      <app-card  [options]="false" [hidHeader]="true">
        <div class="row">
          <div class="col-sm-12 col-xl-4 ">
            <label class="sub-title">Filtro</label>
            <input type="text" class="form-control" [(ngModel)]="filtro">
          </div>
        </div>
  
  
  
      </app-card>
    </div>
    <div class="col-12">
      <app-card cardTitle="Responsável Cadastrado" blockClass="table-border-style" [options]="false">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Nome</th>
                <th>CPF</th>
                <th>RG</th>
                <th>Email</th>
                <th>Cidade</th>
                <th>Estado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of arrayResponsavel | fullTextSearch:filtro">
                <td>{{item.nome }}</td>
                <td>{{item.cpf }}</td>
                <td>{{item.rg }}</td>
                <td>{{item.email }}</td>
                <td>{{item.cidade }}</td>
                <td>{{item.estado }}</td>
                <td><button type="button" class="btn btn-icon btn-warning " (click)="resetarDispositivo(item)">
                  <i class="fa fa-check"></i> Liberar Dispositivo
                </button></td>
                <!--<td>
                  <button type="button" class="btn btn-icon btn-info " (click)="this.util.notImplement()">
                      <i class="fa fa-edit"></i>
                    </button>
                  &nbsp;&nbsp;
                  <button type="button" class="btn btn-icon btn-danger " (click)="desabilitar(item.id)">
                    <i class="fa fa-trash"></i>
                  </button>
  
                </td>-->
              </tr>
            </tbody>
          </table>
        </div>
      </app-card>
    </div>
  </div>
  