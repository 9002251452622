<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>


<div class="row btn-page ">
  <div class="col-md-3 barraLateral ">
    <div class="user-about-block text-center" style="margin-top: 130px;">
      <div class="row align-items-center">

        <div class="col-md-12" >
          <img class="img-radius img-fluid wid-150"  src="assets/images/logo/logo-form-resposta.png" style="background-color: aliceblue;">
        </div>


      </div>
    </div>
    <div class="text-center">
      <!--<h4 class="mb-1 mt-3 text-white">Responsável</h4>-->
      <!--<p class="mb-3 ">UI/UX Designer</p>-->
    </div>
  </div>
  <div class="col-md-7  filter-bar invoice-list corpo" *ngIf="!triagemValida">
    <app-card cardClass="trnasiction-card" [customHeader]="true" headerClass="bg-c-green" blockClass="text-center"
      [isCardFooter]="true" footerClass="border-top transection-footer p-0">
      <div class="app-card-header">
        <h3 class="text-white">Triagem Finalizada</h3>
        <div class="card-header-right">
          <div class="transection-preogress complited">
            <span class="fa fa-check f-20"></span>
          </div>
        </div>
      </div>
      <br><br>
      <h6>O período para resposta da triagem está expirado!</h6>

    </app-card>
  </div>
  <div class="col-md-7  filter-bar invoice-list corpo" *ngIf="triagemValida">
    <div class="row">

      <div class="col-md-12 col-sm-12">
        <br>
        <app-card [hidHeader]="true" cardClass="mb-0" blockClass="p-0" class="text-center">
          <img class="card-img-top" src="{{banner}}" />
          <p class="card-text" *ngIf="!(tab.boasVindas || tab.instrucao)">
            Orientações Sobre Formulário: <span [innerHTML]="orientacoesFormulario"></span>
          </p>
        </app-card>

        <app-card cardClass="card-border-c-blue" blockClass="card-task" [customHeader]="true">

          <div class="row" *ngIf="tab.boasVindas" style="padding:10px">

            <div class="text-center">
              <h2 >Bem-vindo(a) candidato!</h2>
              <p>
                Ficamos muito felizes com o seu interesse em  participar da nossa pesquisa de capacitação parental para
                '{{tituloPrograma}}'.
              </p>
            </div>

            <div class="text-left">
              <br><br>
              <h5 >Quem poderá participar?</h5>
              <br>
              <p>
                Familiar participante responsável de pessoas com TEA:
              </p>
              <p>
                <li>✔	Ensino Fundamental 1 completo.</li>
                <li>✔	60% Renda Familiar até 3 salários mínimos.</li>
                <li>✔	Acesso à smartphone.</li>
              </p>


              <p>
                Familiar participante com TEA:
              </p>
              <p>
                <li>✔	Idade 3 a 6 anos. </li>
                <li>✔	Diagnóstico TEA.</li>
                <li>✔	Sem acompanhamento ABA intensivo (menos de 10 hrs).</li>
                <li>✔	Comportamentos disruptivos.</li>
                <li>✔	Dificuldades de Comunicação</li>
              </p>
            </div>


            <div class="col-5 text-right"></div>
            <div class="col-2"></div>
            <div class="col-5 text-right">
              <button type="button" class="btn btn-warning sizeButton" style="margin-top:12px"
                (click)="selecionaTab('instrucao')">
                Li e Entendi <i class="feather icon-chevrons-right"></i> </button>
            </div>
          </div>

          <div class="row" *ngIf="tab.instrucao">


            <span [innerHTML]="instrucaoTriagem"></span>

            <div class="col-5 text-right"></div>
            <div class="col-2"></div>
            <div class="col-5 text-right">
              <button type="button" class="btn btn-warning sizeButton" style="margin-top:12px"
                (click)="selecionaTab('cadastro')">
                Vamos Lá <i class="feather icon-chevrons-right"></i> </button>
            </div>
          </div>

          <div class="row" *ngIf="tab.cadastro">
            <legend>&nbsp;Familiar Participante Responsável</legend>
            <div class="col-md-3">
              <label class="text-dark">CPF </label>
              <input type="text" class="form-control" [disabled]="this.responsavel.iD_RESPONSAVEL_TEA > 0"
                [textMask]="{mask: maskCPF}" [(ngModel)]="responsavel.cpf" name="responsavel.cpf"
                (blur)="procuraCpf()" />
            </div>
            <div class="col-md-3">
              <label class="text-dark">RG </label>
              <input type="text" class="form-control" [disabled]="this.responsavel.iD_RESPONSAVEL_TEA > 0"
                 [(ngModel)]="responsavel.rg" name="responsavel.rg" />
            </div>
            <div class="col-md-6">
              <label class="text-dark">Nome</label>
              <input type="text" class="form-control" [disabled]="this.responsavel.iD_RESPONSAVEL_TEA > 0"
                [(ngModel)]="responsavel.nome" name="responsavel.nome" />
            </div>

            <div class="col-md-6">
              <label class="text-dark">Email</label>
              <input type="text" class="form-control" [disabled]="this.responsavel.iD_RESPONSAVEL_TEA > 0"
                [(ngModel)]="responsavel.email" name="responsavel.email" />
            </div>
            <div class="col-md-4">
              <label class="text-dark">Telefone</label>
              <input type="text" class="form-control" [disabled]="this.responsavel.iD_RESPONSAVEL_TEA > 0"
                [textMask]="{mask: maskTelefoneCelular}" [(ngModel)]="responsavel.telefone"
                name="responsavel.telefone" />
            </div>

            <div class="col-md-3">
              <label class="text-dark">CEP</label>
              <input type="text" class="form-control" [disabled]="this.responsavel.iD_RESPONSAVEL_TEA > 0"
                [textMask]="{mask: maskCEP}" [(ngModel)]="responsavel.cep" name="responsavel.cep" (blur)="buscaCep()" />
            </div>
            <div class="col-md-7">
              <label class="text-dark">Rua</label>
              <input type="text" class="form-control" [disabled]="this.responsavel.iD_RESPONSAVEL_TEA > 0"
                [(ngModel)]="responsavel.rua" name="responsavel.rua" />
            </div>
            <div class="col-md-2">
              <label class="text-dark">Número</label>
              <input type="number" class="form-control" [disabled]="this.responsavel.iD_RESPONSAVEL_TEA > 0"
                [(ngModel)]="responsavel.numero" name="responsavel.numero" />
            </div>
            <div class="col-md-2">
              <label class="text-dark">Complemento</label>
              <input type="text" class="form-control" [disabled]="this.responsavel.iD_RESPONSAVEL_TEA > 0"
                [(ngModel)]="responsavel.complemento" name="responsavel.complemento" />
            </div>
            <div class="col-md-3">
              <label class="text-dark">Bairro</label>
              <input type="text" class="form-control" [disabled]="this.responsavel.iD_RESPONSAVEL_TEA > 0"
                [(ngModel)]="responsavel.bairro" name="responsavel.bairro" />
            </div>
            <div class="col-md-4">
              <label class="text-dark">Cidade</label>
              <input type="text" class="form-control" [disabled]="this.responsavel.iD_RESPONSAVEL_TEA > 0"
                [(ngModel)]="responsavel.cidade" name="responsavel.cidade" />
            </div>
            <div class="col-md-3">
              <label class="text-dark">Estado</label>
              <input type="text" class="form-control" [disabled]="this.responsavel.iD_RESPONSAVEL_TEA > 0"
                [(ngModel)]="responsavel.estado" name="responsavel.estado" />
            </div>
            <br><br> <br><br>
            <div class="col-md-4">
              <label class="text-dark">Cartão SUS</label>
              <input type="text" class="form-control" [disabled]="this.responsavel.iD_RESPONSAVEL_TEA > 0"
                [(ngModel)]="responsavel.cartaoSus" name="responsavel.cartaoSus" />
            </div>
            <div class="col-md-8">
              <label class="text-dark">Como ficou sabendo da Pesquisa</label>
              <!--<input type="text" class="form-control"  [disabled]="this.responsavel.iD_RESPONSAVEL_TEA > 0"  [(ngModel)]="responsavel.comoSoube" name="responsavel.comoSoube"/>-->
              <select class="form-control" [(ngModel)]="responsavel.comoSoube" name="responsavel.comoSoube">
                <option value="Redes Sociais">Redes Sociais</option>
                <option value="Indicacao de Pais">Indicacao de Pais</option>
                <option value="Indicacao Profissional">Indicacao Profissional</option>
                <option value="Faz Acompanhamento no Ambulátorio">Faz Acompanhamento no Ambulátorio</option>
              </select>
            </div>
            <legend style="margin-top:8px">&nbsp;Familiar Participante com TEA</legend>
            <div class="col-md-6">
              <label class="text-dark">Nome</label>
              <input type="text" class="form-control" [(ngModel)]="crianca.nome" name="crianca.nome" />
            </div>
            <div class="col-md-4">
              <label class="text-dark">Data Nascimento</label>
              <input type="date" class="form-control" [(ngModel)]="crianca.dataNascimento" name="crianca.dataNascimento"
                (change)="calculaData()"  (blur)="validaData()"/>
            </div>
            <div class="col-md-2">
              <label class="text-dark">Idade</label>
              <input type="number" class="form-control" [(ngModel)]="crianca.idade" name="crianca.idade" />
            </div>
            <div class="col-md-3">
              <label class="text-dark">Sexo</label>
              <select class="form-control" [(ngModel)]="crianca.sexo" name="crianca.sexo">
                <option value="Masculino">Masculino</option>
                <option value="Feminino">Feminino</option>
              </select>
            </div>
            <div class="col-md-3">
              <label class="text-dark">Cartao SUS</label>
              <input type="text" class="form-control" [(ngModel)]="crianca.cartaoSus" name="crianca.cartaoSus" />
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-4">
              <br>
              <label class="text-dark">Nível Suporte </label>
              <bar-rating [(rate)]="crianca.graU_TEA" [max]="4" [theme]="'movie'" [showText]="true"
                [titles]="['NÃO SEI', ' 1', ' 2' , ' 3']"></bar-rating>
            </div>
            <div class="col-md-6">
              <label class="text-dark">Estuda em qual tipo de Escola?</label>
              <select class="form-control" [(ngModel)]="crianca.tipoEscola" name="crianca.tipoEscola">
                <option value="Não Aplica">Não Aplica</option>
                <option value="Publica">Publica</option>
                <option value="Privada">Privada</option>
              </select>
            </div>
            <div class="col-md-6">
              <label class="text-dark">Turno Escolar</label>
              <select class="form-control" [(ngModel)]="crianca.turno" name="crianca.turno">
                <option value="Não Aplica">Não Aplica</option>
                <option value="Manha">Manha</option>
                <option value="Tarde">Tarde</option>
                <option value="Integral">Integral</option>
              </select>
            </div>

            <div class="col-5 text-right"></div>
            <div class="col-2"></div>
            <div class="col-5 text-right">
              <button type="button" class="btn btn-warning sizeButton" style="margin-top:12px"
                (click)="salvarCadastro()">
                Próximo <i class="feather icon-chevrons-right"></i> </button>
            </div>

          </div>
          <div *ngIf="tab.triagem">
            <div class="text-center">
              <span style="font-weight: bold;">Páginas {{paginaCorrente}} de {{totalPaginas}}</span>&nbsp;
            </div>
            <div class="row">
              <div class="col-md-12">

                <form [formGroup]="myFormGroup" id='frmEntradaDados' align="left">
                  <div *ngFor="let form_elem of perguntasFormTemplate">

                    <div [ngSwitch]="form_elem.type" class="pagina pagina_{{form_elem.page}} {{form_elem.showClass}}">
                      <div *ngIf="form_elem.showGroupName">

                        <br>
                        <label class="text-dark" id={{form_elem.group}}>{{form_elem.group}}</label>
                        <p class="text-muted">
                          <code>{{form_elem.descricao}} {{form_elem.textMask.mask}}</code> </p>
                        <br>
                      </div>

                      <div *ngSwitchCase="'text'" clas="row">

                        <label class="text-dark" id={{form_elem.id}}>{{form_elem.label}}</label>
                        <p *ngIf="form_elem.descricao null '' && form_elem.descricao != ''" class="text-muted">
                          <code>{{form_elem.descricao}} {{form_elem.textMask.mask}}</code> </p>
                        <input type="text" id="{{form_elem.id}}" class="{{form_elem.class}}"
                          [textMask]="{mask: form_elem.textMask.mask}" />

                      </div>

                      <div *ngSwitchCase="'select'" clas="row">

                        <label class="text-dark" for="{{form_elem.id}}">{{form_elem.label}}</label>
                        <p *ngIf="form_elem.descricao null '' && form_elem.descricao != ''" class="text-muted">
                          <code>{{form_elem.descricao}}</code>
                        </p>
                        <select id="{{form_elem.id}}" class="{{form_elem.class}}">
                          <option *ngFor="let val of form_elem.data" [value]="val.value">
                            {{val.value}}
                          </option>
                        </select>

                      </div>

                      <div *ngSwitchCase="'check'" clas="row">


                        <input type="checkbox" (click)="controlarClickCheckboxDinamico($event)"
                          value="{{form_elem.ponto}}" style="cursor: pointer;" id="{{form_elem.id}}"
                          class="{{form_elem.class}}" />
                        &nbsp;
                        <label id='{{form_elem.id}}_label' style="cursor: pointer;"
                          for="{{form_elem.id}}">{{form_elem.label}}</label>

                      </div>

                      <div *ngSwitchCase="'radio'" clas="row">
                        <br>
                        <label class="text-dark labelClass_{{form_elem.id}}">{{form_elem.label}}</label>
                        <p class="text-muted">
                          <code>{{form_elem.descricao}}</code>
                        </p>
                        <div *ngFor="let val of form_elem.data; let i = index;" [id]="form_elem.id">
                          <input type="radio" style="cursor: pointer;" id="{{form_elem.id}}_{{i}}"
                            class="{{form_elem.class}}" name="{{form_elem.id}}" value="{{val.value + '|'+ val.ponto}}"
                            (click)="controlarClickRadioDinamico($event, val)" />
                          &nbsp;
                          <label style="cursor: pointer;" for="{{form_elem.id}}_{{i}}">{{val.value}}</label>

                        </div>
                        <!--<input *ngIf="val.habilitar =='S' && val.click" type="text" class="form-control" />-->

                      </div>
                      <div *ngSwitchCase="'grade'" clas="row">

                        <div class='caixinhaAgrupadora'>
                          <br>
                          <label class="text-dark">{{form_elem.label}}</label>
                          <p class="text-muted">
                            <code>{{form_elem.descricao}}</code> </p>
                          <table>
                            <thead>
                              <tr *ngFor="let val of form_elem.data; let i = index;">
                                <th class="col-md-6"></th>
                                <th class="col-md-1 text-center" *ngFor="let valData of val.data; ">
                                  <span *ngIf="i == 0">{{valData.value}}</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let val of form_elem.data;" class="labelClass_{{val.id}}">
                                <td class="col-md-6">{{val.label}}</td>
                                <td class="col-md-1 text-center" *ngFor="let valData of val.data; let i = index;"
                                  [id]="val.id">
                                  <input type="radio" style="cursor: pointer;" id="{{val.id}}_{{i}}"
                                    class="{{val.class}}" name="{{val.id}}" value="{{valData.value}}" />
                                  &nbsp;
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <br>
                      </div>
                    </div>
                  </div>

                  <div class="row text-center"
                    *ngIf="mensagemCamposObrigatorios != undefined && mensagemCamposObrigatorios">
                    <div class="col-md-12">
                      <app-alert type="danger">{{mensagemCamposObrigatorios}}</app-alert>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-5 text-right">
                      <button type="button" class="btn btn-primary sizeButton" (click)="voltar()"
                        [disabled]="paginaCorrente == 1">
                        <i class="feather icon-chevrons-left"></i>
                        Anterior </button>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-5 text-left">
                      <button type="button" class="btn btn-warning sizeButton" (click)="avancar()"
                        *ngIf="paginaCorrente != totalPaginas">
                        Próximo <i class="feather icon-chevrons-right"></i> </button>

                      <button type="button" class="btn btn-dark sizeButton" (click)="salvarEntradaDados()"
                        *ngIf="paginaCorrente == totalPaginas">
                        <i class="feather icon-check-circle"></i> Enviar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="tab.laudo">


            <div class="col-sm-12 col-xl-12">
              <label class="sub-title">Caso tenha um laudo.</label>
              <file-upload id="fileCustom" [(ngModel)]="laudo"  name="laudo" [fileslimit]="1"  simple > sdf</file-upload>
            </div>

            <div class="col-sm-12 col-xl-12 text-right">
              <br>
              <button type="button" class="btn btn-success " style="padding:10px"
              (click)="salvarLaudo()">
              Enviar Laudo <i class="fa fa-cloud-upload-alt"></i>
             </button>
            </div>



            <div class="col-12 text-right">
              <br><br><br><br>
              <button type="button" class="btn btn-warning sizeButton" style="margin-top:12px"
                (click)="selecionaTab('triagem')">
                Proximo <i class="feather icon-chevrons-right"></i>
               </button>
            </div>
          </div>

          <div class="row" *ngIf="tab.final">

            <div class="text-center">
              <h4 >Obrigado por disponibilizar o seu tempo e nos ajudar com a nossa pesquisa. </h4>
              <p>
                Nossa equipe analisará seu cadastro e em breve daremos um retorno sobre sua inscrição.
              </p>
              <img src="assets/images/msg-obrigado.PNG" width="50%"/>
            </div>

          </div>

        </app-card>
      </div>


    </div>
  </div>

</div>
