<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class="col-sm-12 col-xl-12 ">
  <app-card cardTitle="Pergunta Original" [options]="false">
    <div class="col-md-3 "></div>
    <div class="col-md-6 ">
      <app-visualizar-perguntas *ngIf="perguntaOriginal" [pergunta]="perguntaOriginal" [mostrarObrigatorio]="false">
      </app-visualizar-perguntas>
    </div>
    <!--<app-visualizar-perguntas *ngIf="perguntaOriginal"  [pergunta]="perguntaOriginal"></app-visualizar-perguntas>-->
  </app-card>
</div>
<div class="col-sm-12 col-xl-12  " id="container">
  <app-card cardTitle="Edição de Perguntas" [options]="false">
    <div class="row">
      <div class="col-md-6 text-left">
        <div class="form-group">
          <div class="switch switch-success d-inline m-r-10">
            <input type="checkbox" id="switch-p-1" [(ngModel)]="perguntaOriginal.revisado" [disabled]="habilitarRevisado">
            <label for="switch-p-1" class="cr"></label>
          </div>
          <label>{{ perguntaOriginal.revisado ? 'REVISADO': 'NÃO REVISADO' }}</label>
        </div>
      </div>
      <div class="col-md-6 text-right">
        <span *ngIf="perguntaOriginal.revisado" class="badge badge-success">REVISADO</span>
        <span *ngIf="!perguntaOriginal.revisado" class="badge badge-danger">NÃO REVISADO</span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-xl-6 m-b-30">
        <label class="sub-title">Escolha o Instrumento/Escala</label>
        <ng-select [ngClass]="'tag-select'" [options]="arrayGrupoQuestoes" [(ngModel)]="grupoQuestaoSelecionado"
          [multiple]="true">
        </ng-select>
      </div>
      <div class="col-sm-12 col-xl-6">
        <label class="sub-title">Entre com as palavras chave <code>(Tags)</code> para facilitar sua
          busca.</label>
        <tag-input [ngClass]="'tag-select'" theme='bootstrap' [placeholder]="'+'" [(ngModel)]="arrayPalavrasChave"
          [addOnBlur]="true" [clearOnBlur]="true">
          <tag-input-dropdown></tag-input-dropdown>
        </tag-input>
        <br>
      </div>

    </div>
    <div class="row">
      <div class="col-sm-12 col-xl-6 m-b-30">
        <label class="sub-title">Estilo da questão</label>
        <select [(ngModel)]="tipoCampoSelecionado" name="tipoCampoSelecionado" (change)="limpaVisualizacao()" disabled
          class="form-control form-control-default">
          <option *ngFor="let item of arrayTipos" value={{item.id}}>
            {{item.descricao}}
        </select>
      </div>
      <div class="col-sm-12 col-xl-4 "
        *ngIf="tipoCampoSelecionado == tipo.Check || tipoCampoSelecionado == tipo.Radio || tipoCampoSelecionado == tipo.Grade">
        <label class="sub-title">É uma questão Eliminatória ou Classificatória? </label>
        <select [(ngModel)]="eliminatoriaClassificatoria" name="eliminatoriaClassificatoria"
          class="form-control form-control-default">
          <option value="N">Não se Aplica</option>
          <option value="E">Eliminatória</option>
          <option value="C">Classificatória</option>
        </select>
      </div>
      <div class="col-sm-12 col-xl-9">
        <label class="sub-title">Digite sua Pergunta </label>

        <input type="text" class="form-control" [(ngModel)]="campoLabel" name="campoLabel" placeholder="">
      </div>
      <div class="col-2"
        *ngIf="tipoCampoSelecionado != tipo.Check && tipoCampoSelecionado != tipo.Radio  && tipoCampoSelecionado != tipo.Grade ">
        <br>
        <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'C'">
          <input type="text" class="form-control form-control" [(ngModel)]="inputPontos" name="inputPontos"
            placeholder="ponto">
        </div>
        <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'E'">
          <select class="form-control form-control-default" [(ngModel)]="inputPontos">
            <option value="E">Elimina</option>
            <option value="C">Classifica</option>
          </select>
        </div>
      </div>

      <div class="col-sm-12 col-xl-12 m-b-30">
        <label class="sub-title">Digite uma Descrição </label>
        <textarea rows="3" class="form-control" [(ngModel)]="descricaoLabel" name="descricaoLabel"></textarea>

      </div>
      <div class="col-sm-12 col-xl-8" *ngIf="tipoCampoSelecionado == tipo.Selecao">
        <label class="sub-title">Digite a opção e aperte a tecla <code>ENTER</code></label>
        <tag-input [ngClass]="'tag-select'" theme='bootstrap' [placeholder]="'+'" [(ngModel)]="arraySelectOpcao"
          [addOnBlur]="true" [clearOnBlur]="true">
          <tag-input-dropdown></tag-input-dropdown>
        </tag-input>
        <br>
      </div>

      <div class="col-sm-12 col-xl-12" *ngIf="tipoCampoSelecionado == tipo.Check">

        <!--<div class="container" dragula="VAMPIRES" [(dragulaModel)]="arraySelectOpcao">-->
        <div class="container">
          <div class="form-group " *ngFor="let item of arraySelectOpcao; let i = index" >
            <div class="row " *ngIf="!item.remover">
              <div class="col-1"></div>
              <!--<div class="col-1">
                    <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormal"><i
                        class="fa fa-arrows-alt" ngbTooltip="Mover"></i>
                    </a>
 <div class="col-1"> <span style="padding-top: 25px;" >{{i}} -</span>  </div>
  <div class="col-1"> <br>{{i}} - </div>
                  </div>-->
              <div class="col-1" style="margin-top:18px;"> {{i}} - </div>
              <div class="col-5">

                <div class="checkbox">
                  <input type="checkbox" name="{{item.chave}}" id="{{item.chave}}">
                  <input type="text" class="form-control form-control" [(ngModel)]="item.chave" name="item.chave">
                  <!--<label for="{{item.chave}}" class="cr">{{item.chave}}</label>-->
                </div>
              </div>
              <div class="col-2">

                <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'C'">
                  <input type="text" class="form-control form-control" [(ngModel)]="item.ponto" name="item.ponto"
                    placeholder="ponto">
                </div>
                <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'E'">
                  <select class="form-control form-control-default" [(ngModel)]="item.ponto">
                    <option value="E">Elimina</option>
                    <option value="C">Classifica</option>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <div class="checkbox"
                  ngbTooltip="Neste campo irá habilitar um campo texto quando usuario selecionar esta opção">
                  <select class="form-control form-control-default" [(ngModel)]="item.habilitaCampo"
                    (change)="verificaSeEcondiciona(arraySelectOpcao)">
                    <option value="N">NÃO CONDICIONAL </option>
                    <option value="S">CONDICIONAL</option>
                  </select>
                </div>
              </div>
              <div class="col-1">
                <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                  (click)="removeOpcao(i)"><i class="fa fa-trash" ngbTooltip="Remover da Lista"></i>
                </a>
              </div>

              <!--<div class="col-6" *ngIf="item.habilitaCampo == 'S'"></div>
                  <div class="col-6" *ngIf="item.habilitaCampo == 'S'">
                    <input type="text" class="form-control form-control" [(ngModel)]="item.textoCampoCondicional"
                      name="item.textoCampoCondicional"
                      placeholder="Entre com o texto de instrução para preenchimento">
                  </div>-->

              <br><br>
            </div>


          </div>
        </div>

        <div class="row">
          <div class="col-12" *ngIf="habilitaCondicional">
            <label>Entre com o texto de instrução para preenchimento</label>
            <input type="text" class="form-control form-control" [(ngModel)]="inputPerguntaCondicional"
              name="inputPerguntaCondicional" placeholder="Entre com o texto de instrução para preenchimento">
          </div>
        </div>
        <br>
        <label class="sub-title">Digite sua Opção e clique no Botão +</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <button class="btn btn-primary" type="button" (click)="addOpcao()"><i class="fa fa-plus"></i></button>
          </div>
          <input type="text" class="form-control" placeholder="Digite sua opção e clique o botão +"
            aria-describedby="basic-addon1" [(ngModel)]="inputOpcoes" name="inputOpcoes">
        </div>
      </div>

      <div class="col-sm-12 col-xl-12" *ngIf="tipoCampoSelecionado == tipo.Radio">

        <div class="container" dragula="VAMPIRES" [(dragulaModel)]="arraySelectOpcao">


          <div class="form-group " *ngFor="let item of arraySelectOpcao; let i = index">
            <div class="row box">
              <div class="col-2"></div>
              <div class="col-1">
                <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormal"><i
                    class="fa fa-arrows-alt" ngbTooltip="Mover"></i>
                </a>

              </div>
              <div class="col-4">
                <div class="form-check">
                  {{i}} - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <input class="form-check-input" type="radio" name="gridRadios" id="{{item.chave}}"
                    value="{{item.chave}}">
                  <label class="form-check-label" for="gridRadios1">{{item.chave}}</label>
                </div>
              </div>
              <div class="col-2">

                <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'C'">
                  <input type="text" class="form-control form-control" [(ngModel)]="item.ponto" name="item.ponto"
                    placeholder="ponto">
                </div>
                <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'E'">
                  <select class="form-control form-control-default" [(ngModel)]="item.ponto">
                    <option value="E">Elimina</option>
                    <option value="C">Classifica</option>
                  </select>
                </div>
              </div>
              <div class="col-2">
                <div class="checkbox"
                  ngbTooltip="Neste campo irá habilitar um campo texto quando usuario selecionar esta opção">
                  <select class="form-control form-control-default" [(ngModel)]="item.habilitaCampo"
                    (change)="verificaSeEcondiciona(arraySelectOpcao)">
                    <option value="N">NÃO CONDICIONAL </option>
                    <option value="S">CONDICIONAL</option>
                  </select>
                </div>
              </div>
              <div class="col-1">
                <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                  (click)="removeOpcao(i)"><i class="fa fa-trash" ngbTooltip="Remover da Lista"></i>
                </a>
              </div>
              <!--<div class="col-6" *ngIf="item.habilitaCampo == 'S'"></div>
                  <div class="col-6" *ngIf="item.habilitaCampo == 'S'">
                    <input type="text" class="form-control form-control" [(ngModel)]="item.textoCampoCondicional"
                      name="item.textoCampoCondicional"
                      placeholder="Entre com o texto de instrução para preenchimento">
                  </div>-->

              <br><br>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12" *ngIf="habilitaCondicional">
            <label>Entre com o texto de instrução para preenchimento</label>
            <input type="text" class="form-control form-control" [(ngModel)]="inputPerguntaCondicional"
              name="inputPerguntaCondicional" placeholder="Entre com o texto de instrução para preenchimento">
          </div>
        </div>
        <br>

        <label class="sub-title">Digite sua Opção e clique no Botão +</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <button class="btn btn-primary" type="button" (click)="addOpcao()"><i class="fa fa-plus"></i></button>
          </div>
          <input type="text" class="form-control" placeholder="Digite sua opção e clique o botão +"
            aria-describedby="basic-addon1" [(ngModel)]="inputOpcoes" name="inputOpcoes">
        </div>
      </div>


      <div class="col-sm-12 col-xl-12" *ngIf="tipoCampoSelecionado == tipo.Grade">

        <div class="container" >


          <div class="form-group " *ngFor="let item of arraySelectOpcao; let i = index">
            <div class="row box" *ngIf="!item.remover">
              <div class="col-2"></div>
              <div class="col-1">
                <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormal"><i
                    class="fa fa-arrows-alt" ngbTooltip="Mover"></i>
                </a>

              </div>
              <div class="col-5">
                <div class="form-check">
                  {{i}} - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <input class="form-check-input" type="radio" name="gridRadios" id="{{item.chave}}"
                    value="{{item.chave}}">
                    <input type="text" class="form-control form-control" [(ngModel)]="item.chave" name="item.chave">
                </div>
              </div>
              <div class="col-2">

                <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'C'">
                  <input type="text" class="form-control form-control" [(ngModel)]="item.ponto" name="item.ponto"
                    placeholder="ponto">
                </div>
                <div class="checkbox" *ngIf="eliminatoriaClassificatoria == 'E'">
                  <select class="form-control form-control-default" [(ngModel)]="item.ponto">
                    <option value="E">Elimina</option>
                    <option value="C">Classifica</option>
                  </select>
                </div>
              </div>
              <div class="col-1">
                <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                  (click)="removeOpcao(i)"><i class="fa fa-trash" ngbTooltip="Remover da Lista"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6" align="center">
            <div class="custom-control custom-radio custom-control-inline"
              *ngFor="let item of arrayColunaMultiplaEscolha; let i = index">

              <input class="form-check-input" type="radio" name="gridRadios" id="{{item.chave}}" value="{{item.chave}}">

              <label class="form-check-label" for="gridRadios1">{{item.chave}} <br> pt. {{ item.ponto}}
              </label>&nbsp;&nbsp;
              <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                (click)="removeOpcaoColuna(i)" style="font-size: 15px; margin: 0px; padding:0px;">
                <i class="fa fa-trash" ngbTooltip="Remover coluna {{item.chave}}"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label class="sub-title">Adicionar Linha</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <button class="btn btn-primary" type="button" (click)="addLinha()"><i class="fa fa-plus"></i></button>
              </div>
              <input type="text" class="form-control" placeholder="Digite sua opção e clique o botão +"
                aria-describedby="basic-addon1" [(ngModel)]="inputLinhas" name="inputLinhas">
            </div>
          </div>
          <div class="col-md-4">
            <label class="sub-title">Adicionar Coluna</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <button class="btn btn-primary" type="button" (click)="addColuna()"><i class="fa fa-plus"></i></button>
              </div>
              <input type="text" class="form-control" placeholder="Digite sua opção e clique o botão +"
                aria-describedby="basic-addon1" [(ngModel)]="inputColunas" name="inputColunas">
            </div>
          </div>
          <div class="col-md-2">
            <label class="sub-title">&nbsp;&nbsp;</label>
            <div *ngIf="eliminatoriaClassificatoria == 'C'">
              <input type="text" class="form-control form-control" [(ngModel)]="inputPontos" name="inputPontos"
                placeholder="ponto">
            </div>
            <div *ngIf="eliminatoriaClassificatoria == 'E'">
              <select class="form-control form-control-default">
                <option value="E">Elimina</option>
                <option value="C">Classifica</option>
              </select>
            </div>

          </div>

        </div>


        <div class="col-md-12">
          <br>
          <div class="form-group " *ngFor="let item of arrayLinhasMultiplaEscolha; let i = index">
            <div class="row box" *ngIf="!item.remover">
              <div class="col-1">
                <a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormalDanger"
                  (click)="removeOpcaoLinhas(i)"><i class="fa fa-trash" ngbTooltip="Remover da Lista"></i>
                </a>
              </div>
              <div class="col-1" >
                <!--<a type="button" class="btn btn-icon btn-outline-danger buttonRadiusSemBordaTamNormal"><i
                        class="fa fa-arrows-alt" ngbTooltip="Mover"></i>
                    </a>-->
                    {{i}} -
              </div>
              <div class="col-4">
                <div class="form-check">                  
                  <input type="text" class="form-control form-control" [(ngModel)]="item.chave" name="item.chave">
                  <!--<label class="form-check-label" for="gridRadios1">{{item.chave}}</label>-->
                </div>
              </div>
              <div class="col-6" align="center">
                <div class="custom-control custom-radio custom-control-inline"
                  *ngFor="let item of arrayColunaMultiplaEscolha; let i = index">
                  <input class="form-check-input" type="radio" name="gridRadios" id="{{item.chave}}"
                    value="{{item.chave}}">
                  <label class="form-check-label" for="gridRadios1">{{item.chave}}</label>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>

    </div>

    <div class="row" align="center">
      <div class="col-sm-12 col-xl-12 m-b-30">
        <br>
        <button type="button" class="btn btn-icon btn-success " (click)="SalvarPergunta()">
          <i class="fa fa-plus"></i> SALVAR EDIÇÃO PERGUNTA
        </button>
      </div>
    </div>
  </app-card>
</div>
