import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { optionQuestao } from 'src/app/model/valorQuestao';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, maskCPF, maskTelefoneCelular, numberMaskInt, numberMaskReal, TIPOS_ENTRADA } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-editar-questao',
  templateUrl: './editar-questao.component.html',
  styleUrls: ['./editar-questao.component.scss']
})
export class EditarQuestaoComponent implements OnInit {

  public idPergunta: any;
  public perguntaOriginal: any;

  habilitarRevisado: boolean;

  tipo = TIPOS_ENTRADA;
  maskCPF = maskCPF;
  maskTelefoneCelular = maskTelefoneCelular;
  numberMaskInt = numberMaskInt;
  numberMaskReal = numberMaskReal;

  arrayProgramas: [];
  arrayGrupoQuestoes: [];
  programaSelecionado: any;
  grupoQuestaoSelecionado: any;
  arrayPalavrasChave: any[];

  arraySelectOpcao: optionQuestao[];

  arrayTipos: any;
  tipoCampoSelecionado: number;
  campoLabel: string;
  descricaoLabel: string;
  inputPontos: string;
  eliminatoriaClassificatoria: string;
  habilitaCondicional: boolean;

  arrayColunaMultiplaEscolha: optionQuestao[];
  arrayLinhasMultiplaEscolha: optionQuestao[];
  inputColunas: string;
  inputLinhas: string;
  inputPerguntaCondicional: string;
  inputOpcoes: string;

 

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) {
    this.idPergunta = this.activatedRoute.snapshot.params['id'];
    this.limparItem();

  }

  desabilitaTudo() {
    //document.getElementById("container").disabled = true; 
    $(document).ready(function () {
      $("#container").addClass("disable-div");
    });
  }

  ngOnInit() {
    this.util.showLoading();
    let url = API + "Pergunta/Pergunta?IS_RESUMIR_AGRUPAMENTOS=true&iD_PERGUNTA=" + this.idPergunta;
    return this.ajax.get(url)
      .subscribe(response => {
        this.perguntaOriginal = response.data[0];
        console.log('Pergunta Original', this.perguntaOriginal);
        this.util.closeLoading();
        this.preencherDados();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Perguntas");
      });
  }

  limparItem() {
    this.campoLabel = "";
    this.arraySelectOpcao = [];
    this.descricaoLabel = "";
    this.inputColunas = "";
    this.inputLinhas = "";
    this.inputOpcoes = "";
    this.arrayColunaMultiplaEscolha = [];
    this.arrayLinhasMultiplaEscolha = [];
    this.inputPontos = "";
    this.eliminatoriaClassificatoria = "N";
    this.arrayPalavrasChave = [];
    this.inputPerguntaCondicional = "";
    this.habilitaCondicional = false;
    this.habilitarRevisado = true;
    //this.desabilitaForm();
  }

  preencheTag(): any[] {
    let arraTag = this.perguntaOriginal.tag != null && this.perguntaOriginal.tag != "" ? this.perguntaOriginal.tag.split(',') : [];
    for (var i = 0; i < arraTag.length; i++) {
      arraTag[i] = { value: arraTag[i], display: arraTag[i] };
    }
    return arraTag;
  }

  preencheSelect(): any[] {
    //arraySelectOpcao
    let arraSelect = this.perguntaOriginal.valores != null && this.perguntaOriginal.valores != "" ? this.perguntaOriginal.valores.split('|') : [];
    for (var i = 0; i < arraSelect.length; i++) {
      arraSelect[i] = { value: arraSelect[i], display: arraSelect[i] };
    }
    return arraSelect;
  }

  preencherDados() {
    this.buscarGruposCadastrados();

    this.arrayPalavrasChave = this.preencheTag(); //this.perguntaOriginal.tag != null && this.perguntaOriginal.tag != "" ? this.perguntaOriginal.tag.split(',') : [];
    this.eliminatoriaClassificatoria = this.perguntaOriginal.eliminA_CLASSIFICA;
    this.campoLabel = this.perguntaOriginal.titulo;
    this.descricaoLabel = this.perguntaOriginal.descricao;
    this.habilitarRevisado = this.perguntaOriginal.revisado;

    if (this.habilitarRevisado)
      this.desabilitaTudo();

    this.buscarTipos();

    if (this.perguntaOriginal.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Selecao) {

      this.arraySelectOpcao = this.preencheSelect();

    } else if (this.perguntaOriginal.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Radio) {


      debugger
      let arrSelect = this.perguntaOriginal.valores.split('|');
      let arrPonto = this.perguntaOriginal.ponto != null ? this.perguntaOriginal.ponto.split('|') : [];

      this.arraySelectOpcao = [];
      for (var i = 0; i < arrSelect.length; i++) {
        this.arraySelectOpcao.push({ chave: arrSelect[i], ponto: "", habilitaCampo: "N", textoCampoCondicional: "" });
      };
      for (var i = 0; i < arrPonto.length; i++) {
        this.arraySelectOpcao[i].ponto = arrPonto[i];
      };

    }
    else if (this.perguntaOriginal.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Check) {


      this.arraySelectOpcao = [];
      this.perguntaOriginal.detalhesValores.forEach(item => {
        this.arraySelectOpcao.push({
          chave: item.titulo,
          descricao: item.descricao,
          ponto: item.ponto,
          eliminaClassifica: item.eliminA_CLASSIFICA,
          habilitaCampo: item.habilitA_CAMPO,
          textoCampoCondicional: item.textO_CAMPO_CONDICIONAL,
          idPergunta: item.iD_PERGUNTA,
          idPerguntaPai: item.iD_PERGUNTA_PAI,
          agrupamento: item.agrupamento,
          tag: item.tag,
          idPerguntaAgrupamento: item.iD_PERGUNTA_AGRUPAMENTO != null ? parseInt(item.iD_PERGUNTA_AGRUPAMENTO) : null,
          remover: false
        });
      });
    }
    else if (this.perguntaOriginal.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Grade) {

      debugger
      this.arrayColunaMultiplaEscolha = [];
      let colunas = this.perguntaOriginal.filhos[0].valores.split('|');
      let pontos = this.perguntaOriginal.filhos[0].ponto.split('|');
      this.arrayLinhasMultiplaEscolha = [];

      this.perguntaOriginal.filhos.forEach(item => {
        this.arrayLinhasMultiplaEscolha.push({
          chave: item.titulo,
          descricao: item.descricao,
          ponto: item.ponto,
          eliminaClassifica: item.eliminA_CLASSIFICA,
          habilitaCampo: item.habilitA_CAMPO,
          textoCampoCondicional: item.textO_CAMPO_CONDICIONAL,
          idPergunta: item.iD_PERGUNTA,
          idPerguntaPai: item.iD_PERGUNTA_PAI,
          agrupamento: item.agrupamento,
          tag: item.tag,
          idPerguntaAgrupamento: item.iD_PERGUNTA_AGRUPAMENTO != null ? parseInt(item.iD_PERGUNTA_AGRUPAMENTO) : null,
          remover: false,
          valores: item.valores,
        });
      });
      /*for (var i = 0; i < this.perguntaOriginal.filhos.length; i++) {
        this.arrayLinhasMultiplaEscolha.push(this.perguntaOriginal.filhos[i].titulo);
      };*/


      for (var i = 0; i < colunas.length; i++) {
        this.arrayColunaMultiplaEscolha.push({ chave: colunas[i], ponto: "", habilitaCampo: "N", textoCampoCondicional: "" });
      };

      for (var i = 0; i < pontos.length; i++) {
        this.arrayColunaMultiplaEscolha[i].ponto = pontos[i];
      };



    }

  }

  addColuna() {
    if (this.inputColunas != undefined && this.inputColunas != "") {
      this.arrayColunaMultiplaEscolha.push({ chave: this.inputColunas, ponto: this.inputPontos, habilitaCampo: "N", textoCampoCondicional: "" });
      this.inputColunas = "";
    } else {
      this.util.toasterShowWarning("Atenção", "Digite uma opção válida!");
    }
  }

  addLinha() {
    if (this.inputLinhas != undefined && this.inputLinhas != "") {
      //this.arrayLinhasMultiplaEscolha.push(this.inputLinhas);
      this.arrayLinhasMultiplaEscolha.push({
        chave: this.inputLinhas,
        ponto: "0",
        habilitaCampo: this.arrayLinhasMultiplaEscolha[0].habilitaCampo,
        textoCampoCondicional: this.arrayLinhasMultiplaEscolha[0].textoCampoCondicional,
        eliminaClassifica: this.arrayLinhasMultiplaEscolha[0].eliminaClassifica,
        idPergunta: 0,
        descricao: this.arrayLinhasMultiplaEscolha[0].descricao,
        agrupamento: this.arrayLinhasMultiplaEscolha[0].agrupamento,
        tag: this.arrayLinhasMultiplaEscolha[0].tag,
        idPerguntaAgrupamento: this.arrayLinhasMultiplaEscolha[0].idPerguntaAgrupamento,
        remover: false,
        idPerguntaPai: this.arrayLinhasMultiplaEscolha[0].idPerguntaPai,
      });
      this.inputLinhas = "";
    } else {
      this.util.toasterShowWarning("Atenção", "Digite uma opção válida!");
    }
  }

  verificaSeEcondiciona(arrayItens: any[]): boolean {
    let existe = arrayItens.find(c => c.habilitaCampo == "S");
    this.habilitaCondicional = existe == undefined ? false : true;
    return this.habilitaCondicional
  }

  removeOpcao(index) {
    //this.arraySelectOpcao.splice(index, 1);
    this.arraySelectOpcao[index].remover = true;
  }

  removeOpcaoLinhas(index) {
    //this.arrayLinhasMultiplaEscolha.splice(index, 1);
    this.arrayLinhasMultiplaEscolha[index].remover = true;
  }

  removeOpcaoColuna(index) {
    this.arrayColunaMultiplaEscolha.splice(index, 1);
  }

  addOpcao() {
    debugger
    if (this.inputOpcoes != undefined && this.inputOpcoes != "") {
      this.arraySelectOpcao.push({
        chave: this.inputOpcoes,
        ponto: "0",
        habilitaCampo: this.arraySelectOpcao[0].habilitaCampo,
        textoCampoCondicional: this.arraySelectOpcao[0].textoCampoCondicional,
        eliminaClassifica: this.arraySelectOpcao[0].eliminaClassifica,
        idPergunta: 0,
        descricao: this.arraySelectOpcao[0].descricao,
        agrupamento: this.arraySelectOpcao[0].agrupamento,
        tag: this.arraySelectOpcao[0].tag,
        idPerguntaAgrupamento: this.arraySelectOpcao[0].idPerguntaAgrupamento,
        remover: false,
        idPerguntaPai: this.arraySelectOpcao[0].idPerguntaPai,
      });

      this.inputOpcoes = "";
    } else {
      this.util.toasterShowWarning("Atenção", "Digite uma opção válida!");
    }
  }

  buscarTipos() {
    this.util.showLoading();
    var url = API + 'TipoCampoFormulario/TipoCampoFormulario';
    this.ajax.get(url)
      .subscribe(resp => {
        console.log(resp);
        this.arrayTipos = resp.data;
        this.tipoCampoSelecionado = this.perguntaOriginal.iD_TIPO_CAMPO_FORMULARIO;
        this.util.closeLoading();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao buscar Dados");
      });
  }

  limpaVisualizacao() {
    this.arraySelectOpcao = [];
    this.arrayColunaMultiplaEscolha = [];
    this.arrayLinhasMultiplaEscolha = [];
  }

  buscarGruposCadastrados() {
    this.util.showLoading();
    let url = API + "TipoClassificacaoPergunta/TipoClassificacaoPergunta";
    return this.ajax.get(url)
      .subscribe(response => {
        //this.arrayProgramas = this.util.convertIoptions(response.data, "iD_PROGRAMA", "descricao");
        this.arrayGrupoQuestoes = this.util.convertIoptions(response.data, "id", "descricao");
        console.log(this.arrayGrupoQuestoes);
        this.grupoQuestaoSelecionado = this.perguntaOriginal.iD_GRUPO_DESCRICAO;
        this.util.closeLoading();
      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Instrumentos/Escala");
      });
  }

  justaValoresRadio(array: optionQuestao[]): string {
    let valores = "";
    array.forEach(element => { valores += element.chave + "|" });
    return valores.substring(0, valores.length - 1);
  }

  getTag(): string {

    let tags = "";
    this.arrayPalavrasChave.forEach(item => { tags += item.value + "," });
    if (tags.length > 0)
      return tags.substring(0, tags.length - 1);

    return tags;
  }

  getSelect(): string {

    let tags = "";
    this.arrayPalavrasChave.forEach(item => { tags += item.value + "," });
    if (tags.length > 0)
      return tags.substring(0, tags.length - 1);

    return tags;
  }

  ajustaValorSelecao(): any[] {

    let valores = [];
    for (let item of this.arraySelectOpcao) {
      valores.push({ chave: item.value, ponto: "" });
    }
    return valores;
  }

  pegaPonto(array: optionQuestao[]): string {
    let pontos = "";
    array.forEach(element => { pontos += element.ponto + "|" });
    return pontos.substring(0, pontos.length - 1);
  }

  pegaHabilitaCampo(array: optionQuestao[]): string {
    let hab = "";
    array.forEach(element => { hab += element.habilitaCampo + "|" });
    return hab.substring(0, hab.length - 1);
  }

  pegaTextoCampoCondicional(array: optionQuestao[]): string {
    let hab = "";
    array.forEach(element => { hab += element.textoCampoCondicional + "|" });
    return hab.substring(0, hab.length - 1);
  }

  justaValoresDetalhe(array: any[]): string[] {
    let arrayGo = [];
    array.forEach(element => { arrayGo.push({ chave: element, ponto: "" }) });
    return arrayGo;
  }

  montarDetalheValores(): any[] {

    let detalhes = [];

    if (this.perguntaOriginal.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Check) {

      for (let i = 0; i < this.arraySelectOpcao.length; i++) {
        detalhes.push({
          iD_PERGUNTA: this.arraySelectOpcao[i].idPergunta,
          titulo: this.arraySelectOpcao[i].chave,
          descricao: this.descricaoLabel,
          valores: null,
          ponto: this.arraySelectOpcao[i].ponto,
          eliminA_CLASSIFICA: this.arraySelectOpcao[i].eliminaClassifica,
          ordem: i + 1,
          habilitA_CAMPO: this.arraySelectOpcao[i].habilitaCampo,
          textO_CAMPO_CONDICIONAL: this.arraySelectOpcao[i].textoCampoCondicional,
          agrupamento: this.campoLabel, //titulo
          tag: this.getTag(),
          ID_PERGUNTA_AGRUPAMENTO: this.arraySelectOpcao[i].idPerguntaAgrupamento,
          ID_PERGUNTA_PAI: this.arraySelectOpcao[i].idPerguntaPai,
          REMOVER: this.arraySelectOpcao[i].remover
        });
      }

    } else if (this.perguntaOriginal.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Grade) {

      for (let i = 0; i < this.arrayLinhasMultiplaEscolha.length; i++) {
        detalhes.push({
          iD_PERGUNTA: this.arrayLinhasMultiplaEscolha[i].idPergunta,
          titulo: this.arrayLinhasMultiplaEscolha[i].chave,
          descricao: this.descricaoLabel,
          valores: this.justaValoresRadio(this.arrayColunaMultiplaEscolha),
          ponto: this.pegaPonto(this.arrayColunaMultiplaEscolha),
          eliminA_CLASSIFICA: this.arrayLinhasMultiplaEscolha[i].eliminaClassifica,
          ordem: i + 1,
          habilitA_CAMPO: this.arrayLinhasMultiplaEscolha[i].habilitaCampo,
          textO_CAMPO_CONDICIONAL: this.arrayLinhasMultiplaEscolha[i].textoCampoCondicional,
          //agrupamento: this.campoLabel, //titulo
          tag: this.getTag(),
          ID_PERGUNTA_AGRUPAMENTO: this.arrayLinhasMultiplaEscolha[i].idPerguntaAgrupamento,
          ID_PERGUNTA_PAI: this.arrayLinhasMultiplaEscolha[i].idPerguntaPai,
          REMOVER: this.arrayLinhasMultiplaEscolha[i].remover
        });
      }
      console.log('arrayColunaMultiplaEscolha', this.arrayColunaMultiplaEscolha);
      console.log('arrayLinhasMultiplaEscolha', this.arrayLinhasMultiplaEscolha);
      console.log('detalhes', detalhes);
    }

    return detalhes;
  }


  montarSalvar() {

    let dados = [{
      "iD_PERGUNTA": this.perguntaOriginal.iD_PERGUNTA,
      "titulo": this.campoLabel,
      "descricao": this.descricaoLabel,
      "iD_TIPO_CLASSIFICACAO_PERGUNTA": this.grupoQuestaoSelecionado,
      "tag": this.getTag(),
      "eliminA_CLASSIFICA": this.eliminatoriaClassificatoria,
      "iD_TIPO_CAMPO_FORMULARIO": this.perguntaOriginal.iD_TIPO_CAMPO_FORMULARIO,
      "agrupamento": this.perguntaOriginal.agrupamento,
      "iD_PERGUNTA_PAI": this.perguntaOriginal.iD_PERGUNTA_PAI,
      "TITULO_PAI": this.campoLabel,
      "revisado": this.perguntaOriginal.revisado

    }];

    debugger
    if (this.perguntaOriginal.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Selecao) {
      dados[0]['valores'] = this.ajustaValorSelecao();
    }
    else if (this.perguntaOriginal.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Radio) {
      dados[0]['VALOR'] = this.justaValoresRadio(this.arraySelectOpcao);
      dados[0]['PONTO'] = this.pegaPonto(this.arraySelectOpcao);
      dados[0]['HABILITA_CAMPO'] = this.pegaHabilitaCampo(this.arraySelectOpcao);
      dados[0]['TEXTO_CAMPO_CONDICIONAL'] = this.inputPerguntaCondicional;
    }
    else if (
      this.perguntaOriginal.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Check ||
      this.perguntaOriginal.iD_TIPO_CAMPO_FORMULARIO == this.tipo.Grade) {
      dados[0]['detalhesValores'] = this.montarDetalheValores();
    }


    return dados;

  }

  SalvarPergunta() {


    debugger
    let date = this.montarSalvar();
    console.log(date);

    let url = API + "Pergunta/Pergunta/T";
    this.util.showLoading();
    return this.ajax.put(url, date)
      .subscribe((resp: ResponseNet) => {
        this.ngOnInit();
        this.util.closeLoading();
        if (!resp.hasErrors)
          this.util.toasterShowSuccess("Sucesso", "Alterado com sucesso!");
        else
          this.util.toasterShowError("Erro", resp.message);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.messageError(err);
      });
  }

}
