import { BASE_API, IMG_PADRAO, maskCEP, REDIRECT_SITE } from './../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Qrcode } from 'src/app/model/Qrcode';
import { Pergunta } from 'src/app/model/Pergunta';
import { RequestGetPerguntas } from 'src/app/model/RequestGetPerguntas';
import { AjaxService } from 'src/app/service/ajax.service';
import { UtilService } from 'src/app/service/util.service';
import { API, ehDesenvolvimento, maskCPF, maskTelefoneCelular, numberMaskInt, numberMaskReal } from 'src/environments/environment';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { Pipe, PipeTransform } from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { DomSanitizer } from '@angular/platform-browser';
import { DadosCartao } from 'src/app/model/CartaoVisita';
import { FormControl, FormGroup } from '@angular/forms';
import { GeoLocation } from 'src/app/model/geoLocation';
import { Crianca } from 'src/app/model/Crianca';
import { Responsavel } from 'src/app/model/ResponsavelTea';
import { HttpClient } from '@angular/common/http';
import { ResponseNet } from 'src/app/model/ResponseNet.model';

@Component({
  selector: 'app-g',
  templateUrl: './g.component.html',
  styleUrls: ['./g.component.scss']
})
export class GComponent implements OnInit {


  public maskCPF = maskCPF;
  public maskTelefoneCelular = maskTelefoneCelular;
  public maskCEP = maskCEP;
  public myFormGroup: FormGroup;
  public perguntasFormTemplate = [];
  public mensagemCamposObrigatorios: string;
  public isExibirBotaoSalvar: boolean = true;
  public contadorRedirect: number = 3;
  public totalPaginas: number;
  public banner: string;
  public triagemValida: boolean;
  public paginaCorrente: number;
  public aconteceuAlteracaoDePagina: boolean = false;
  param: any;
  objPerguntas: Pergunta[];
  linkArquivo: string;
  mensagemErro: string;
  ps: string;
  public redirect_site = REDIRECT_SITE;
  public orientacoesFormulario: string;
  arrayOriginalDados: any[];

  public instrucaoTriagem: string;
  public tituloPrograma: string;
  public laudo: File;

  dadosTemplateEntradaResposta: any[];
  //public cadastro: boolean;
  public responsavel: Responsavel;
  public crianca: Crianca;
  public tab: { boasVindas: boolean, instrucao: boolean, cadastro: boolean, triagem: boolean, laudo: boolean, final: boolean };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ajax: AjaxService,
    private util: UtilService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,

  ) {
    this.limpar();
  }

  limpar() {
    this.tituloPrograma = "";
    this.instrucaoTriagem = "";
    this.responsavel = new Responsavel(null, "", "", "", "", "", "", 0, "", "", "", "", null, this.util);
    this.crianca = new Crianca(null, "", 0, "", 1, null, null, "", this.util, "Não Aplica", "Não Aplica");
    this.tab = { boasVindas: true, instrucao: false, cadastro: false, triagem: false, laudo: false, final: false };
  }

  ngOnInit() {

    this.myFormGroup = new FormGroup({});
    this.param = this.activatedRoute.snapshot.params['p'];
    this.getPerguntas();
    this.mensagemErro = "";
    this.ps = "";
    this.triagemValida = true;
    this.paginaCorrente = 1;
    this.totalPaginas = 0;
    //this.cadastro = ehDesenvolvimento ? false : true;
    //this.cadastro = true;
    
    
    this.selecionaTab("boasVindas");
    //this.selecionaTab("laudo");

  }

  selecionaTab(item: string) {
    switch (item) {
      case "boasVindas": {
        this.tab = { boasVindas: true, instrucao: false, cadastro: false, triagem: false, laudo: false, final: false };
        break;
      }
      case "instrucao": {
        this.tab = { boasVindas: false, instrucao: true, cadastro: false, triagem: false, laudo: false, final: false };
        break;
      }
      case "cadastro": {
        this.tab = { boasVindas: false, instrucao: false, cadastro: true, triagem: false, laudo: false, final: false };
        break;
      }
      case "triagem": {
        this.tab = { boasVindas: false, instrucao: false, cadastro: false, triagem: true, laudo: false, final: false };
        let av = this;
              setTimeout(function () {
                av.avancar(false);
                av.voltar(false);
              }, 50);
        break;
      }
      case "laudo": {
        this.tab = { boasVindas: false, instrucao: false, cadastro: false, triagem: false, laudo: true, final: false };       
        break;
      }
      case "final": {
        this.tab = { boasVindas: false, instrucao: false, cadastro: false, triagem: false, laudo: false, final: true };
        break;
      }
    }

  }


  salvarLaudo() {

    debugger
    if (this.laudo != undefined) {
      let dadosQrcode = {
        "ID_CRIANCA": this.crianca.iD_CRIANCA,
        "Arquivo": this.laudo[0]
      }

      let url = API + "Crianca/Crianca/SalvarLaudo";
      let dados = this.util.jsonToFormData(dadosQrcode);

      this.util.showLoading();
      this.ajax.postFile(url, dados)
        .subscribe((resp: ResponseNet) => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowSuccess("Atenção", "Laudo Enviado com Sucesso");
          this.selecionaTab("triagem");

        }, err => {
          debugger
          this.util.closeLoading();
          this.util.messageError(err);
        });

    } else {
      this.util.toasterShowError("Atenção", "Entre com o arquivo antes de tentar enviar!");
    }




    debugger
    /*let url = API + "Triagem/Triagem/T";
    let dados = this.util.jsonToFormData(dadosQrcode);

    this.util.showLoading();
    this.ajax.postFile(url, dados)
      .subscribe((resp: ResponseNet) => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Cabeçalho atualizado com sucesso!");
        this.habilitarRevisado = true;

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.messageError(err);
      });*/

  }

  buscaCep() {
    let url = "https://viacep.com.br/ws/" + this.util.removeMask(this.responsavel.cep) + "/json";
    this.util.showLoading();
    //return this.http.get<any>(url, this.options);
    this.http.get<any>(url)
      .subscribe(response => {
        console.log(response);
        this.util.closeLoading();

        if (response.erro == undefined) {
          this.responsavel.rua = response.logradouro;
          this.responsavel.numero = response.numero;
          this.responsavel.complemento = response.complemento;
          this.responsavel.bairro = response.bairro;
          this.responsavel.cidade = response.localidade;
          this.responsavel.estado = response.uf;
        }
      }, err => {
        this.util.closeLoading();
      });
  }

  procuraCpf() {
    let url = API + "ResponsavelTea/ResponsavelTea/ConsultarPorCpf?CPF=" + this.util.removeMask(this.responsavel.cpf);
    this.util.showLoading();
    this.ajax.get(url)
      .subscribe(response => {
        console.log(response);
        this.util.closeLoading();

        debugger
        //if (response.data.length > 0) {
        this.responsavel.iD_RESPONSAVEL_TEA = response.data.iD_RESPONSAVEL_TEA;
        this.responsavel.nome = response.data.nome;
        this.responsavel.email = response.data.email;
        this.responsavel.telefone = response.data.telefone;
        this.responsavel.cep = response.data.cep;
        this.responsavel.rua = response.data.rua;
        this.responsavel.numero = response.data.numero;
        this.responsavel.complemento = response.data.complemento;
        this.responsavel.bairro = response.data.bairro;
        this.responsavel.cidade = response.data.cidade;
        this.responsavel.estado = response.data.estado;
        //}
      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Procurar Responsavel");
      });
  }


  salvarCadastro() {
    debugger;
    this.responsavel.iD_TRIAGEM = parseInt(this.param);
    this.crianca.iD_TRIAGEM = parseInt(this.param);
    if (this.responsavel.validar() && this.crianca.validar()) {

      let dados = this.responsavel.convertJson();      
      
      let url = API + "ResponsavelTea/ResponsavelTea";
      this.util.showLoading();
      this.ajax.post(url, dados)
        .subscribe(response => {

          this.responsavel.iD_RESPONSAVEL_TEA = response.data.iD_RESPONSAVEL_TEA;
          this.crianca.iD_RESPONSAVEL_TEA = response.data.iD_RESPONSAVEL_TEA;

          let dados = this.crianca.montarJson();
          let url = API + "Crianca/Crianca";
          this.ajax.post(url, dados)
            .subscribe(response => {

              this.crianca.iD_CRIANCA = response.data.iD_CRIANCA;
              console.log(response);
              this.util.closeLoading();
              //this.cadastro = false;
              this.selecionaTab("laudo")
              let av = this;
              setTimeout(function () {
                av.avancar(false);
                av.voltar(false);
              }, 50);
            }, err => {

              this.util.closeLoading();
              this.util.toasterShowError("Atenção", "Erro ao Salvar Cadastro");
            });

        }, err => {

          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Salvar Cadastro");
        });

    }
  }


  tiposEntrada = {
    TextoLivre: '1'
    , Telefone: '2'
    , Cpf: '3'
    , Rg: '4'
    , Email: '5'
    , Moeda: '6'
    , Inteiro: '7'
    , Selecao: '8'
    , Check: '9'
    , Radio: '10'
    , Grade: '12'
  };



  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  acaoPage(perguntas: any[]) {
    this.viewTipoEntradaDeDados(perguntas);
  }

  calculaData() {
    console.log('calculou', this.crianca.dataNascimento);
    let arrayData = this.crianca.dataNascimento.split('-');
    let idade = this.util.calculaIdade(parseInt(arrayData[0]), parseInt(arrayData[1]), parseInt(arrayData[2]));
    this.crianca.idade = idade;
  }

  validaData() {
    if(!this.util.dateIsValid(this.crianca.dataNascimento)){
      this.util.toasterShowError("Atenção", "Revise a Data de Nascimento, ela esta em um formato inválido");
    }
  }


  getPerguntas() {
    if (this.param == undefined || this.param == "") {
      this.util.toasterShowError("Atenção", "Parametro inválido");
    } else {
      this.util.showLoading();
      var url = API + 'CampoFormularioTriagem/CampoFormularioTriagem/' + this.param + "/true";

      this.ajax.get(url)
        .subscribe((resp: any) => {
          ;
          console.log(resp.data);
          this.totalPaginas = resp.data.numeroDePaginas;
          this.arrayOriginalDados = resp.data;
          this.banner = resp.data.banner != null ? BASE_API + "/" + resp.data.banner : IMG_PADRAO;
          this.triagemValida = resp.data.triagemValida;
          this.tituloPrograma = resp.data?.programa;
          this.instrucaoTriagem = resp.data?.instrucao;

          this.acaoPage(resp.data.perguntas);
          this.util.closeLoading();
        }, err => {
          this.util.closeLoading();

          switch (err.status) {
            case 400: {
              this.util.toasterShowError("Atenção", err.error[0]);
              this.mensagemErro = err.error[0];
              break;
            }
            default: {
              this.util.toasterShowError("Atenção", "Erro Desconhecido");
              break;
            }
          }
        });
    }
  }

  controlarClickCheckboxDinamico(event: any) {

    if (!event) {
      event = window.event; // Older versions of IE use
      // a global reference
      // and not an argument.
    };

    var el = (event.target || event.srcElement);

    // var texto = (<HTMLInputElement>el).checked ? "Sim" : "Não";
    // this.document.getElementById(el.getAttribute('id') + '_span').innerHTML = texto;
  }

  controlarClickRadioDinamico(event: any, val: any) {

    console.log(event);
    //val.click = event.target.checked;
    console.log(event.target.value, val.value);
    /*if (!event) {
      event = window.event; // Older versions of IE use
      // a global reference
      // and not an argument.
    };

    var el = (event.target || event.srcElement);*/

    // var texto = (<HTMLInputElement>el).checked ? "Sim" : "Não";
    // this.document.getElementById(el.getAttribute('id') + '_span').innerHTML = texto;
  }


  getDadosPerguntas(perguntas: any[]) {

    console.log('perguntas: ', perguntas);
    var retorno = [];
    var idGroup = 0;
    perguntas.map((dado) => {

      var input = { tituloPai: '', showClass: 'visible', showGroupName: false, name: '', idGroup: 0, group: '', page: 0, class: '', type: '', id: '', label: '', data: [], textMask: {}, descricao: '', textoCampoCondicional: '', ponto: '' };

      var classeRequired = dado.obrigatorio ? 'required ' : '';
      var classeMascara = this.getClasseMascara(dado.iD_TIPO_CAMPO_FORMULARIO);

      input.class = "dynamicComponent form-control " + classeRequired + classeMascara;
      input.id = dado.iD_CAMPO_FORMULARIO_TRIAGEM;
      input.label = dado.titulo;
      input.descricao = dado.descricao;
      input.page = dado.pagina;
      input.group = dado.triagemAgrupamento;
      input.idGroup = dado.iD_CAMPO_FORMULARIO_TRIAGEM_AGRUPAMENTO;
      input.tituloPai = dado.tITULO_PAI;
      input.textoCampoCondicional = dado.textO_CAMPO_CONDICIONAL;
      input.ponto = dado.ponto;

      if (input.idGroup && input.idGroup != idGroup) {
        input.showGroupName = true;
        idGroup = input.idGroup;
      }

      if (input.page > 1)
        input.showClass += ' invisible';

      //Seleção
      if (dado.iD_TIPO_CAMPO_FORMULARIO == this.tiposEntrada.Selecao) {
        input.type = 'select';
        dado.valores.split('|').map(valor => {
          input.data.push({ 'value': valor });
        });
      }
      else if (dado.iD_TIPO_CAMPO_FORMULARIO == this.tiposEntrada.Check) {
        input.type = 'check';
        input.class = input.class.replace("form-control", " ");
      }
      else if (dado.iD_TIPO_CAMPO_FORMULARIO == this.tiposEntrada.Radio) {

        input.type = 'radio';
        input.class = input.class.replace("form-control", " ");
        let camposHabilita = dado.habilitA_CAMPO != null ? dado.habilitA_CAMPO.split('|') : null;
        let pontos = dado.ponto.split('|');
        let dados = dado.valores.split('|');
        for (let i = 0; i < dados.length; i++) {
          let insert = { 'value': dados[i], 'habilitar': '', 'ponto': '' };

          if (camposHabilita != undefined && camposHabilita != null && camposHabilita.length > 0)
            insert.habilitar = camposHabilita[i]

          if (pontos != undefined && pontos != null && pontos.length > 0)
            insert.ponto = pontos[i]

          input.data.push(insert);
        }

      }
      else if (dado.iD_TIPO_CAMPO_FORMULARIO == this.tiposEntrada.Grade) {

        input.type = 'grade';
        var dados = this.getDadosPerguntas(dado.filhos);
        input.data = dados;
      }
      else {

        input.type = 'text';
        let tipo = this.getClasseMascara(dado.iD_TIPO_CAMPO_FORMULARIO);

        switch (tipo) {
          case "telefone": {
            input.textMask = { mask: maskTelefoneCelular };
            break;
          }
          case "cpf": {
            input.textMask = { mask: maskCPF };
            break;
          }
          case "moeda": {
            input.textMask = { mask: numberMaskReal };
            break;
          }

          case "inteiro": {
            input.textMask = { mask: numberMaskInt };
            break;
          }
        }
      }

      retorno.push(input);
    });

    return retorno;
  }

  viewTipoEntradaDeDados(perguntas: any[]) {

    let perguntaDados = this.getDadosPerguntas(perguntas);

    perguntas.map(perg => { });
    this.perguntasFormTemplate = perguntaDados;
    console.log('viewTipoEntradaDeDados', this.perguntasFormTemplate);
    let group = {}

    this.perguntasFormTemplate.forEach(input_template => {
      group[input_template.label] = new FormControl('');
    })
    this.myFormGroup = new FormGroup(group);

    let av = this;
    setTimeout(function () {
      av.avancar();
      av.voltar();
    }, 50);


  }



  getClasseMascara(classe: string) {
    switch (classe.toString()) {
      case (this.tiposEntrada.TextoLivre): return "texto";
      case (this.tiposEntrada.Telefone): return "telefone";
      case (this.tiposEntrada.Cpf): return "cpf";
      case (this.tiposEntrada.Rg): return "rg";
      case (this.tiposEntrada.Email): return "email";
      case (this.tiposEntrada.Moeda): return "moeda";
      case (this.tiposEntrada.Inteiro): return "inteiro";
      default: return "texto";
    }

  }

  buscarInstrucao() {
    console.log(this.arrayOriginalDados);
    let ooo = this.arrayOriginalDados['perguntas'].find(c => c.pagina == this.paginaCorrente);
    this.orientacoesFormulario = "";
    ooo.descricaO_INSTRUCAO.forEach(element => {
      this.orientacoesFormulario += element;
    });
  }




  avancar(validarPagina = true) {


    if (validarPagina) {
      let valid = this.dadosValidosParaSalvar(this.paginaCorrente);

      if (!valid) {
        this.mensagemCamposObrigatorios = "Favor preencher todos os campos obrigatórios!";
        return false;
      }

      if (!this.temCheckboxMarcadoNaPagina(this.paginaCorrente)) {
        if (!confirm("Você não marcou nenhuma opção, deseja avançar mesmo assim?")) {
          return false;
        }
      }

    }

    this.avancarPaginaSemValidacao();

  }

  avancarPaginaSemValidacao() {
    if (this.paginaCorrente < this.totalPaginas)
      this.paginaCorrente++;

    this.exibirPagina(this.paginaCorrente);
    this.buscarInstrucao();
  }

  voltar(validarPagina = true) {
    if (this.paginaCorrente > 1)
      this.paginaCorrente--;

    this.exibirPagina(this.paginaCorrente);
    this.buscarInstrucao();
  }

  exibirPagina(pagina: number) {
    document.querySelectorAll('.pagina').forEach(input => {
      input.classList.remove('visible');
      input.classList.add('invisible');
    });

    document.querySelectorAll('.pagina_' + pagina).forEach(input => {
      input.classList.remove('invisible');
      input.classList.add('visible');
    });
  }


  salvarEntradaDados() {

    let valid = this.dadosValidosParaSalvar();

    console.log('a enviar', this.dadosTemplateEntradaResposta);
    if (valid) {
      this.mensagemCamposObrigatorios = '';
      this.enviarDadosEntradaDados(this.dadosTemplateEntradaResposta);
    } else {
      this.mensagemCamposObrigatorios = "Favor preencher todos os campos obrigatórios!";
    }
  }

  dadosValidosParaSalvar(pagina = 0) {
    this.mensagemCamposObrigatorios = '';
    this.dadosTemplateEntradaResposta = [];

    var seletorPagina = pagina ? '.pagina_' + pagina + ' ' : '';
    var isValid = true;
    document.querySelectorAll(seletorPagina + '.dynamicComponent').forEach(input => {

      let value = '';
      let type = input.getAttribute('type');

      if (type == 'text')
        value = (<HTMLInputElement>input).value
      else if (type == 'checkbox') {

        value = (<HTMLInputElement>input).checked ? 'Sim' : "Não";
        //  value += '- '+document.getElementById(input.getAttribute('id')+'_label').innerText;
        value += (<HTMLInputElement>input).checked ? '|' + (<HTMLInputElement>input).value : '';
      }
      else if (type == 'radio') {

        //Pega o valor do rádio corrente, se o mesmo estiver preenchido, checado, o código saíra desse if e as intruções fora desse if irão adicionar o value do rádio ao array para ser feito o post
        value = (<HTMLInputElement>input).checked ? (<HTMLInputElement>input).value : '';

        //Verifica se o radio corrente tem restrição required
        const isRadioGroupRequired = input.classList.contains('required');

        if (isRadioGroupRequired) {

          //Pega o NAME do grupo INTEIRO de rádios, teoricamente se um tem required então a pessoa deverá marcar UM rádio nesse grupo
          const groupName = (<HTMLInputElement>input).name;

          //Verifica se no grupo do rádio tem um rádio marcado, se tiver, então já foi preenchido
          const checkedElement = document.querySelectorAll(`[name="${groupName}"]:checked`).length;

          //Se tiver alguem marcado, então o required foi atendido
          if (checkedElement) {

            //Remove a classe de inválido do label do grupo de rádios
            this.document.querySelector(`.labelClass_${groupName}`).classList.remove('invalid');

            //Se tem um rádio marcado no grupo de rádios mas não é o rádio da iteração, não fazer mais nada pois o valor nesse rádio não deve ser enviado
            if (!value)
              return true;
          } else {
            //Não tem nenhum elemento marcado, o label do grupo será destacado e o isValid será false
            this.document.querySelector(`.labelClass_${groupName}`).classList.add('invalid');
            isValid = false;
            return false;
          }
        } else {
          //O grupo de rádios NÃO é obrigatório, se não tiver valor basta sair
          if (!value)
            return false;
        }
      }
      else
        value = (<HTMLSelectElement>input).value;

      let isRequired = input.classList.contains('required');

      if (!value && isRequired) {
        input.classList.add('invalid');
        isValid = false;
      } else {
        var id = input.getAttribute("id");

        let valuePontos = value.split('|');
        let ponto = "0";
        if (valuePontos.length > 1) {
          value = valuePontos[0];
          ponto = valuePontos[1]
        }

        var dado = this.montarInputTipoEntrada(id, isRequired, value, ponto);
        this.dadosTemplateEntradaResposta.push(dado);
        input.classList.remove('invalid');
      }
    });

    return isValid;
  }


  temCheckboxMarcadoNaPagina(pagina) {
    var seletorPagina = pagina ? '.pagina_' + pagina + ' ' : '';
    var temCheckboxMarcado = false;
    const checkboxs = document.querySelectorAll(seletorPagina + '[type="checkbox"].dynamicComponent');
    checkboxs.forEach(input => {


      let type = input.getAttribute('type');

      if (type == 'checkbox') {

        if ((<HTMLInputElement>input).checked)
          temCheckboxMarcado = true;
      }
    });


    return temCheckboxMarcado || !checkboxs.length;
  }

  montarInputTipoEntrada(idCampoFormularioTriagem: string, isRequired: boolean, value: string, ponto?: string) {

    var dados =
    {
      iD_CAMPO_FORMULARIO_TRIAGEM: parseInt(idCampoFormularioTriagem),
      PONTO: ponto,
      valor: value,
      obrigatorio: isRequired,
      iD_RESPOSTA_FORMULARIO: 0,
      iD_RESPOSTA_METADADO_FORMULARIO: 0,
      iD_RESPONSAVEL_TEA: this.responsavel.iD_RESPONSAVEL_TEA,
      iD_CRIANCA: this.crianca.iD_CRIANCA,
      iD_TRIAGEM: this.crianca.iD_TRIAGEM
    };
    return dados;

  }


  enviarDadosEntradaDados(dados) {
    var url = API + 'RespostaFormulario/RespostaFormulario';

    this.util.showLoading();
    this.ajax.post(url, dados)
      .subscribe(() => {

        this.configurarRetornoSucessoSaveEntradaDeDados();
      }, err => {
        this.util.closeLoading();
        switch (err.status) {
          case 400: {
            this.util.toasterShowError("Atenção", "Erro Desconhecido");
            this.mensagemErro = err.error[0];
            break;
          }
          default: {
            this.util.toasterShowError("Atenção", "Erro Desconhecido");
            break;
          }
        }

      });
  }

  configurarRetornoSucessoSaveEntradaDeDados() {

    this.mensagemCamposObrigatorios = 'Dados enviados com sucesso, Falta apenas mais alguma Etapa ';// + this.contadorRedirect;
    this.isExibirBotaoSalvar = false;
    this.util.closeLoading();
    this.selecionaTab("final");
    //Exibindo mensagem de contagem regressiva e efetuando o redirect

    /*setInterval(() => {
      this.contadorRedirect--;
      this.mensagemCamposObrigatorios = 'Dados enviados com sucesso, redirecionando em ' + this.contadorRedirect;

      if (this.contadorRedirect <= 0)
        location.href = REDIRECT_SITE;
    }, 1000);*/

  }

}
