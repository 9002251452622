import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, BASE_API, IMG_PADRAO } from 'src/environments/environment';

@Component({
  selector: 'app-cadastro-participante',
  templateUrl: './cadastro-participante.component.html',
  styleUrls: ['./cadastro-participante.component.scss']
})
export class CadastroParticipanteComponent implements OnInit {

  public arrayParticipante: any[];
  public filtro: string;

  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) { }

  ngOnInit(
    
  ) {
    this.buscar();
  }

  verArquivo(item) {
    let url = item.patH_LAUDO != null ? BASE_API + "/" + item.patH_LAUDO : IMG_PADRAO;
    window.open(url, '_blank').focus();
  }

  buscar() {
    this.util.showLoading();
    let url = API + "Crianca/Crianca";
    return this.ajax.get(url)
      .subscribe(response => {
        this.arrayParticipante = response.data;
        console.log(this.arrayParticipante);
        this.util.closeLoading();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Responsáveis");
      });
  }

}
