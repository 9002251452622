import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';

@Component({
  selector: 'app-cadastro-grupo-questoes',
  templateUrl: './cadastro-grupo-questoes.component.html',
  styleUrls: ['./cadastro-grupo-questoes.component.scss']
})
export class CadastroGrupoQuestoesComponent implements OnInit {

  public nome: string;
  public arrayGrupos: any;
  public descricao: string;
  public escalaSelecionada: any;


  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.escalaSelecionada = null;
    this.buscar();
  }

  buscar(){
    this.util.showLoading();
    let url = API + "TipoClassificacaoPergunta/TipoClassificacaoPergunta";
    //let url = "fake-data/grupo-questao.json" ;
    return this.ajax.get(url)
    .subscribe(response  => {
      this.arrayGrupos = response.data;
      console.log(this.arrayGrupos);
      this.util.closeLoading();
    }, err => {
      debugger
      this.util.closeLoading();
      this.util.toasterShowError("Atenção", "Erro ao Buscar Escala");
    });
  }

  valida(){
    if (this.nome == undefined || this.nome == "") {
      this.util.toasterShowError("Atenção", "Entre com o Nome do Escala");
      return false;
    }

    return true;

  }

  limpar(){
    this.nome = "";

  }

  salvar(){

    if(this.valida()){

      debugger
      let dados = {
        "descricao": this.nome,
        "INSTRUCAO": this.descricao,
        "STATUS": true
      }
      let url = API + "TipoClassificacaoPergunta/TipoClassificacaoPergunta";
      return this.ajax.post(url, dados)
      .subscribe(response  => {

        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Escala Salvo com Sucesso");
        this.nome = "";
        this.descricao = "";
        this.buscar();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Escala");
      });

    }

  }


  salvarEdicao(){

    if(this.valida()){

      debugger
      let dados = {
        "descricao": this.nome,
        "INSTRUCAO": this.descricao,
        "STATUS": true,
        "ID_TIPO_CLASSIFICACAO_PERGUNTA": this.escalaSelecionada.iD_TIPO_CLASSIFICACAO_PERGUNTA
      }
      let url = API + "TipoClassificacaoPergunta/TipoClassificacaoPergunta";
      return this.ajax.post(url, dados)
      .subscribe(response  => {

        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Escala Atualizada com Sucesso");
        this.nome = "";
        this.descricao = "";
        this.escalaSelecionada = null;
        this.buscar();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Escala");
      });

    }

  }

  carregarEdicao(item){
    this.escalaSelecionada = item;
    this.nome = item.descricao;
    this.descricao = item.instrucao;
  }

  voltar(){
    this.escalaSelecionada = null;
    this.nome = "";
    this.descricao = "";
  }

  desabilitar(id: string){

      debugger
      let dados = {
        "id": id
      }
      let url = API + "TipoClassificacaoPergunta/TipoClassificacaoPergunta/Desabilitar/"+id;
      return this.ajax.post(url, dados)
      .subscribe(response  => {

        this.util.closeLoading();
        this.util.toasterShowSuccess("Atenção", "Escala Desabilitado com Sucesso");
        this.nome = "";
        this.buscar();
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Salvar Escala");
      });

  }

}
