import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AjaxService } from "src/app/service/ajax.service";
import { AuthService } from "src/app/service/auth.service";
import { UtilService } from "src/app/service/util.service";
import { API, BASE_API_MEETING } from "src/environments/environment";
import Swal from "sweetalert2";
import slugify from '@sindresorhus/slugify';



@Component({
  selector: "app-meeting",
  templateUrl: "./meeting.component.html",
  styleUrls: ["./meeting.component.scss"],
})
export class MeetingComponent implements OnInit {

  public nome: string;
  public dataEvento: string;
  public tamanhoSala: string;
  public arrayMeeting: any;
  public meetingSelecionada: any;


  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService
  ) {
    this.limpar();
  }

  public generateSlug(): void {
    this.nome = slugify(this.nome, {lowercase: false}); 
  }

  ngOnInit() {
    this.buscar();
  }

  carregarEdicao(item) {
    console.log(item);
    this.limpar();
    this.meetingSelecionada = item;
    this.nome = item.descricao;
  }

  buscar() {
    this.util.showLoading();
    let url = BASE_API_MEETING + "Meeting/BuscarMeeting/27652671000193";
    return this.ajax.get(url).subscribe(
      (response) => {
        this.arrayMeeting = response.results.results;
        console.log(this.arrayMeeting);
        this.util.closeLoading();
      },
      (err) => {
        debugger;
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Buscar Encontro");
      }
    );
  }

  valida() {
    if (this.nome == undefined || this.nome == "") {
      this.util.toasterShowError("Atenção", "Entre com o Nome do Turma");
      return false;
    }

    debugger;
    if (this.dataEvento == undefined || this.dataEvento == "") {
      this.util.toasterShowError("Atenção", "Entre com a data de expiração");
      return false;
    }

    return true;
  }

  limpar() {
    this.nome = "";
    this.dataEvento = "";
    this.tamanhoSala = "group";
    this.nome = "";
    this.meetingSelecionada = null;
  }

  salvar() {
    if (this.valida()) {
      debugger;
      let dados = {
        isLocked: true,
        roomNamePrefix: this.nome,
        roomNamePattern: "human-short",
        roomMode: this.tamanhoSala,
        endDate: this.util.formatDateSQL(this.dataEvento),
        fields: ["hostRoomUrl"],
      };

      let url = BASE_API_MEETING + "Meeting/SalvarMeeting";
      return this.ajax.post(url, dados).subscribe(
        (response) => {
          this.util.closeLoading();
          this.util.toasterShowSuccess("Atenção", "Meeting Criado com Sucesso");
          this.limpar();
          this.buscar();
        },
        (err) => {
          debugger;
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", "Erro ao Criar Meeting");
        }
      );
    }
  }

  visualizarLinks(item) {
    console.log(item.iD_TRIAGEM);
    let message = `
    <div class=""> 
        <h4 class="mb-2">URL Host</h4> 
        <div class="form-group mb-4"> 
          <input type="text" value="${item.hostRoomUrl}" class="form-control pl-2" readonly=""> 
        </div>
    <div>
    <div class=""> 
        <h4 class="mb-2">URL Convidado</h4> 
        <div class="form-group mb-4"> 
          <input type="text" value="${item.roomUrl}" class="form-control pl-2" readonly=""> 
        </div>
    <div>

`;
    Swal.fire({
      title: item.titulo,
      type: "warning",
      html: message,
      width: "85%",
    }).then((result) => {});
  }

  desabilitar(id: string) {
    //this.util.toasterShowWarning("Atenção", "Em Implementação");
    debugger;
    let dados = {
      meetingId: id,
    };
    let url = BASE_API_MEETING + "Meeting/DeletarMeeting";
    return this.ajax.post(url, dados).subscribe(
      (response) => {
        this.util.closeLoading();
        this.util.toasterShowSuccess(
          "Atenção",
          "Reunião Apagada com Sucesso"
        );
        this.nome = "";
        this.buscar();
      },
      (err) => {
        debugger;
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", "Erro ao Deletar Reuniao");
      }
    );
  }
}
